.header-faqs {
    margin: 0 auto;
    width: 100%;
    max-width: 446px;

    div {
        margin: 0;
    }

    h2 {
        margin: 0;
        text-align: center;
        font: normal normal bold 25px/32px Source Sans Pro;
        letter-spacing: 2.5px;
        color: $black;
        opacity: 1;
        padding-top: 50px
    }

    p {
        text-align: center;
        font: normal normal normal 18px/24px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        padding-top: 30px;
        padding-bottom: 80px;
        margin: 0;
    }
}
