.vida-vacations {

    .center-text {
        width: 296px;
        height: 64px;
        margin: 30px auto 40px auto;
    }

    .center-text > p {
        color: $grey;
        opacity: 1;
        margin: 0 auto;
        text-align: center;
        font: normal normal normal 16px/22px Source Sans Pro;
    }

    .logo-center {
        width: 78px;
    }

    .my-vida-vacations-payments-right {
        width: 195px;

        h1 {
            text-align: left;
            font: normal normal bold 18px/22px Source Sans Pro;
            letter-spacing: 0.9px;
            color: #000000;
            text-transform: uppercase;
            opacity: 1;
            padding-left: 17px;
            margin-left: 15px;
        }
    }

}
