.your-reservation {
    .reservation-summary-information-container {
        max-width: 717px;
        min-height: 217px;
        margin-bottom: 0;
    }

    h1 {
        font: normal normal bold 25px/30px Playfair Display;
        letter-spacing: 0;
        padding-top: 40px;
        padding-bottom: 30px;
    }

    h2 {
        font: normal normal bold 25px/36px Playfair Display;
        padding-top: 30px;
    }

    h3 {
        font: normal normal 600 18px/17px Source Sans Pro;
        letter-spacing: 0.36px;
        margin-top: 20px;
    }

    h4 {
        font: normal normal normal 18px/17px Source Sans Pro;
        letter-spacing: 0.36px;
        margin-bottom: 30px;
    }

    .reservation-summary-image {
        // min-height: 217px;
    }

    .reservation-info {
        .reservation-info-text {
            min-height: 161px;
            height: 100%;
            padding: 10px 50px 0;

            h2 {
                margin: 0;
            }
        }

        .reservation-summary-dates {
            height: 100%;

            .reservation-date-container {
                height: 56px;
            }
        }
    }
}
