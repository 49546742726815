.welcome-component {
    height: 447px;

    .title {
        margin-top: 52px;
        font: normal normal normal 40px/53px Playfair Display;
        letter-spacing: -0.4px;
    }

    .sub-title {
        font: normal normal 600 18px/19px Source Sans Pro;
        letter-spacing: 0;
        //text-transform: lowercase;
    }

    .welcome-filters {
        margin-top: 60px;

        span {
            font: normal normal 600 18px/19px Source Sans Pro;
        }

        width: 100%;
    }
}
