.footer {
    height: 76px;

    .footer-black-section {
        .footer-about-links {
            .link {
                font: normal normal normal 17px/16px Source Sans Pro;
                letter-spacing: 0.85px;

                &:first-child {
                    margin-right: 50px;
                }
            }
        }

        .footer-social {
            .icon {
                margin-left: 17px;

                .social-icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.footer-grey-section {
    .privacy-terms-container {
        .link-grey-section {
            font: normal normal normal 13px/13px Source Sans Pro;

            a.link-grey-text {
                font: normal normal normal 13px/13px Source Sans Pro;
            }
        }

        .copyright {
            color: black;
        }
    }
}
