.success-payment-content {
    padding-top: 40px;

    .payment-received {
        padding-top: 160px;
    }

    .download-invoice {
        margin-top: 50px;
        margin-bottom: 268px;
    }
}
