.stay-with-us {
    padding: 37px 0px 80px 0;
    height: auto;

    .stay-with-us-title {
        text-transform: uppercase;
        margin: 0 auto 40px;
        text-align: center;
        font: normal normal bold 25px/32px Playfair Display;
        color: $black;
        letter-spacing: 0px;
        //width: 350px;
        line-height: 32px;
    }

    .partners {
        justify-content: center;
    }

    .img-container {
        max-width: 280px;
        margin: 10px 10px;
        //height: 157px;
        //padding-right: 15px;
        //padding-top: 15px;

        &:last-child {
            padding-right: 0;
        }

        .img-company-background {
            overflow: hidden;
            //height: 100%;
            width: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .stay-with-us-subtitle {
        color: $grey;
        font: normal normal normal 18px Source Sans Pro;
        line-height: 20px;
        text-align: center;
        margin-bottom: 50px;
        margin-top: 60px;
    }

    .stay-with-us-btn {
        width: 246px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue;
        color: $white;
        font: normal normal bold 20px Source Sans Pro;
        border: none;
        letter-spacing: 2px;
        //text-transform: uppercase;
        border-radius: 2px;
    }
}
