.filters-container {
    min-height: 112px;
    padding: 8px 0;

    .custom-select-field-wrapper {
        &.header-filter {
            .MuiFormLabel-root {
                padding-left: 6px;
            }
        }
    }

    .date-picker-container {
        &.header-filter {

            .MuiInputLabel-filled {
                transform: translate(6px, 24px) scale(1);
            }

            .MuiIconButton-root {
                margin-left: -10px;
                margin-top: 5px;
            }
        }
    }

    .filter-item-container:not(:last-child) {
        padding-right: 8px;
    }

    .reserve-btn {
        margin-top: 8px;
        font-size: 16px;
        height: 60.75px;
        width: 100%;
    }
}
