.video-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .video-modal-content {
        display: flex;
        flex-direction: column;
        width: 848px;
        height: 480px;
        align-items: center;
        justify-content: center;
        outline: none;

        svg {
            margin-left: 95%;
            color: $white;
            margin-bottom: 10px;
            cursor: pointer;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: $tablet-width) {
    .video-modal-container {
        .video-modal-content {
            width: 70%;
            height: 55%;
        }
    }
}

@media screen and (max-width: $mobile-width) {
    .video-modal-container {
        .video-modal-content {
            width: 90%;
            height: 50%;
        }
    }
}
