.profile-notifications {
    padding-bottom: 20px;

    .profile-notifier {
        max-height: 130px;
        padding-top: 16px;
    }

    h1.profile-notifications-label {
        font: normal normal normal 16px/31px Source Sans Pro;
        letter-spacing: 0.8px;
    }
}
