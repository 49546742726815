.success-payment-content {
    padding-top: 30px;

    .logo-img {
        padding-right: 17px;
        text-align: right;
    }

    .title-right {
        margin-left: 0px;
        padding-left: 17px;

        .my-vida-vacations-payments-title {
            font: normal normal bold 18px/22px Source Sans Pro;
            letter-spacing: 0.9px;
        }
    }

    .payment-received {
        padding-top: 60px;

        .payment-received-title {
            font: normal normal bold 22px/28px Playfair Display;
            letter-spacing: 0px;
        }
    }

    .thank-container {
        padding-top: 15px;
        width: 254px;

        .thank-text {
            font: normal normal normal 16px/20px Source Sans Pro;
        }
    }

    .download-invoice {
        margin-top: 40px;
        padding: 0 32px;
        margin-bottom: 86px;
    }
}
