.about-section {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    h1 {
        margin: 0;
        text-align: center;
        //text-transform: capitalize;
        font: normal normal bold 25px Playfair Display;
        letter-spacing: 0;
        color: $black;
        opacity: 1;
        padding-top: 47px;
    }

    p {
        max-width: 574px;
        text-align: center;
        font: normal normal normal 18px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        padding-top: 14px;
        padding-bottom: 57px;
        margin: 0 auto;
    }
}
