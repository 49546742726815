.header-image {
    height: 273px;
    background-image: url('/img/mobile/generalslider.jpg');

    .payments-header-container {

        .logo-and-title-container {
            width: 100%;

            .logo-container {

                .vida-payments-logo {
                    width: 79px;
                }
            }

            .title-container {
                h1 {
                    font: normal normal bold 22px/28px Playfair Display;
                }

                hr.border {
                    margin-top: 20px;
                    width: 69px;
                }
            }
        }

        .center-text {
            max-width: 310px;
            font: normal normal 600 15px/20px Source Sans Pro;

            p.welcome-text{
                margin-bottom: 0.5rem;
            }
        }
    }

    .select-contract {
        padding-top: 0px;
        font: normal normal 600 15px/20px Source Sans Pro;
    }
}
