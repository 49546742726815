.upcoming-reservation {

    .view-reservation {
        padding: 0;
        height: 56px;
        width: 290px;
        letter-spacing: 1px;
    }

    h1.upcoming-reservation-text {
        font: normal normal bold 22px/25px Source Sans Pro;
        letter-spacing: 1.1px;

        &:first-child {
            max-width: 250px;
        }
    }
}
