.choose-months {
    max-height: 0;
    transition: max-height 0.5s, visibility 0s;
    visibility: hidden;
    padding-top: 20px;
    border-top: 1px solid $grey1;
    width: 100%;
    text-align: center;

    &.expanded {
        overflow: hidden;
        height: auto;
        max-height: 240px;
        visibility: visible;
    }

    .choose-months-title {
        text-align: center;
        font: normal normal normal 18px/20px Source Sans Pro;
        letter-spacing: 0px;
        color: $grey7;
        opacity: 1;
        margin-bottom: 20px;
        display: block;
    }

    .custom-payment-field > div:nth-child(3n + 2) {
        padding-left: 6px;
    }

    .custom-payment-field > div:nth-child(3n) {
        padding-left: 9px;
    }
}
