.second-nav-menu {
    height: 45px;
    padding-top: 3px;
    background-color: $blue;
    &.b-bottom {
        border-bottom: 1px solid $white;
    }

    .second-nav-link {
        font: normal normal 600 16px/36px Source Sans Pro;
        letter-spacing: 0;
        color: $white;
        opacity: 1;
        text-align: left;
        cursor: pointer;
    }

    .second-nav-btn {
        background-color: transparent;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border: none;
    }
}
