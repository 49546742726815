.club-section {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    h1 {
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        font: normal normal bold 22px/25px Source Sans Pro;
        letter-spacing: 1.1px;
        color: $black;
        opacity: 1;
        padding-top: 50px;
    }

    p {
        text-align: center;
        font: normal normal normal 18px/24px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        padding-top: 30px;
        padding-bottom: 50px;
        max-width: 438px;
        margin: 0 auto;
    }

    .club-read-more {
        width: 351px;
        height: 56px;
        margin-bottom: 114px;
    }
}

