.add-new-credit-card-modal {
    
    .add-new-credit-card-modal-container {
        width: 473px;
        height: 85%;
        overflow: scroll;

        .modal-body {
            padding: 0px 60px 0px 60px;

            .card-billing-info-container {
                &.is-modal {
                    padding-bottom: 50px;

                    .card-details-container {
                        margin-right: 0px;
                    }

                    .bill-details-container {
                        margin-left: 0px;
                    }

                    .card-billing-title {
                        margin-bottom: 40px;
                        margin-top: 40px;
                    }

                    // TOGGLE
                    .save-credit-card {
                        margin-top: 35px;
                    }
                    // END TOGGLE
                }
            }

            .submit-save-card-btn {
                margin: 0px auto 50px auto;
                width: 351px;
            }
        }
    }
}
