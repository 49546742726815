.toggle-container {
    margin-top: 45px;
    justify-content: center;

    &.register {
        .MuiSwitch-root {
            width: 54px;
        }
    }

    &.contact-form {
        margin-top: 10px;
    }
}
