.profile-details {
    .section-profile {
        .box-container-tablet {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .member-no {
        margin-top: -30px;
    }

    .label-info {
        font: normal normal normal 18px/36px Source Sans Pro;
        line-height: 0.8;
    }

    .edit-profile-button {
        .edit-button {
            margin-top: 60px;
            width: 350px;
        }
    }
}

