.profile-details {
    padding-top: 80px;
    padding-bottom: 44px;
    background-color: #EFEFEF;
    text-align: center;
    margin: auto;

    .member-no {
        margin-top: -35px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    .section-profile {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .section-profile-info {
            margin-top: 0;
        }
    }

    button {
        border-radius: 0;
    }

    .label-info {
        text-align: center;
        color: $grey3;
        font: normal normal normal 20px Source Sans Pro;
        letter-spacing: 0;
        opacity: 1;
        margin: 0;
        line-height: 0.8;
    }

    .info {
        text-align: center;
        color: $black;
        font: normal normal bold 22px Source Sans Pro;
        letter-spacing: 0;
        opacity: 1;
        margin: 0;
    }

    .edit-profile-button {
        display: flex;
        margin: 0 auto;
        border-radius: 2px;
        text-align: center;
        letter-spacing: 2px;
        color: $white;
        text-transform: none;
        opacity: 1;

        .edit-button {
            font: normal normal bold 20px Source Sans Pro;
            background-color: $switch-bg-color;
            color: $white;
            margin-top: 40px;
            padding: 15px 37px;
            text-align: center;
            letter-spacing: 2px;
            text-transform: none;
            opacity: 1;
            border-radius: 2px;
        }

        .edit-profile-icon {
            margin-right: 10px;
        }
    }
}

