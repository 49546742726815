.footer {
    height: 130px;
    justify-content: center;

    .footer-black-section {
        align-items: center;

        .footer-about-links {
            display: flex;
            flex-direction: row;

            .link {
                text-align: left;
                font: normal normal normal 16px Source Sans Pro;
                text-transform: uppercase;
                opacity: 1;
                letter-spacing: 2.46px;

                &:first-child {
                    margin-right: 40px;
                }

                .link-text {
                    color: $white;
                    text-decoration: none;
                }
            }
        }

        .footer-social {
            .icon {
                margin-left: 20px;
            }
        }
    }
}

.footer-grey-section {
    justify-content: space-between;
    align-items: center;

    .privacy-terms-container {
        justify-content: center;
        display: block;
        flex-direction: row;
        margin: 30px 0 30px 0;

        .link-grey-section {
            text-align: left;
            font: normal normal normal 15px/13px Source Sans Pro;
            letter-spacing: 0;
            opacity: 1;
            margin-top: 20px;

            &:first-child {
                margin-right: 40px;
                margin-top: 0px;
            }
            &:last-child {
               opacity: 0.5;
            }

            a.link-grey-text {
                text-decoration: none;
                text-align: left;
                font: normal normal normal 15px/13px Source Sans Pro;
                letter-spacing: 0;
                color: #7F7D7D;
                opacity: 1;
            }
        }

        .copyright {
            color: black;
        }
    }

    .copyright {
        text-align: left;
        font: normal normal normal 13px/16px Source Sans Pro;
        letter-spacing: 0;
        color: #7F7D7D;
        opacity: 1;
    }
}
