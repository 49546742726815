.messages-list {
    margin-bottom: 121px;

    .messages-item {
        border: 1px solid $grey2;
        border-radius: 2px;
        margin-top: 10px;
        padding: 20px 15px;
        height: auto;
        cursor: pointer;

        .message-left-section {
            display: flex;
            align-items: center;

            .message-icon-container {
                width: 20px;

                .title-badge {
                    background-color: $red;
                    width: 10px;
                    height: 10px;
                    margin-right: 10px;
                    border-radius: 50%;
                }

                img {
                    margin-bottom: 5px;
                }
            }
        }

        .message-title-container {
            display: flex;
            align-items: center;

            .title {
                text-align: left;
                font: normal normal bold 18px/20px Source Sans Pro;
                letter-spacing: 0px;
                color: $black1;
                text-transform: capitalize;
                opacity: 1;
            }
        }

        .message-from {
            margin-bottom: 5px;

            .label {
                text-align: left;
                font: normal normal 600 11px/20px Source Sans Pro;
                letter-spacing: 0px;
                color: $grey6;
                text-transform: uppercase;
                margin-right: 10px;
            }

            .text {
                text-align: left;
                font: normal normal 600 13px/20px Source Sans Pro;
                letter-spacing: 0px;
                color: $blue3;
            }
        }

        .subject {
            text-align: left;
            font: normal normal normal 16px/20px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey3;
            opacity: 1;
            margin-left: 20px;
        }

        .messages-right-section {
            display: flex;
            justify-content: flex-end;

            .time {
                font: normal normal normal 13px/20px Source Sans Pro;
                letter-spacing: 0px;
                color: $grey6;
                opacity: 1;
            }

            .nr-notification {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $grey2;
                color: $grey1;
                font: normal normal 600 13px/20px Source Sans Pro;
                letter-spacing: -0.65px;
            }
        }
    }
}
