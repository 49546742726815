@media screen and (max-width: $mobile-width) {
    &.my-account {
        @import "ProfileNotifications";
        @import "UpcomingReservation";
        @import "ProfileDetails";

        .remove-xs-space {
            margin: 0;
        }
    }
}
