.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background-color: rgba(0,0,0,0.5); /*dim the background*/
    display: flex;
    align-items: center;
    justify-content: center;

    &.middle>* {
        position: fixed;
        top: 50%;
    }

    &.top>* {
        position: relative;
        top: 0;
    }

    &.bottom>* {
        position: fixed;
        bottom: 0;
    }
}
