.contact-footer {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    div {
        margin: 0;
    }

    h1 {
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        font: normal normal bold 18px/24px Source Sans Pro;
        letter-spacing: 0.9px;
        color: $black;
        opacity: 1;
        padding-top: 30px;
    }

    p {
        text-align: center;
        font: normal normal normal 18px/22px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        padding-top: 20px;
        padding-bottom: 30px;
        max-width: 282px;
        margin: 0 auto;
    }

    .contact-footer-button {
        width: 282px;
        height: 56px;
        background: #446DB4;
        color: white;
        text-transform: uppercase;
        font: normal normal 600 20px/36px Source Sans Pro;
        border: none;
        letter-spacing: 1px;
    }

    .contact-call-icon {
        margin-right: 10px;
        margin-bottom: 5px;
    }

    .contact-customer-care {
        margin: 0 auto;
        max-width: 160px;
        margin-top: 40px;
    }

    .customer-care-text {
        padding-top: 0;
        padding-bottom: 20px;
    }

    .contact-program {
        margin: 0 auto;
        max-width: 267px;
    }

    p.contact-program-hours {
        font: normal normal bold 16px/22px Source Sans Pro;
        letter-spacing: 0px;
        color: #000000;
        text-align: center;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    p.contact-program-days {
        text-align: center;
        font: normal normal normal 16px/22px Source Sans Pro;
        letter-spacing: 0px;
        color: $grey;
        padding-top: 0;
    }

    p.closed-sunday {
        padding-top: 0;
        padding-bottom: 38px;
    }

    .contact-grid-item-left {
        padding-right: 18px;
    }

    .contact-grid-item-right {
        padding-left: 18px;
    }
}
