.other-fees-payment-form-header {
    .select-one {
        font: normal normal normal 16px/22px Source Sans Pro;
        margin-bottom: 10px;
    }

    .other-fees-payment-form-header-item {
        width: 105px;
        height: 59px;

        &.other-fees-payment-form-header-item:not(:last-child) {
            margin-right: 8px;
        }

        .label {
            font: normal normal normal 13px/13px Source Sans Pro;
        }

        .attention-container {
            top: -6px;
            right: -2px;
        }
    }
}
