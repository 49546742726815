@media screen and (max-width: $mobile-width) {
    &.my-vida-vacations-contracts {

        .wrapper {
            padding-top: 0;
        }

        @import "EntrySection";
        @import "ContractsList";
        @import "ContractsHeader";
    }
}
