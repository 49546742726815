.pre-registration-section {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    h1 {
        margin: 0;
        text-align: center;
        //text-transform: capitalize;
        font: normal normal bold 25px/32px Playfair Display;
        letter-spacing: 0;
        color: $black;
        opacity: 1;
        padding-top: 60px;
    }

    p {
        text-align: center;
        font: normal normal normal 18px/24px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        padding-top: 30px;
        padding-bottom: 60px;
        max-width: 674px;
        margin: 0 auto;
    }

    .pre-registration-button {
        width: 381px;
        min-height: 56px;
        height: auto;
        border: none;
        background: $blue;
        margin-bottom: 120px;

        a {
            color: $white;
            text-decoration: none;
            font: normal normal 600 20px/36px Source Sans Pro;
            letter-spacing: 2px;
            //text-transform: uppercase;
            text-align: center;
        }
    }
}
