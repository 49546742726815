@media screen and (max-width: $tablet-width) {
    &.messages-center {
        .messages-center-container {
            width: 100%;
            margin: 0 auto;

            @import "MessagesCentreHeader";
            @import "MessagesList";
        }
    }
}
