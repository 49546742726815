.auth.privacyPolicy {

    a{
        text-decoration: underline;
        color: #ffffff;
    }

    .policy-container {
        margin: 0 auto;
        padding: 60px 0;
        max-width: 718px;
    }

    .main{
		text-align: justify;
		text-justify: inter-word;
	}
	
	.titles{
		font-style:normal;
		font-weight:bold;
		font-size:10pt;

        color:#e7e7e7;
	}
	.bold{
		font-style:normal;
		font-weight:bold;
		font-size:10pt;

        color:#b7b7b7
	}
	.text{
		font-style:normal;
		font-weight:normal;font-size:10pt;

        color:#999999;
	}
    .left{
        margin-left: 15px;
    }
    .list{
        margin-left: 27px;
    }
    .italic{
        font-style: italic;
    }

    table.GeneratedTable {
        border-collapse: collapse;
        border-width: 1px;
        border-color: #706f6f;
        border-style: solid;
        color: #706f6f;
        width: 93%;
        margin: 0 auto;
      }
      
      table.GeneratedTable td, table.GeneratedTable th {
        border-width: 1px;
        border-color: #c5c5c5;
        border-style: solid;
        padding: 3px;
      }
}

.account.privacyPolicy {

    a{
        text-decoration: underline;
        color: #706f6f;
    }
    

    .policy-container {
        margin: 0 auto;
        padding: 60px 0;
        max-width: 718px;
    }

    .main{
		text-align: justify;
		text-justify: inter-word;
	}
	
	.titles{
		font-style:normal;
		font-weight:bold;
		font-size:10pt;

        color:#bc9d7a;
	}
	.bold{
		font-style:normal;
		font-weight:bold;
		font-size:10pt;

        color:#706f6f
	}
	.text{
		font-style:normal;
		font-weight:normal;font-size:10pt;

        color:#706f6f;
	}
    .left{
        margin-left: 15px;
    }
    .list{
        margin-left: 27px;
    }
    .italic{
        font-style: italic;
    }

    table.GeneratedTable {
        border-collapse: collapse;
        border-width: 1px;
        border-color: #706f6f;
        border-style: solid;
        color: #706f6f;
        width: 93%;
        margin: 0 auto;
      }
      
      table.GeneratedTable td, table.GeneratedTable th {
        border-width: 1px;
        border-color: #706f6f;
        border-style: solid;
        padding: 3px;
      }
    

}

