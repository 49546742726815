.upcoming-reservation {
    color: $black;
    padding-top:80px;
    padding-bottom: 40px;

    h1 {
        color: $white;
        font-size: 25px;
    }
    .view-reservation {
        max-height: 56px;
        background-color: $switch-bg-color;
        color: $white;
        font: normal normal 600 20px/36px Source Sans Pro;
        padding: 15px 60px;
        letter-spacing: 2px;
        border-radius: 2px;
        opacity: 1;
        text-transform: none;
    }

    .mtop {
        margin-top: 30px;
    }

    button {
        border-radius: 0;
    }

    .voice-icon {
        margin-right: 22px;
    }

    h1.upcoming-reservation-text {
        color: #000000;
        text-align: center;
        font: normal normal bold 25px/32px Source Sans Pro;
        letter-spacing: 2.5px;
        //text-transform: uppercase;
        opacity: 1;
    }

    p.upcoming-reservation-description {
        max-width: 445px;
        text-align: center;
        font: normal normal normal 20px/23px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        margin-bottom: 0;
        padding-top: 30px;
    }
}
