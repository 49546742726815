.transportation-title-container {
    margin-bottom: 30px;

    .transportation-title {
        margin-top: 50px;
        font: normal normal bold 22px/28px Playfair Display;
        letter-spacing: 0;
    }
}

// .transportation-container {
//     max-width: 340px;
//     height: 110px;
//     margin: 0 auto 40px auto;

//     .transportation-image-container {
//         width: 116px;
//         height: 110px;

//         .transportation-image {
//             width: 116px;
//             height: 110px;
//         }
//     }
//     .transportation-info-container {
//         width: 224px;

//         .transportation-info {
//             margin-top: 15px;

//             p.transportation-subtitle {
//                 font: normal normal bold 16px/25px Playfair Display;
//                 margin-bottom: 8px;
//             }

//             p.transportation-text {
//                 margin-top: 8px;
//                 width: 204px;
//                 font: normal normal normal 14px/18px Source Sans Pro;
//             }
//         }
//     }
// }
