.your-reservation {

    .reservation-summary-information-container {
        max-width: 960px;
        height: 323px;
        margin: 0 auto 40px;
        
    }

    h1 {
        margin: 0;
        text-align: center;
        text-transform: capitalize;
        font: normal normal bold 21px Playfair Display;
        letter-spacing: 0;
        color: $black;
        opacity: 1;
        padding-top: 60px;
        padding-bottom: 40px;
    }

    h2 {
        //text-align: center;
        font: normal normal bold 28px Playfair Display;
        letter-spacing: 0;
        color: $black;
        text-transform: capitalize;
        padding-top: 10px;
        opacity: 1;
    }

    h3 {
        //text-align: center;
        font: normal normal 600 16px Source Sans Pro;
        letter-spacing: 0.44px;
        color: $grey;
        opacity: 1;
        margin-top: 12px;
        margin-bottom: 20px;
    }

    h4 {
        //text-align: center;
        font: normal normal normal 16px Source Sans Pro;
        color: $grey3;
        letter-spacing: 0.44px;
        margin-bottom: 69px;
    }

    .reservation-summary-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .reservation-info {
        background: #ecf1f5;

        .reservation-info-text {
            height: 263px;
            padding: 40px 50px 0;

            h2 {
                margin: 0;
            }
        }

        .reservation-summary-dates {
            height: 60px;

            .reservation-date-container {
                background: $blue;
                height: 60px;
                border-right: 1px solid $grey2;
            }

            p.arriving-departing {
                margin: 0;
                padding-top: 10px;
                font: normal normal normal 13px/13px Source Sans Pro;
                text-align: center;
                letter-spacing: 0px;
                color: $grey2;
                opacity: 1;
            }

            p.reservation-date {
                margin: 0;
                text-align: center;
                font: normal normal 600 18px/16px Source Sans Pro;
                color: $white;
                letter-spacing: 0px;
                opacity: 1;
                margin-top: 5px;
            }
        }
    }
}
