&.my-vida-vacations-contracts {

    .wrapper {
        max-width: 715px;
        width: 100%;
        margin: 0 auto;
        padding-top: 92px;
        padding-left: 0;
        padding-right: 0;
    }

    p {
        margin: 0;
    }

    @import "EntrySection";
    @import "ContractsList";
    @import "ContractsHeader";
}
