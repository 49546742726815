.auth {
    .custom-select-field-wrapper {
        margin: 8px 0 !important;
    }
}

.dashboard {
    .custom-select-field-wrapper {
        &.black {
            .MuiInputBase-input {
                font: normal normal 600 16px/16px Source Sans Pro;
            }
        }
    }
}
