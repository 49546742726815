.no-margin-header-container {
    margin: 0 0 0 -15px;
}

.account-header {
    height: 60px;

    .logo {
        width: 147.5px;
    }

    .header-profile {
        .header-user-name {
            display: none;
        }

        .icon {
            &.notifications {
                margin-left: 27px;
            }
        }
    }
}

.vidanta-badge {
    .MuiBadge-anchorOriginTopRightRectangle {
        font-size: 11px;
        width: 24px;
        height: 24px;
    }
}

.beta-layout {
    height: 46px;
    margin-left: 50px;

    .beta-ring {
        height: 50px;
        width: 50px;
    }

    .beta-ring-inside {
        height: 40px;
        width: 40px;

        .beta-text {
            font-size: 9px;
            line-height: 9px;
        }
    }

    .beta-message {
        display: none;
    }
}
