.welcome-component {
    height: 407px;
    .title {
        z-index: 5;
        height: 49px;
        margin-top: 102px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        font-family: PlayfairDisplay;
        font-size: 55px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.73;
        letter-spacing: normal;
        text-align: center;
        color: #fff;

        @media screen and (max-width: 800px) {
            font: normal normal normal 45px/75px Playfair Display;
        }
    }

    .sub-title {
        z-index: 5;
        height: 23px;
        margin: 6px 122px 0;
        font-family: SourceSansPro;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }

    .welcome-filters {
        margin-top: 25px;

        span {
            text-align: center;
            font: normal normal 600 18px/16px Source Sans Pro;
            letter-spacing: 0px;
            color: $white;
            display: block;
            margin: 0 auto;
        }

        z-index: 5;
        width: 85%;
    }
}
