.update-user-modal {
    .update-user-modal-container {
        width: 100%;
        position: absolute;
        bottom: 0;

        .modal-body {
            padding: 36px 46px 0px 46px;
        }

        .submit-save-btn {
            margin: 45px auto 30px auto;
            height: 56px;
        }
    }
}
