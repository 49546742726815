.reservation-found-info-container {
    .image-and-info-container {
        min-height: 200px;

        .info-container {
            .reservation-details-container {
                min-height: 154px;
                height: auto;

                h3.destination {
                    margin-top: 20px;
                }

                h4.confirmation-label {
                    margin-top: 15px;
                }
            }
        }
    }

    .travelers-and-unit-container {
        .unit-type-container {
            padding-left: 30px;
        }
    }
}


.verify-button-container {
    margin-top: 50px;
    margin-bottom: 82px;

    .verify-button {
        width: 350px;
        letter-spacing: 1px;
    }
}
