.nav-tabs {
    border-bottom: 1px solid $grey1;
    display: flex;

    .nav-link {
        opacity: 0.5;
        color: #fff;
        font-size: 20px;
        text-transform: uppercase;
        flex: 1;
        text-align: center;
        outline: none;

        &:hover {
            border-color: transparent;
        }

        &.active {
            opacity: 1;
            border: 0;
            font-weight: 600;
            border-bottom: 5px solid #fff;
        }
    }
}
