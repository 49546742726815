.message {
    margin: 0px auto;
    width: 446px !important;
    border-top: 1px solid $grey1;
    border-bottom: 1px solid $grey1;
    padding: 100px 80px;

    .title {
        margin: 0px auto 20px auto;
        font-size: 25px;
        font-family: PlayFair Display, serif;
        font-style: italic;
    }

    .text {
        font-family: Source Sans Pro, sans-serif;
        font-size: 18px;
        margin: 0 auto;
    }

    .seconds {
        color: $blue1;
    }
}
