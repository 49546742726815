.termsAndConditions {

    .terms-and-conditions-list {
        .terms-container {
            margin-bottom: 30px;

            .term-title {
                margin: 0 auto;
                text-transform: uppercase;
                font: normal normal bold 25px/32px Source Sans Pro;
                letter-spacing: 2.5px;
                color: $white;
                opacity: 1;
                padding-top: 100px;
                text-align: center;

                &.black {
                    color: $black;
                }

                &.no-padding {
                    padding-top: 10px
                }
            }

            ul {
                font: normal normal normal 18px/24px Source Sans Pro;
                letter-spacing: 0;

                &.black {
                    color: $black;
                }

                .white {
                    color: $white;
                }
            }

            .term-text {
                font: normal normal normal 18px/24px Source Sans Pro;
                letter-spacing: 0;
                color: $white;
                opacity: 1;
                padding-top: 30px;

                &.black {
                    color: $black;
                }
            }

            .cat-title {
                font: normal normal normal 18px/24px Source Sans Pro;
                letter-spacing: 0;
                color: $white;
                opacity: 1;
                padding-top: 30px;
                font-weight: bold;

                &.black {
                    color: $black;
                }
            }
        }
    }

}
