.add-new-credit-card-modal {
    top: 57px !important;

    .add-new-credit-card-modal-container {
        width: 100%;
        height: 100%;

        .modal-body {
            padding: 0;

            .card-billing-info-container {
                &.is-modal {
                    padding: 0 46px;

                    .card-billing-title {
                        font: normal normal 600 16px/20px Source Sans Pro;
                        letter-spacing: 0.8px;
                        margin-bottom: 15px;
                        margin-top: 37px;
                    }

                    // Select field for card and bill
                    .custom-select-field-wrapper {
                        &.card-bill {
                            .MuiSvgIcon-root {
                                color: $grey6;
                                margin-right: -6px;
                            }
                        }
                    }

                    .save-credit-card {
                        margin-top: 0px;
                    }
                    // END TOGGLE

                }
            }

            .submit-save-card-btn {
                margin: 40px auto;
                width: 288px;
            }
        }
    }
}
