.contract-details {
    background-color: $grey;
    //border-top: 1px solid $grey3;

    .container {
        padding-top: 20px;
        padding-bottom: 0px;
        max-width: 700px;

    }

    .days {
        border: 1px solid rgba(68, 68, 68, .5);
        background-color: $grey;
        opacity: 1;
        padding-top: 24px;
        padding-bottom: 25px;
        // max-width: calc(33.33% - 15px);
        // max-height: 130px;

        .number {
            text-align: center;
            font: normal normal normal 49px/31px Source Sans Pro;
            letter-spacing: 0;
            color: $white;
            opacity: 1;
            margin-bottom: 20px;
        }

        p.label {
            text-align: center;
            font: normal normal normal 16px/20px Source Sans Pro;
            letter-spacing: 0;
            color: $sorrellbrown;
            opacity: 1;
            margin: auto;
            padding-bottom: 25px;
        }

    }

}
