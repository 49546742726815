.write-message {
    background-color: $white;
    margin-top: 30px;
    height: 400px;
    overflow-y: scroll;

    .accordion-container {
        padding-top: 30px;
    }

    .message-content {
        margin: 0 0  5px 0;

        &.fixed-message-header {
            margin-top: 30px;
        }

        .message-subject-read > h2 {
            text-align: left;
            font: normal normal bold 22px/25px Source Sans Pro;
            letter-spacing: 0;
            color: $black;
            opacity: 1;
        }

        .message-header-date > p {
            text-align: right;
            font: normal normal normal 16px/20px Source Sans Pro;
            letter-spacing: 0;
            color: $grey1;
            opacity: 1;
        }

        .show-message-body {
            width: 100%;

            .message-body > p {
                text-align: left;
                font: normal normal normal 18px/23px Source Sans Pro;
                letter-spacing: 0px;
                color: $black;
                opacity: 1;
            }

            .message-header > p {
                text-align: right;
                font: normal normal normal 16px/20px Source Sans Pro;
                letter-spacing: 0;
                color: $blue;
                opacity: 1;
            }

            .message-header-info {
                padding-left: 10px;
                padding-bottom: 15px;
            }

            .message-header-info > p {
                font: normal normal normal 16px/20px Source Sans Pro;
                letter-spacing: 0;
                color: $blue;
                opacity: 1;
                margin: 0;
            }

        }
    }

    .message-header {
        display: inline-flex;
        border-bottom: 1px solid $grey7;
        margin-bottom: 30px;

        .message-header-label {
            padding-top: 2px;
        }

        .message-header-label > p {
            text-align: left;
            font: normal normal 600 13px/20px Source Sans Pro;
            letter-spacing: 0;
            color: $grey6;
            text-transform: uppercase;
            margin: 0;
        }
    }

    .accordion-item {
        background-color: $greyWhite;
        padding: 10px 17px;
        width: 100%;

        .accordion-item-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .message-subject > h3 {
            text-align: left;
            font: normal normal bold 16px/20px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey;
            opacity: 1;
        }

        .message-header-date > p {
            text-align: right;
            font: normal normal normal 13px/20px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey1;
            opacity: 1;
        }

        .message-body > p {
            text-align: left;
            font: normal normal normal 15px/16px Source Sans Pro;
            letter-spacing: 0;
            color: $grey3;
            opacity: 1;
        }
    }
}
