.request-reservation-form {
    width: 595px;
    padding: 60px 0px 200px 0px;

    .title {
        font: normal normal bold 25px/30px Playfair Display;
        margin-bottom: 50px;
    }

    button[type=submit] {
        letter-spacing: 1px;
        height: 60px;
        width: 229px;
        margin-top: 60px;
        text-transform: none;
    }
}
