.contract-modal {

    .contract-modal-container{
        width: 300px;
        .modal-title {

            h1 {
                text-align: left;
                font: normal normal bold 18px/25px Source Sans Pro;
                letter-spacing: 0.9px;
                //text-transform: uppercase;
                opacity: 1;
                margin: 0;
            }

            svg {
                cursor: pointer;
                margin-bottom: 3px;
            }
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            label  {
                text-align: center;
                font: normal normal normal 16px Source Sans Pro;
                letter-spacing: 0;
                color: #444444;
                opacity: 1;
                margin: 0;
            }

            .box {
                padding-bottom: 0;
                height: 60px;
            }

            p {
                text-align: center;
                font: normal normal bold 18px Source Sans Pro;
                letter-spacing: 0;
                color: $black;
                opacity: 1;
                margin: 0;
            }

            a.link-button {
                    border: none;
                    margin: 40px auto;
                    text-align: center;
                    font: normal normal 600 16px/20px Source Sans Pro;
                    letter-spacing: 2px;
                    //text-transform: uppercase;
                    opacity: 1;
                    outline: none;
                    text-decoration: none;

                .link-to {
                    color: $white;
                }

                svg {
                    margin-bottom: 3px;
                }
            }
        }
    }
}
