.payment-details-container {
    width: 473px;

    .title {
        font: normal normal bold 22px/25px Source Sans Pro;
        letter-spacing: 1.1px;
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .saved-card-container {
        margin-right: 14px;
        padding: 15px;
        height: 113px;
        width: 148px;

        &.last-item {
            margin-right: 0px;
        }

        .saved-card-header {
            margin-bottom: 11px;

            .expiration-date {
                font: normal normal normal 16px/36px Source Sans Pro;
            }
        }

        .number {
            margin-bottom: 0px;
            font: normal normal 600 16px/36px Source Sans Pro;
        }

        .owner-name {
            //height: 23px;
            font: normal normal normal 16px/36px Source Sans Pro;
        }

        .add-new-card {
            font: normal normal normal 16px/36px Source Sans Pro;
        }
    }

    .replace-card {
        margin: 50px 14%;
    }

    button[type=submit] {
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 1px;
        height: 56px;
        width: 350px;
        margin-bottom: 100px;
        text-transform: none;
    }
}
