.contracts-container {
    padding: 10px 5px;

    .no-contracts-available {
        margin: 0 auto;
        padding-top: 15px;
        font: normal normal normal 18px/20px Source Sans Pro;
        text-align: center;
    }
}
