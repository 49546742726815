.upcoming-reservation {
    padding-top: 40px;
    padding-bottom: 60px;

    .upcoming-reservations-container {
        margin-bottom: 88px;
    }

    h1.upcoming-reservation-text {
        font: normal normal bold 18px/24px Source Sans Pro;
        letter-spacing: 0.9px;
        color: $black;
    }

    .view-reservation {
        text-align: center;
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 0;
        color: $white;
        opacity: 1;
        padding: 14px 36px;
    }

    .voice-icon {
        width: 32px;
        height: 32px;
        margin-right: 15px;
    }

    p.upcoming-reservation-description {
        font: normal normal normal 16px/22px Source Sans Pro;
        padding-top: 20px;
    }

    .mtop {
        margin-top: 30px;
    }
}
