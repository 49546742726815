.destination-highlights-section {
    max-width: 472px;

    .destination-logo {
        width: 230px;
        margin-top: 25px;
    }

    .margin-top-no-logo {
        margin-top: 60px;
    }

    .destination-description {
        font: normal normal 600 18px/24px Source Sans Pro;

        .read-more, svg {
            margin-top: 20px;
        }
    }

    .buy-tickets {
        margin: 0 auto 87px auto;
        width: 351px;
        min-height: 56px;
        letter-spacing: 1px;
    }
}
