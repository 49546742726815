.tooltip-title {
    font: normal normal 600 13px/16px Source Sans Pro;
    color: $black;
}

.tooltip-content {
    font: normal normal normal 13px/16px Source Sans Pro;
    color: $grey3;
}

.tooltip-title, .tooltip-content  {
    text-align: left;
    letter-spacing: 0;
    margin: 0;
}
