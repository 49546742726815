.pre-registration-section {
    h1 {
        font: normal normal bold 25px/30px Playfair Display;
        padding-top: 196px;
    }

    p {
        font: normal normal normal 18px/24px Source Sans Pro;
        padding-bottom: 50px;
        max-width: 474px;
    }

    .pre-registration-button {
        width: 351px;
        margin-bottom: 289px;

        a {
            letter-spacing: 1px;
        }
    }
}
