.contracts-list {
    padding-top: 30px;
    padding-bottom: 60px;

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .list-contract {
        cursor: pointer;
        padding-left: 0;
        padding-right: 0;

        .odd {
            background-color: $grey2;
        }

        .even {
            background-color: $white;
        }

        div {
            .modal-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: white;
            margin: 200px auto;

            h1 {
                font: normal normal bold 15px Source Sans Pro;
            }

            .manage-payments-button {
                margin: 0 auto;
                    button {
                        background: #446DB4;
                        border: #446DB4;
                        color: white;
                        height: 56px;
                        text-transform: uppercase;
                        opacity: 1;
                        margin: 14px auto;
                        max-width: 331px;
                        width: 100%;
                    }
                }
            }
        }

        .list-my-contracts {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            height: 63px;
            width: 100%;
            align-items: center;

            div.contract-number-image{
                display: flex;
            }

            p.resort-name {
                font: normal normal bold 20px/22px Source Sans Pro;
                letter-spacing: 0;
                color: $grey;
                opacity: 1;
            }

            div.contract-number {
                //text-decoration: underline;
                font: normal normal bold 20px/22px Source Sans Pro;
                letter-spacing: 0;
                color: $grey;
                opacity: 1;
                //display: flex;

                .contract-border {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    border: 1px solid #7B9AD1;
                    width: 100%;
                }

            }
            img {
                margin-left: 10px;
            }
        }
    }

    .MuiListItem-container {
        background-color: $grey2;
        height: 63px;
        display: flex;
    }

    .MuiSvgIcon-root {
        color: $blue;
        height: 22px;
    }
}
