.request-reservation-form {
    width: 935px;
    margin: 0 auto;
    padding: 80px 0px 80px 0px;

    .title {
        text-align: center;
        font: normal normal bold 25px/32px Playfair Display;
        letter-spacing: 0;
        color: $black;
        text-transform: capitalize;
        opacity: 1;
        margin-bottom: 60px;
    }

    // Select field for request reservation
    .custom-select-field-wrapper {
        &.request-reservation {
            background-color: $white;
            border-radius: 2px;
            padding-left: 12px;
            margin: 0 !important;

            &.error-border {
                border: 1px solid $error;
            }

            .MuiFormLabel-root {
                padding-left: 12px;
            }

            .MuiInputBase-input {
                text-align: left;
                font: normal normal normal 20px/16px Source Sans Pro;
                letter-spacing: 0px;
                opacity: 1;
                color: $black;
            }

            .MuiInput-underline::before, .MuiInput-underline::after {
                border: none;
            }

            label + .MuiInput-formControl {
                margin-top: 24px;
            }

            .MuiInputLabel-shrink {
                transform: translate(2px, 10px) scale(0.75);
                transform-origin: top left;
            }

            .MuiSvgIcon-root {
                margin-bottom: 10px;
                margin-right: 12px;
            }
        }
    }

    //END Select field for request reservation

    .guests-container {
        background-color: $white;

        .substract {
            margin-right: 16px;
            cursor: pointer;
        }

        .add {
            cursor: pointer;
        }
    }

    // Date picker
    .date-picker-container {
        &.request-reservation {
            width: 100%;

            .MuiFilledInput-root {
                cursor: pointer;
                background-color: $white;
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
            }

            .MuiFilledInput-underline::before, .MuiFilledInput-underline::after {
                border: none;
            }

            &.MuiFormControl-marginNormal {
                margin: 0 !important;
            }

            .MuiInputBase-input {
                text-align: left;
                font: normal normal normal 20px/16px Source Sans Pro;
                letter-spacing: 0px;
                color: $black;
                opacity: 1;
            }

            .MuiIconButton-root {
                padding: 0;
                color: $grey1;
            }


            .MuiFormLabel-root {
                color: $grey1;
                font-family: Source Sans Pro;
                letter-spacing: 0px;
            }

            .MuiFilledInput-root.Mui-error {
                border: 1px solid $error;
            }

            .MuiFormLabel-root.Mui-focused {
                color: $grey1;
            }

            .MuiInputLabel-filled {
                transform: translate(12px, 24px) scale(1);
            }

            .MuiInputLabel-filled.MuiInputLabel-shrink {
                transform: translate(12px, 10px) scale(0.75);
            }

            .MuiFormLabel-root.Mui-error {
                color: $error;
            }

            .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
                border: 1px solid $error;
            }

            .icon-error {
                color: $error;
            }
        }
    }

    // END Date picker

    // Text Field
    .custom-field {
        .request-reservation {
            background-color: $white;


            .MuiInputBase-input {
                text-align: left;
                font: normal normal normal 20px/16px Source Sans Pro;
                letter-spacing: 0px;
                color: $black;
                opacity: 1;
            }

            .MuiInput-underline::before, .MuiInput-underline::after {
                border: none;
            }

            &.MuiFormControl-marginNormal {
                margin: 0;
                border-radius: 2px;
            }

            label + .MuiInput-formControl {
                margin-top: 24px;
            }

            .MuiInputLabel-shrink {
                transform: translate(2px, 10px) scale(0.75);
                transform-origin: top left;
            }

            .MuiInputBase-root {
                padding-left: 12px;
            }

            .MuiFormLabel-root {
                padding-left: 12px;
            }

            .MuiFormLabel-root.Mui-disabled {
                color: $grey1;
            }
        }
    }

    button[type=submit] {
        font: normal normal 600 20px/36px Source Sans Pro;
        height: 56px;
        width: 291px;
        text-transform: none;
        margin-bottom: 30px;
        letter-spacing: 2px;
    }

    // End Text Field

    .toggle-container {
        margin: 30px 20px 0px 20px;
    }
}
