.monthly-payment-header {
    .select-one {
        font: normal normal normal 16px/22px Source Sans Pro;
    }

    .monthly-payment-header-item {
        width: 108px;
        height: 75px;
        padding: 10px;
        justify-content: space-around;

        &.monthly-payment-header-item:not(:last-child) {
            margin-right: 8px;
        }

        &.monthly-payment-header-item:last-child {
            .label {
                margin-top: 10px;
            }
        }

        .label {
            font: normal normal normal 13px/13px Source Sans Pro;
            letter-spacing: 0px;
        }

        .value {
            font: normal normal 600 16px/11px Source Sans Pro;
        }
    }
}
