.destination-highlights-section {
    max-width: 282px;

    .destination-logo {
        margin-top: 30px;
    }

    .margin-top-no-logo {
        margin-top: 30px;
    }

    .destination-location {
        font: normal normal normal 14px/22px Source Sans Pro;
        letter-spacing: 0px;
    }

    .destination-description {
        text-align: center;
        font: normal normal 600 15px/20px Source Sans Pro;
        letter-spacing: 0;
        padding-top: 20px;
        padding-bottom: 30px;

        .read-more, svg {
            margin-top: 15px;
            font: normal normal bold 16px/16px Source Sans Pro;
        }
    }

    .buy-tickets {
        margin: 0 auto 50px auto;
        width: 282px;
        letter-spacing: 1px;
    }
}
