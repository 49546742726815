.account-header {
    height: 70px;
    align-items: center;

    .logo {
        width: 157.5px;
    }

    .header-profile {
        justify-content: flex-end;
        align-items: center;

        .header-user-name {
            margin-right: 15px;
            text-transform: capitalize;
        }

        .icon {
            cursor: pointer;

            &.notifications {
                margin-left: 32px;
                margin-top: -8px;
            }
        }

        a {
            text-decoration: none;
            color: $white;
        }
    }

}

.vidanta-badge {
    .MuiBadge-anchorOriginTopRightRectangle {
        background-color: $red;
        font-size: 16px;
        font-family: Source Sans Pro, sans-serif;
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
}

.vidanta-badge-small {
    .MuiBadge-anchorOriginTopRightRectangle {
        background-color: $red;
        font-size: 11px;
        font-family: Source Sans Pro, sans-serif;
        width: 14px;
        min-width: 14px;
        height: 14px;
        border-radius: 50%;
    }
}



.beta-layout {
    height: 50px;
    display: inline-flex;
    margin-left: 20px;

    .beta-ring {
        border: 2px solid white;
        border-radius: 50%;
        height: 50px;
        position: absolute;
        width: 50px;
        text-align: center;
    }

    .beta-ring-inside {
        background-color: white;
        border-radius: 50%;
        height: 40px;
        position: absolute;
        width: 40px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .beta-text {
            color: #000000;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            font-weight: bolder;
            font-size: 9px;
            line-height: 9px;
        }
    }

    .beta-message {
        width: 145px;
        font-size: 12px;
        margin: auto 2px auto 60px;
        line-height: 13px;
    }


}

.header-accordion{
    margin-left: 15px !important;
    margin-top: 0px !important;
}

.MuiAccordionSummary-root.header-menu-item-accordion-summary {
    padding: 0px 16px;
}

.MuiAccordionSummary-root.header-menu-item-accordion-summary.Mui-expanded {
    background: rgb(16, 16, 16);
    border-radius: 5px 5px 0 0;
    min-height: 48px !important;

    .MuiAccordionSummary-content.Mui-expanded{
        margin: 10px 0 !important;
    }
}

.MuiAccordionDetails-root.header-menu-item-accordion-details {
    position: absolute;
    background: #101010;
    width: 100%;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #686868;
}