.vida-vacations {

    .center-text {
        width: 472px;
        height: 47px;
        margin: 40px auto 60px auto;
        text-align: center;
    }

    .my-vida-vacations-payments-right {
        width: 420px;

        h1 {
            text-align: left;
            font: normal normal bold 22px/25px Source Sans Pro;
            letter-spacing: 0.9px;
            color: #000000;
            text-transform: uppercase;
            opacity: 1;
            padding-left: 17px;
            margin-left: 15px;
        }
    }
    .center-text > p {
        opacity: 1;
        margin: auto;
        text-align: center;
        font: normal normal normal 18px/24px Source Sans Pro;
    }
}
