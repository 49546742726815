
.general-contract-item {
    display: flex;
    flex: 1;
    padding: 0 15px;
    height: 72px;

    &.first {
        padding-left: 0px;
        padding-right: 10px;
    }

    &.last {
        padding-left: 10px;
        padding-right: 0px;
    }

    &.single {
        padding: 0;
    }

    .emty {
        padding: 10px 0;
    }
}

.icon-arrow-container {
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black;
    position: absolute;

    &.left-btn-arrow {
        left: 10px;
    }

    &.right-btn-arrow {
        right: 10px;
    }
}

.contract-button {
    width: 100%;
    height: auto;
    background: linear-gradient(270deg, #a3a3a3, #414141);
    border: none;
    padding: 10px;
    border-radius: 2px;

    p.contract-text {
        margin-bottom: 0;
        text-align: center;
        font: normal normal normal 16px Source Sans Pro;
        letter-spacing: 0.9px;
        color: $black;
        text-transform: uppercase;

        &.contract-number {
            font: normal normal bold 20px Source Sans Pro;
            letter-spacing: 1px;
        }
    }

}

.contract-button-active {
    width: 100%;
    height: auto;
    background: $white;
    border: none;
    padding: 10px;
    border-radius: 2px;

    p.contract-text {
        margin-bottom: 0;
        text-align: center;
        font: normal normal normal 16px Source Sans Pro;
        letter-spacing: 0.9px;
        color: $black;
        text-transform: uppercase;

        &.contract-number {
            font: normal normal bold 20px Source Sans Pro;
            letter-spacing: 1px;
        }
    }
}

.no-contracts-available {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 14px;
}

