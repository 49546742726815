.payments-type {
    .payments-container {
        .payments-title-container {
            .payment-type-text {
                margin-top: 50px;
                margin-bottom: 40px;
                h3 {
                    font: normal normal bold 25px/30px Playfair Display;
                }
            }
        }
    }

    .payment-type-options {
        width: 503px;
        max-width: 503px;
    }
}
