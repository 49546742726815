.about-section {
    h1 {
        font: normal normal bold 25px/30px Playfair Display;
        padding-top: 60px;
    }

    p {
        font: normal normal 600 18px/24px Source Sans Pro;
        padding-top: 20px;
        padding-bottom: 60px;
        max-width: 472px;
    }
}
