.entry-section {
    .entry-section-contracts-title {

        h1 {
            font: normal normal bold 18px/22px Source Sans Pro;
            letter-spacing: 0.9px;
        }
    }

    .entry-section-content {
        width: 282px;

        p {
            font: normal normal 600 15px/20px Source Sans Pro;
            width: 282px;
            text-align: center;
            margin: auto;
            padding-top: 41px;
        }
    }
}
