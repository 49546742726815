.contracts-list {
    padding-top: 30px;
    padding-bottom: 60px;

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .list-contract {
        cursor: pointer;
        padding-left: 0;
        padding-right: 0;
        box-shadow: none;

        .odd {
            background-color: #e5e5e5;
        }

        .even {
            background-color: #f9f9f9;
        }


        div {
            .modal-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: white;
            margin: 200px auto;

            h1 {
                font: normal normal bold 15px Source Sans Pro;
            }

            .manage-payments-button {
                margin: 0 auto;
                    button {
                        background: #446DB4;
                        border: #446DB4;
                        color: white;
                        height: 56px;
                        text-transform: uppercase;
                        opacity: 1;
                        margin: 14px auto;
                        max-width: 331px;
                        width: 100%;
                    }
                }
            }
        }

        .list-my-contracts {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            height: 63px;
            width: 100%;
            align-items: center;

            div.contract-number-image{
                display: flex;
            }

            p.resort-name {
                font: normal normal bold 20px/22px Source Sans Pro;
                letter-spacing: 0;
                color: $grey;
                opacity: 1;
            }

            div.contract-number {
                //text-decoration: underline;
                font: normal normal bold 20px/22px Source Sans Pro;
                letter-spacing: 0;
                color: $grey;
                opacity: 1;
                //display: flex;

                .contract-border {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    border: 1px solid #7B9AD1;
                    width: 100%;
                }

            }
            img {
                margin-left: 10px;
            }
        }

        .accordion-summary{
            justify-content: space-between;
            display: flex;
            width: 100%;
    
            .accordion-summary-item{
                font-weight: bold;
            }
        }

        .details-container-header{
            background: #446DB4;
            border-radius: 2px;
        
            .label{
                font: normal normal bold 15px/20px Source Sans Pro;
                color: $white;
                text-align: center;
            }
        }

        .details-container-item{
            padding: 5px;

            .desktop{
                display: inline-flex;;
            }

            .device{
                display: none;
            }

            .label{
                font: normal normal bold 16px/20px Source Sans Pro;
            }

            .label-value{
                text-align: center;
                font: normal normal bold 16px/20px Source Sans Pro;

                .value {
                    font: normal normal bold 16px/20px Source Sans Pro;
                    .total {
                        font: normal normal bold 16px/20px Source Sans Pro;
                    }
    
                    sup {
                        &.total-sup {
                            font: normal normal bold 14px/18px Source Sans Pro;
                            top: -1em;
                            font-size: 10px;
                        }
                    }
                }
            }

            .odd {
                background-color: #e5e5e5;
            }
    
            .even {
                background-color: #f9f9f9;
            }

            .download-icon {
                color: #446DB4;
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;
                height: 18px;
            }
        }
        
        .empty-text {
            padding: 10px;
        }

    } 

    .MuiListItem-container {
        background-color: $grey2;
        height: 63px;
        display: flex;
    }

    .MuiSvgIcon-root {
        color: $blue;
        height: 22px;
    }

    .MuiAccordionDetails-root {
        display: block;

    }

    .MuiAccordion-root:before {
        background-color: #00000000;
    }
}
