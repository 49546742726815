.exchanges-carousel {
    height: auto;
    padding: 40px 0 94px 0;

    h2 {
        text-transform: uppercase;
        margin: 0 auto 40px;
        text-align: center;
        font: normal normal bold 25px/32px Source Sans Pro;
        color: $black;
        letter-spacing: 2.5px;
    }

    .slick-arrow {
        width: 24px;
        height: 24px;
        background-color: $blue;
    }

    .slick-arrow::before {
        border: solid $white;
        border-width:0 4px 4px 0;
        padding: 1px;
    }

    .slick-prev {
        left: -10px;
    }


    .slick-next {
        right: -10px;
    }

    @media screen and (min-width: 400px) {
        .slick-prev {
            left: 0px;
        }

        .slick-next {
            right: 0px;
        }

    }

    @media screen and (min-width: 430px) {
        .slick-prev {
            left: 10px;
        }

        .slick-next {
            right: 10px;
        }

    }

    @media screen and (min-width: 460px) {
        .slick-prev {
            left: 40px;
        }

        .slick-next {
            right: 40px;
        }

    }

    @media screen and (min-width: 480px) {
        .slick-prev {
            left: 55px;
        }

        .slick-next {
            right: 55px;
        }
    }

    .slick-disabled {
        opacity: 0.5;
    }

    .slick-dots li.slick-active button:before {
        color: #446DB4;
        font-size: 10px;
    }

    .slick-dots {
        bottom: -35px;
    }

    .slick-dots li {
        height: 20px;
        width: 20px;
        margin: 0 5px 0 5px;
        cursor: pointer;
    }

    .slick-track {
        display: flex;
    }

    .slick-slider {
        width: 100%;

        .slick-list {
            margin: 0;
            width: 100%;
        }
    }

    .slick-slide {
        opacity: 0.5;
        height: 191px;
        max-width: 282px;
        margin: 0;

        .card {
            max-width: 282px;
            height: 191px;
            cursor: pointer;
            position: relative;
            overflow: hidden;


            .card-container {
                display: block;
                position: relative;
                height: 191px;
                width: 282px;

                .card-content {
                    width: 282px;
                    height: 56px;
                    background: #e5e5ea 0% 0% no-repeat padding-box;
                    border-radius: 2px;

                    .card-img {
                        padding: 75px 0 0 0;
                        img {
                            max-height: 65px;
                        }
                    }

                    .caption {
                        .subtitle {
                            color: $grey3;
                            padding: 20px 20px 0 20px;
                            font: normal normal normal 16px/22px Source Sans Pro;
                            text-align: left;
                            letter-spacing: 0;
                        }
                    }

                    .card-button {
                        width: 100%;
                        height: 56px;
                        margin: 0px 0px 0px 0px;
                        background: #446DB4 0% 0% no-repeat padding-box;

                        .link-btn {

                            color: white;
                            height: 56px;
                            display: flex;
                            text-decoration: none;
                            justify-content: center;
                            align-items: center;
                            bottom: 0;
                            padding-top: 15px;

                            .visit-website-img {
                                margin-bottom: 15px;
                                margin-right: 8px;
                            }

                            .visit-website {
                                font: normal normal 600 20px/36px Source Sans Pro;
                                letter-spacing: 1px;
                                color: #FFFFFF;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }

    .slick-center {
        opacity: 1;
    }
}

