.transportation-title-container {
    margin-bottom: 30px;

    .transportation-title {
        text-align: center;
        font: normal normal bold 18px Playfair Display;
        letter-spacing: 0;
        color: $black;
        text-transform: capitalize;
        opacity: 1;
    }
}

.transportation-container {
    max-width: 440px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    margin: 0 auto;

    .transportation-image-container {
        width: 119px;
        height: 119px;

        .transportation-image {
            width: 119px;
            height: 119px;
            object-fit: cover;
        }
    }

    .transportation-info-container {
        //width: 905px;
        background: $greyWhite no-repeat padding-box;

        .transportation-info {
            margin-top: 0px;
            padding: 19px;

            p.transportation-subtitle {
                text-align: center;
                font: normal normal bold 16px Playfair Display;
                letter-spacing: 0px;
                color: $black;
                opacity: 1;
                margin-bottom: 0;
            }

            p.transportation-text {
                text-align: center;
                font: normal normal normal 15px Source Sans Pro;
                letter-spacing: 0px;
                color: $grey;
                opacity: 1;
                margin-bottom: 0;
            }
        }
    }
}
