.container-white {
    margin: 0;

    .container {
        margin: 0;
        padding-right: 0;
        padding-left: 0;

        .MuiContainer-root {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.container-message {
    margin: 0;
}



.message-area-reply-form {
    height: 150px;
    display: flex;

    .send-message-btn-center {

        .send-message-btn {
            border: 0;
            outline: none;
            background-repeat: no-repeat;
            background-color: $switch-bg-color;
            border-radius: 100%;
        }
    }
}

.message-area-reply-notification {
    background-color: $blue;
    display: flex;
    justify-content: center;

    > p {
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 1px;
        color: $white;
        text-transform: uppercase;
        opacity: 1;
        margin-top: 16px;
    }
}


.MuiInput-underline:before {
    border-bottom: 0;
}

.send-message-container {
    .MuiInput-input {
        position: relative;
    }
}
.accordion .MuiAccordionSummary-content {
    text-transform: none;
    padding-bottom: 0;
    min-height: 74px;
    height: auto;
}

.container-message {
    background-color: $white;
    margin-top: 0;

    .accordion-container {
        padding-top: 30px;
    }

    .message-content {
        margin: 0 0  5px 0;

        .MuiAccordionDetails-root {
            padding: 0 25px;
        }

        .message-subject-read > h2 {
            font: normal normal bold 22px/25px Source Sans Pro;
            opacity: 1;
        }

        .message-header-date > p {
            font: normal normal normal 16px/20px Source Sans Pro;
        }

        .show-message-body {

            .message-body > p {
                font: normal normal normal 18px/23px Source Sans Pro;
                color: $body-bg;
                opacity: 1;
            }
            .message-header > p {
                text-align: right;
                font: normal normal normal 16px/20px Source Sans Pro;
                letter-spacing: 0;
                opacity: 1;
            }

            .message-header-info {
                padding-left: 10px;
            }

            .message-header-info > p {
                font: normal normal normal 16px/20px Source Sans Pro;
                letter-spacing: 0;
                color: $blue;
                opacity: 1;
            }

        }
    }

    .message-header {
        display: inline-flex;
        border-bottom: 1px solid $grey7;
        margin-bottom: 30px;

        .message-header-label > p {
            text-align: left;
            font: normal normal 600 13px/20px Source Sans Pro;
            letter-spacing: 0;
            color: $grey6;
        }
    }

    .accordion-item {
        width: 100%;
        background-color: $greyWhite;
        padding: 15px 26px;

        .accordion {
            .MuiAccordionSummary-content {
                border-bottom: 0;
            }
        }

        .message-subject > h3 {
            text-align: left;
            font: normal normal bold 16px/20px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey;
            opacity: 1;
        }

        .message-header-date > p {
            font: normal normal normal 13px/20px Source Sans Pro;
            color: $grey1;
            opacity: 1;
        }

        .message-body > p {
            text-align: left;
            font: normal normal normal 15px/16px Source Sans Pro;
            letter-spacing: 0;
            color: $grey3;
            opacity: 1;
        }
    }

}

.reply {
    background-color: $blue;
    margin: 0 auto;
    text-align: center;
    height: 56px;

    > p {
        text-align: center;
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 1px;
        color: $white;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 9px;
    }
}

.second-container {
    margin-bottom: 20px;
}

.edit-profile-link {
    font: normal normal 600 16px/36px Source Sans Pro;
    letter-spacing: 0;
    color: $white;
    opacity: 1;
}

a {
    text-decoration: none;
}

.message-area-reply-form form {
    width: 100%;
    padding: 15px 25px;

    > div {
        align-items: flex-start;
    }
}
