.custom-payment-field {
    margin-bottom: 30px;
    max-height: 176px;
    overflow-y: scroll;

    label {
        margin-bottom: 0;
    }

    .MuiCheckbox-root {
        color: $grey6;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
        color: $mustard;
    }

    .MuiCheckbox-colorSecondary.Mui-checked:hover {
        background-color: transparent;
    }

    .MuiIconButton-colorSecondary:hover {
        background-color: transparent;
    }

    .MuiFormControlLabel-label {
        text-align: left;
        font: normal normal normal 16px/36px Source Sans Pro;
        letter-spacing: 0;
        opacity: 1;
    }

    // Monthly payment section
    .payment-field {
        height: 42px;
        color: $grey6;

        &.checked {
            color: $mustard;
        }

        .payment-checked-icon {
            background-color: #C5A57B;
            color: white;
            border-radius: 2px;
            margin-left: 2px;
            font-size: 1.2rem;
            margin-right: 3px;
        }
    }

    // End monthly payment section

}
