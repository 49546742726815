.edit-profile-form {
    padding: 30px 0 30px 0;
    margin: 0 auto;

    .member-no {
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    .member-no-edit {
        margin-top: -150px;
    }

    .title {
        color: $black;
        text-align: center;
        font: normal normal bold 25px/32px source sans pro;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        opacity: 1;
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .sub-title {
        text-align: center;
        font: normal normal normal 18px/24px source sans pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        margin-bottom: 19px;
    }

    .bg-white{
        background: $grey2 !important;
    }

    .change-field-btn-container {
        cursor: pointer;

        .field-label {
            text-align: left;
            font: normal normal 400 12px/36px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey1;
            opacity: 1;
        }

        .field-btn {
            text-align: left;
            font: normal normal normal 18px/36px Source Sans Pro;
            letter-spacing: 0px;
            color: $black;
            opacity: 1;
            border-bottom: 1px solid $grey1;
            margin-top: -6px;
        }
    }

    .form-title {
        text-align: left;
        font: normal normal bold 18px/22px source sans pro;
        letter-spacing: 0;
        color: $black;
        text-transform: capitalize;
        opacity: 1;
        margin-top: 60px;
    }

    .form-title-underline {
        border: 1px solid $black;
        width: 3%;
        margin-left: 0;
        margin-bottom: 25px;
    }

    .select-language-container, .communication-preferences {
        .first-item {
            margin-right: 35px;
        }

        span {
            text-align: left;
            font: normal normal normal 18px/36px Source Sans Pro;
            letter-spacing: 0px;
            opacity: 1;
            color: $black;
        }

        .muiradio-colorsecondary.mui-checked {
            color: $blue;
        }

        .muitypography-root {
            text-align: left;
            font: normal normal normal 18px/36px source sans pro;
            letter-spacing: 0px;
            color: $black;
            opacity: 1;
        }
    }

    .submit-btn {
        background-color: $blue;
        outline: none;
        margin: 90px 90px 0 90px;
        padding: 10px 60px;
        border: none;
        border-radius: 2px;
        text-align: center;
        font: normal normal 600 20px/36px source sans pro;
        letter-spacing: 2px;
        color: $white;
        text-transform: uppercase;
        opacity: 1;
        height: 56px;
    }

}

