.accordion {
    .MuiAccordionSummary-content {
        font: normal normal bold 18px/20px Source Sans Pro;
        letter-spacing: 0.9px;
    }

    .MuiAccordion-rounded:last-child {
        margin-bottom: 0px;
    }
}
