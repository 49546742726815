.entry-section {
    .entry-section-content {
        width: 472px;
        text-align: center;
        margin: auto;

        p {
            font: normal normal 600 18px/24px Source Sans Pro;
        }
    }
}
