@import "desktop";
@import "tablet";
@import "mobile";

::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.3125rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f7f9fc;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    cursor: pointer;
    border-radius: 0.15625rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $grey11;
}