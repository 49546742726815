.one-more-step {
    h1 {
        margin: 0;
        text-align: center;
        text-transform: capitalize;
        font: normal normal bold 25px/32px Playfair Display;
        letter-spacing: 0;
        color: $black;
        opacity: 1;
        padding-bottom: 10px;
    }
    .one-more-line {
        margin: 38px 0 25px;
    }
}

.one-more-step-form {
    margin: 0 auto;
    max-width: 675px;

    .form-title {
        text-align: left;
        font: normal normal bold 18px/22px source sans pro;
        letter-spacing: 0;
        color: $black;
        text-transform: capitalize;
        opacity: 1;
        margin-top: 30px;
    }

    .form-title-underline {
        border: 1px solid $black;
        width: 3%;
        margin-left: 0;
        margin-bottom: 25px;
    }

    .custom-field {

        .MuiInputBase-input {
            text-align: left;
            font: normal normal normal 18px/36px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey6;
            opacity: 1;
        }

        .MuiInputBase-input.MuiInput-input {
            text-align: left;
            font: normal normal normal 18px/36px Source Sans Pro;
            letter-spacing: 0px;
            color: $black;
            opacity: 1;
        }
    }

    .select-member-container {
        .first-item {
            margin-right: 15px;
        }

        .custom-radio {
            margin-right: 55px;
        }

        span {
            text-align: left;
            font: normal normal normal 18px/22px Source Sans Pro;;
            letter-spacing: 0px;
            opacity: 1;
            color: $grey;
        }

        .muiradio-colorsecondary.mui-checked {
            color: $blue;
        }

        .muitypography-root {
            text-align: left;
            font: normal normal normal 18px/22px Source Sans Pro;;
            letter-spacing: 0px;
            color: $grey;
            opacity: 1;
        }
    }

    .travelers-container {
        margin-top: 40px;
        background-color: $white;
        border: 1px solid $grey4;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .add-button-container {
            text-align: right;

            .add {
                padding-right: 10px;
                padding-left: 0;
            }
        }

        .reservation-summary-form {
            .MuiFormLabel-root.Mui-disabled {
                margin-left: 15px;
                font: normal normal 600 13px/13px Source Sans Pro;
                color: $grey1;
            }
            .MuiInputBase-root.Mui-disabled {
                margin-left: 15px;
            }
            .MuiInputBase-input.Mui-disabled {
                color: $black;
                letter-spacing: 0px;
                font: normal normal normal 20px/16px Source Sans Pro;
            }
            .MuiInput-underline::before {
                border: none;
            }
        }
    }

    .additional-button-container-adding {
        margin-top: 25px;
        margin-bottom: 300px;

        .cancel-button {
            background: none;
            border: none;
            font: normal normal 600 16px/13px Source Sans Pro;
            text-decoration: underline;
            color: $grey1;
            text-transform: uppercase;
            padding-left: 0;
            padding-top: 20px;
        }

        .add-save-button {
            background: none;
            border: none;
            font: normal normal 600 16px/13px Source Sans Pro;
            text-decoration: underline;
            color: $blue;
            text-transform: uppercase;
            padding-right: 0;
            float: right;
            padding-top: 20px;
        }
    }

    .additional-button-container {
        margin-top: 25px;

        .cancel-button {
            background: none;
            border: none;
            font: normal normal 600 16px/13px Source Sans Pro;
            text-decoration: underline;
            color: $grey1;
            text-transform: uppercase;
            padding-left: 0;
            padding-top: 20px;
        }

        .add-save-button {
            background: none;
            border: none;
            font: normal normal 600 16px/13px Source Sans Pro;
            text-decoration: underline;
            color: $blue;
            text-transform: uppercase;
            padding-right: 0;
            float: right;
            padding-top: 20px;
        }
    }

    .addition-traveler-item-container {
        justify-content: space-between;
    }
}

.submit-container {
    display: flex;

    .submit {
        width: 307px;
        height: 56px;
        background: $blue 0% 0% no-repeat padding-box;
        border: none;
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 2px;
        color: $white;
        text-transform: uppercase;
        margin-top: 90px;
        margin-bottom: 150px;
    }
}
