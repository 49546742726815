.profile-details {
    max-width: 726px;
    /* height: 121px; */
    background-color: #446DB4;
    margin: 0 auto;
    /* padding: 0 50px; */
    padding: 20px 60px 20px 60px;

    .name {
        text-align: left;
        font: normal normal normal 24px/32px Source Sans Pro;
        letter-spacing: 0px;
        color: $white;
        opacity: 1;
        margin-bottom: 15px;
    }

    .link {
        text-align: left;
        font: normal normal 600 16px/14px Source Sans Pro;
        letter-spacing: 0px;
        opacity: 1;

        a {
            text-decoration: underline;
            color: $white;
            text-underline-offset: 5px;
        }
    }

    .vida-profile-details-id {
        position: relative;
    }

    .logo {
        text-align: right;
        width: 100%;
        margin-top: 15px;
        margin-left: 30px;
    }
}
