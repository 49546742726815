.not-found {
    .not-found-container {
        height: 50vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }

    h1 {
        color: $white;
        text-align: center;
        font: normal normal bold 36px/32px Source Sans Pro;
        letter-spacing: 2.5px;

        &.authenticated {
            color: $black;
        }

        &.not-authenticated {
            padding-top: 200px;
        }
    }
}
