.reservation-page {
    padding-top: 60px;
    max-width: 450px;

    h2 {
        font: normal normal bold 25px/30px Playfair Display;
    }

    p {
        padding-top: 30px;
        letter-spacing: 0;

        > span {
            font: normal normal bold 18px/24px Source Sans Pro;
            letter-spacing: 0;
        }
    }

    .reservation-form {
        padding: 60px 30px 0 30px;

        .submit-btn {
            margin: 60px auto 181px auto;
            max-width: 228px;
        }
    }
}
