.global-notification-container {
    width: 100%;
    background-color: green;
    min-height: 60px;
    display: flex;
    align-items: center;
    font: normal normal normal 18px/20px Source Sans Pro;
    z-index: 9999999999;
    color: $white;
    position: fixed;

    &.error {
        background-color: $error;
    }
    &.warning {
        background-color: $warning;
    }

    .global-text {
        text-align: center;
        margin: 0 auto;
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .global-icon {
        padding-right: 20px;
        cursor: pointer;
    }
}
