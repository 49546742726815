.no-margin-header-container {
    margin: 0 0px 0 -15px;
}

.account-header {
    height: 56px;

    .logo {
        width: 118px;
    }

    .header-profile {
        .header-user-name {
            display: none;
        }

        .icon {
            &.notifications {
                margin-left: 20px;
            }
        }
    }
}

.vidanta-badge {
    .MuiBadge-anchorOriginTopRightRectangle {
        font-size: 11px;
        width: 17px;
        height: 20px;
    }
}

.beta-layout {
    height: 33px;
    margin-left: 57px;

    .beta-ring {
        height: 40px;
        width: 40px;
    }

    .beta-ring-inside {
        height: 30px;
        width: 30px;
        
        .beta-text {
            font-size: 7px;
            line-height: 7px;
        }
    }
}

.header-accordion{
    display: none;
}
