.contract-button {
    p.contract-text {
        font: normal normal normal 16px/18px Source Sans Pro;
        letter-spacing: 0.8px;

        &.contract-number {
            font: normal normal bold 16px/18px Source Sans Pro;
            letter-spacing: 0.8px;
        }
    }
}

.contract-button-active {
    p.contract-text {
        font: normal normal normal 16px/18px Source Sans Pro;
        letter-spacing: 0.8px;

        &.contract-number {
            font: normal normal bold 16px/18px Source Sans Pro;
            letter-spacing: 0.8px;
        }
    }
}

.no-contracts-available {
    padding-top: 0px;
}

