.club-section {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    h1 {
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        font: normal normal bold 18px/24px Source Sans Pro;
        letter-spacing: 0.9px;
        color: $black;
        opacity: 1;
        padding-top: 30px;
    }

    p {
        text-align: center;
        font: normal normal normal 16px/22px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        padding-top: 20px;
        padding-bottom: 40px;
        max-width: 296px;
        margin: 0 auto;
    }

    .club-read-more {
        width: 282px;
        height: 56px;
        margin-bottom: 62px;

        a {
            font: normal normal 600 20px/36px Source Sans Pro;
            letter-spacing: 1px;
        }
    }
}
