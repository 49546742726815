.profile-notifications {
    padding-bottom: 0;

    > div.container {
        padding: 0;
    }

    .profile-notifier {
        margin-top: 0;
        border-top: 1px solid $grey3;
        border-right: 1px solid $grey3;
        max-width: 50%;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 0 25px 0;

        h1 {
            margin : 0;
        }

        &:last-child {
            border-right: 0;
        }

        .profile-notifications-image {
            width: 35px;
            height: 25px;
        }

        .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle {
            transform: scale(0.8) translate(30%, 50%);
        }

        .MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible {
            transform: scale(0) translate(50%, -50%);
        }
    }


    .margin {
        margin-top: 0;
    }

    h1.profile-notifications-label {
        font: normal normal normal 13px/13px Source Sans Pro;
    }
    .notify {
        background: #000;
        padding-top: 35px;
    }
}
