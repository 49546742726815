.other-fees-payment-form-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .select-one {
        text-align: right;
        font: normal normal normal 18px/20px Source Sans Pro;
        letter-spacing: 0px;
        color: $grey7;
        opacity: 1;
        margin-bottom: 18px;
    }

    .other-fees-payment-form-header-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 214px;
        height: 80px;
        background-color: $grey3;
        margin-bottom: 15px;
        border-radius: 2px;
        padding: 0 10px;
        cursor: pointer;
        position: relative;

        &.other-fees-payment-form-header-item:not(:last-child) {
            margin-right: 15px;
        }

        &.selected {
            background-color: $mustard;
        }

        .label {
            text-align: center;
            font: normal normal normal 18px/20px Source Sans Pro;
            letter-spacing: 0px;
            color: $white;
            text-transform: uppercase;
            opacity: 1;
        }

        .attention-container {
            width: 16px;
            height: 16px;
            background-color: $white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -8px;
            right: -8px;
            border: 2px solid $red;

            .attention-svg {
                color: $red;
                width: 16px;
                height: 16px;
            }
        }
    }
}
