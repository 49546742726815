.payment-method-header {
    width: 674px;
    margin: 0 auto;

    .title {
        text-align: center;
        font: normal normal bold 25px/25px Source Sans Pro;
        letter-spacing: 1.25px;
        color: $white;
        text-transform: uppercase;
        opacity: 1;
        margin: 30px 0;
    }

    .payment-method-header-item {
        border-top: 1px solid $grey1;
        padding: 30px 0;
        &:last-child{
            margin-bottom: 30px;
        }
        .label {
            text-align: left;
            font: normal normal normal 18px/20px Source Sans Pro;
            letter-spacing: 0px;
            color: $mustard;
            text-transform: uppercase;
            opacity: 1;
        }

        .values-container {
            text-align: right;

            .value {
                text-align: right;
                font: normal normal normal 20px/20px Source Sans Pro;
                letter-spacing: 0px;
                color: $white;
                opacity: 1;

                .total {
                    font: normal normal normal 30px/20px Source Sans Pro;
                }

                sup {
                    &.total-sup {
                        top: -1em;
                    }
                }
            }

        }

        .payment-amount {
            display: flex;
            justify-content: flex-end;
            width: 70%;
            height: 42px;

            .placeholder {
                background-color: $grey3;
                text-align: left;
                font: normal normal normal 16px/20px Source Sans Pro;
                letter-spacing: 0px;
                color: $white;
                text-transform: uppercase;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .value {
                input {
                    outline: none;
                    width: 100%;
                    height: 100%;
                    border: none;
                    background-color: $grey8;
                    color: $white;
                    font: normal normal normal 20px/20px Source Sans Pro;
                    padding: 0 10px;
                }
            }
        }
    }
}
