.accordion {
    .MuiAccordion-root::before {
        background-color: transparent;
    }

    .MuiAccordionSummary-root {
        min-height: 64px;
        padding: 0;
    }

    .MuiAccordionSummary-root.Mui-focused {
        background-color: transparent;
    }

    .MuiAccordion-rounded:first-child {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
    }

    .MuiAccordionSummary-content {
        justify-content: center;
        text-align: center;
        font: normal normal bold 25px/32px Source Sans Pro;
        letter-spacing: 2.5px;
        color: $black;
        text-transform: uppercase;
        opacity: 1;
        border-bottom: 1px solid $grey2;
        padding-bottom: 15px;
        width: 100%;
        margin: 0;

        .expand-icon {
            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .MuiAccordion-rounded:last-child {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 148px;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
    }

    .MuiAccordion-root.Mui-expanded {
        margin: 0;
    }
 }
.read-message-accordion {

}
