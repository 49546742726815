#loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background: url('../../../../resources/assets/loader.gif') no-repeat center;
    background-size: 50px;
}
