.card-billing-info-container {
    background-color: $grey2;
    padding: 30px 29px 35px 29px;

    .card-billing-title {
        font: normal normal 600 16px/20px Source Sans Pro;
        letter-spacing: 0.8px;
    }

    //INPUT TYPE TEXT
    .custom-field {
        .card-bill {
            .MuiFormLabel-root {
                color: $grey1;
                font: normal normal normal 16px/20px Source Sans Pro;
            }
        }
    }

    //END INPUT TYPE TEXT

    // Select field for card and bill
    .custom-select-field-wrapper {
        &.card-bill {
            margin-top: 8px !important;
            //margin-bottom: 6px !important;
            .MuiFormLabel-root {
                font: normal normal normal 16px/20px Source Sans Pro;
            }

            .MuiInputBase-input {
                font: normal normal normal 16px/20px Source Sans Pro;
            }

            .MuiSvgIcon-root {
                margin-top: 7px;
            }
        }
    }

    .save-credit-card {
        margin-top: 10px;
        margin-bottom: 10px;

        .MuiFormControlLabel-root {
            align-items: center;
            margin-right: -1px;
        }

        .MuiFormControlLabel-label {
            font: normal normal normal 16px/18px Source Sans Pro;
            letter-spacing: -0.16px;
            margin-top: 0;
        }
    }
}
