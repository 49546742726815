.payment-details-container {
    max-width: 905px;
    width: auto;
    margin: 0 auto;

    .title {
        text-align: center;
        font: normal normal bold 25px/32px Source Sans Pro;
        letter-spacing: 2.5px;
        color: #000000;
        text-transform: uppercase;
        opacity: 1;
        margin-top: 60px;
        margin-bottom: 40px;
    }

    .MuiGrid-item:nth-child(4n) {
        .saved-card-container {
            margin-right: 0;
        }
    }

    .saved-card-container {
        cursor: pointer;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 15px;
        height: 135px;
        width: 215px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid $grey2;
        position: relative;

        .saved-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 25px;
            margin-bottom: 15px;

            .expiration-date {
                text-align: right;
                font: normal normal normal 18px/36px Source Sans Pro;
                letter-spacing: 0px;
                color: $grey1;
                opacity: 1;
            }
            .img img{
                width: 40px;
                height: 27px;
            }
        }

        .number {
            height: 25px;
            margin-bottom: 5px;
            text-align: left;
            font: normal normal 600 20px/36px Source Sans Pro;
            letter-spacing: 0px;
            color: $black;
            opacity: 1;
        }

        .owner-name {
            text-align: left;
            font: normal normal normal 18px/36px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey;
            opacity: 1;
            height: 30px;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .add-new-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $grey1;
            font: normal normal normal 18px/36px Source Sans Pro;
            letter-spacing: 0px;
            margin-top: 15px;

            .add-circle {
                width: 24px;
                height: 24px;
            }
        }

        .border-arrow {
            position: absolute;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            width: 0;
            height: 0;
            border-bottom: 10px solid $grey2;
            bottom: -31px;
            left: calc(50% - 5px);
        }
    }

    .saved-card-container.selected {
        border: 1px solid $blue;
        border-radius: 2px;
        background-color: $grey2;

        .header {
            .expiration-date {
                color: $blue;
            }
        }

        .number {
            color: $blue;
        }

        .owner-name {
            color: $blue;
        }

        .add-new-card {
            color: $blue;
        }
    }

    .replace-card {
        margin: 60px 20%;
        text-align: center;
        letter-spacing: 0px;
        color: $grey;
        opacity: 1;
        font: normal normal normal 18px/24px Source Sans Pro;

        .phone {
            font-weight: bold;
        }
    }

    button[type=submit] {
        font: normal normal 600 20px/36px Source Sans Pro;
        height: 56px;
        width: 291px;
        text-transform: none;
        margin-bottom: 30px;
        letter-spacing: 2px;
    }
}
