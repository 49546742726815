.beta-container {
    &.show {
        width: 300px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}

.beta-container {

    &.hidden {
        display: none;

    }

}