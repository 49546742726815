.header-image {
    height: 300px;
    background-image: url('/img/mobile/generalslider.jpg');

    .payments-header-container {

        .logo-and-title-container {
            max-width: 515px;
            width: 100%;

            .logo-container {

                @media screen and (min-width: 500px) and (max-width: 526px) {
                    margin-right: 0;
                }

                .vida-payments-logo {
                    width: 107px;
                }
            }

            .title-container {
                h1 {
                    font: normal normal bold 32px/36px Playfair Display;
                }

                hr.border {
                    margin-top: 20px;
                    width: 89px;
                }
            }
        }

        .center-text {
            padding-top: 10px;
            font: normal normal 600 18px/23px Source Sans Pro;

            p.welcome-text{
                margin-bottom: 0.5rem;
            }
        }
    }

    .select-contract {
        padding-top: 0px;
        font: normal normal 600 18px/23px Source Sans Pro;
    }
}
