.contract-modal {
    z-index: 999999 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .contract-modal-container{
        background-color: $white;
        width: 545px;
        padding: 16px 20px;
        outline: none;
        cursor: pointer;

        .modal-title {
            h1 {
                text-align: left;
                font: normal normal bold 18px/25px Source Sans Pro;
                letter-spacing: 0.9px;
                color: $black;
                //text-transform: uppercase;
                opacity: 1;
                margin: 0;
            }

            svg {
                cursor: pointer;
                margin-bottom: 3px;
            }
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 47px;

            label  {
                text-align: center;
                font: normal normal normal 18px Source Sans Pro;
                letter-spacing: 0;
                color: #444444;
                opacity: 1;
                margin: 0;
            }

            .box {
                padding-bottom: 30px;
                line-height: 0.5;
            }

            p {
                text-align: center;
                font: normal normal bold 20px Source Sans Pro;
                letter-spacing: 0;
                color: $black;
                opacity: 1;
                margin: 0;
            }

            a.link-button {
                background-color: $blue;
                border: none;
                margin: 15px auto 15px auto;
                border-radius: 2px;
                width: 331px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                text-align: center;
                font: normal normal 600 20px/36px Source Sans Pro;
                letter-spacing: 2px;
                //text-transform: uppercase;
                opacity: 1;
                outline: none;
                text-decoration: none;

                .link-to {
                    color: $white;
                }

                svg {
                    margin-bottom: 3px;
                }
            }
        }
    }
}
