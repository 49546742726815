.request-reservation-form {
    width: 100%;
    padding: 40px 0px 58px 0px;

    .title {
        font: normal normal bold 22px/28px Playfair Display;
        margin-bottom: 30px;
    }

    .toggle-container {
        margin: 10px 20px 0px 20px;
    }

    button[type=submit] {
        letter-spacing: 1px;
        height: 56px;
        width: 100%;
        margin-top: 30px;
        text-transform: none;
    }
}
