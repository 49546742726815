.reservation-found-info-container {
    .image-and-info-container {
        min-height: 160px;

        .info-container {
            .reservation-status-container {
                height: 30px;
            }

            .reservation-details-container {
                min-height: 130px;

                h3.destination {
                    margin-top: 8px;
                    font: normal normal bold 22px/28px Playfair Display;
                }

                h4.confirmation-label {
                    margin-top: 10px;
                    font: normal normal normal 13px/36px Source Sans Pro;
                    letter-spacing: 0.65px;
                    margin-bottom: 0;
                }

                p.confirmation-number {
                    font: normal normal normal 18px/31px Source Sans Pro;
                    margin-bottom: 0;
                }
            }
        }
    }

    .travelers-and-unit-container {
        padding: 10px 0;

        .travelers-container {
            padding-left: 16px;
        }

        .unit-type-container {
            padding-left: 16px;
        }
    }
}


.verify-button-container {
    margin-top: 20px;
    margin-bottom: 68px;

    .verify-button {
        width: 345px;
        letter-spacing: 1px;
    }
}
