.radio-btn {
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon {
        width: 20px;
        height: 20px;
        border: 1px solid $gray-600;
        border-radius: 20px;
        margin-right: 16px;
    }

    &.on {
        .icon {
            border: 5px solid $pink;
        }
    }
}
