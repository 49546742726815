
.simple-alert-container {
    font-family: Source Sans Pro !important;
    display: flex;
    text-align: center;
    font-size: 16px;
    justify-content: center;
    margin: 0 auto;
     .error {
        color: $error !important;
     }

    .success {
        background-color: $notification-success-background;
        color: $white;
        padding: 10px;
    }
}
