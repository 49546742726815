.monthly-payment-header {
    .monthly-payment-header-item {
        width: 148px;
        height: 75px;
        padding: 0 10px;

        .label {
            font: normal normal normal 16px/15px Source Sans Pro;
            letter-spacing: -0.32px;
        }

        .value {
            font: normal normal 600 20px/20px Source Sans Pro;
        }
    }
}
