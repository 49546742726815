.destination-highlights-section {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    .destination-logo {
        width: 247px;
        height: auto;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .margin-top-no-logo {
        margin-top: 82px;
    }

    .destination-location {
        margin: 0 auto;
        text-align: center;
        font: normal normal normal 16px Source Sans Pro;
        letter-spacing: 0px;
        color: $grey3;
        opacity: 1;
        margin-top: 33px;

        span {
            margin-right: 5px;
        }
    }

    .destination-description {
        text-align: center;
        font: normal normal normal 18px Source Sans Pro;
        letter-spacing: 0px;
        color: #242424;
        opacity: 1;
        padding-top: 30px;
        padding-bottom: 20px;
        max-width: 674px;
        margin: 0 auto;

        .read-more-container {
            display: flex;
            justify-content: center;
        }

        .read-more, svg {
            margin-top: 30px;
            cursor: pointer;
            text-align: center;
            font: normal normal bold 18px/16px Source Sans Pro;
            letter-spacing: 0px;
            color: $blue;
            opacity: 1;
        }
    }

    .buy-tickets {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        margin: 0 auto 40px auto;
        width: 245px;
        min-height: 56px;
        height: auto;
        border: none;
        background: $blue;
        color: $white;
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 2px;
        //text-transform: uppercase;
        text-align: center;
    }
}
