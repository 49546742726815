.destination-highlights {
    height: auto;
    padding: 20px 0;

    h2 {
        text-transform: uppercase;
        margin: 0 auto 40px;
        text-align: center;
        font: normal normal bold 25px/32px Playfair Display;
        color: $black;
        letter-spacing: 0px;
    }

    .slick-arrow {
        width: 32px;
        height: 32px;
        background-color: $blue;
    }

    .slick-arrow::before {
        border: solid $white;
        border-width: 0 4px 4px 0;
        padding: 1px;
    }

    .slick-track {
        display: flex;
    }

    .center-carousel {
        justify-content: center;
    }

    .slick-slide {
        padding: 0 5px;
        max-width: 350px;

        .card {
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 100%;
            display: block;

            .card-container {
                display: block;
                position: relative;
                height: 386px;

                .card-img-container {
                    width: 340px;
                    height: 200px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .caption {
                    .subtitle {
                        color: $grey3;
                        margin: 15px 0 10px;
                        padding: 0 10%;
                        font: normal normal normal 16px/13px Source Sans Pro;
                        text-align: center;
                    }

                    .title {
                        //text-transform: capitalize;
                        color: $black;
                        font: normal normal bold 25px/32px Playfair Display;
                        padding: 0 10%;
                        text-align: center;
                    }

                    .learn-more {
                        border: none;
                        outline: none;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        font: normal normal bold 14px Source Sans Pro;
                        background-color: $blue;
                        color: white;
                        height: 56px;
                        display: flex;
                        text-decoration: none;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        bottom: 0;
                        position: absolute;
                    }
                }
            }

            @media screen and (min-width: 1554px) {
                .card-container {
                    height: 350px;
                }
            }
        }
    }
}
