@media screen and (max-width: $mobile-width) {
    .auth {
        @import "header";
        @import "nav-tabs";
        @import "form";
        @import "beta";
        
        position: relative;
        overflow: hidden;

        .content {
            width: 282px;
        }

        .auth-title {
            font-size: 22px;
            padding-bottom: 15px;
        }

        .auth-subtitle {
            font-size: 16px;
        }

        &.register-container {
            min-height: 1120px;
        }

        &.login-container {
            min-height: 667px;
        }

        .login-container {
            padding: 0px;
        }

        &.register-success-container {
            min-height: 667px;
        }

        &.verify-email-success-container {
            min-height: 667px;
        }

        &.register-resend-email-container {
            min-height: 667px;
        }

        &.forgot-password-container {
            min-height: 667px;
        }

        &.not-found {
            min-height: 667px;
        }

        .auth-smaller-container {
            width: 282px;
            padding: 20px 0;

            .two-buttons {
                input[type="submit"], button[type="submit"], button.icon-left {
                    width: 137px;
                }
            }

            .auth-title {
                font-size: 22px;
                font-family: PlayFair Display, serif;
                font-weight: bold;
                padding-bottom: 20px;
            }

            .auth-subtitle {
                font-size: 16px;
                font-family: Source Sans Pro, sans-serif;
            }
        }


        &.reset-password-wrapper {
            min-height: 780px;
        }

        .reset-password-container {
            padding-top: 20px;
            width: 282px;

            .title {
                font-size: 22px;
            }

            .subtitle {
                font-size: 16px;
            }
        }

        &.reset-password-success-wrapper {
            min-height: 667px;
        }
    }
}
