.stay-with-us {
    padding: 80px 0px 94px 0;

    .stay-with-us-title {
        font: normal normal bold 25px/30px Playfair Display;
        margin: 0 auto 30px;
    }

    .img-container {
        min-width: 229px;
        //height: 168px;
    }

    .stay-with-us-subtitle {
        font: normal normal 600 18px/24px Source Sans Pro;
        margin: 40px auto 40px auto;
        width: 70%;
    }

    .stay-with-us-btn {
        width: 351px;
        height: 56px;
        font-size: 20px;
        letter-spacing: 1px;
    }
}
