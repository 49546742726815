.card-video-container {
    .card-with-video-details-container {
        padding: 10px;
        min-height: 150px;

        .card-with-video-title {
            font: normal normal bold 14px/20px Playfair Display;
            height: auto;
            margin-bottom: 5px;
        }

        .card-with-video-subtitle {
            font: normal normal normal 14px/17px Source Sans Pro;
            max-width: 119px;
            margin-bottom: 10px;
        }

        .video-link {
            .play-video-btn {
                font: normal normal 600 14px/16px Source Sans Pro;
            }

            .card-video-icon {
                margin-bottom: 0px;
            }
        }

        a {
            font: normal normal 600 14px/16px Source Sans Pro;
        }
    }
}
