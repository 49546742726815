.more-about {
    height: auto;
    margin: 57px 0 50px 0;

    .title {
        text-align: center;
        font: normal normal bold 25px Playfair Display;
        letter-spacing: 0px;
        color: $black;
        opacity: 1;
        margin: 0 auto;
        width: 100%;
    }

    .more-about-title-line {
        background-color: $blue;
        margin-bottom: 18px;
        height: 2px;
        width: 69px;
    }

    .sub-title {
        text-align: center;
        font: normal normal normal 18px Source Sans Pro;
        letter-spacing: 0px;
        color: $black;
        opacity: 1;
        margin: 0 auto 56px auto;
        width: 60%;
    }
}
