.edit-profile-form {
    padding: 60px 0 66px 0;
    margin: 0 auto;

    .member-no-edit {
        margin-top: -195px;
    }

    .title {
        text-align: center;
        font: normal normal bold 22px/25px Source Sans Pro;
        letter-spacing: 1.1px;
    }

    .sub-title {
        margin-bottom: 50px;
    }

    .change-field-btn-container {
        .field-btn {
            margin-top: -14px;
        }
    }

    .submit-btn {
        margin: 70px auto 0 auto;
    }
}
