.switch-container {
    margin-bottom: 20px;
    width: 100%;
    max-width: 905px;
    padding-right: 10px;

    .message-area-reply-notification {
        background-color: $blue;
        height: 56px;
        display: flex;
        justify-content: center;

        > button {
            color: $white;
            border: 0;
            background-color: $blue;
            font: normal normal 600 20px/36px Source Sans Pro;
            letter-spacing: 1px;
            text-transform: uppercase;
            opacity: 1;
            outline: none;
        }
    }
}
