.vida-member-id-container {
    position: absolute;
    z-index: 2;
    width: 146px;
    height: 59px;
    padding: 8px 10px;

    &.my-account {
        width: 146px;
        height: 59px;
    }

    &.vida-dashboard {
        top: -50px;
        right: 0;
    }
}
