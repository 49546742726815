.section {
    &.bg-blue {
        &.sticky {
            top: 60px;

            &.edit-profile {
                top: 150px;
            }
        }

        &.sticky-add-reservation {
            top: 154px;
        }
    }

    &.bg-filters {
        top: 60px;

        &.margin-top-100 {
            margin-top: -90px;
        }
    }

    &.bg-transparent {
        margin-top: 90px;
    }

    &.bg-transparent-filters {
        margin-top: 15px;
    }

    &.bg-dashboard-image {
        margin-bottom: -107px;
    }

    &.bg-dashboard-image:before {
        content: ' ';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        z-index: 4;
        opacity: var(--opacity);
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('/img/home/tablet/homepage.jpg');
    }
}
