.thank-you-modal {
    .thank-you-modal-container {
        width: 473px;
        height: 315px;

        .thank-you-modal-title {
            margin-bottom: 20px;
        }

        .thank-you-modal-text {
            max-width: 350px;
            text-align: center;
            font: normal normal normal 18px/24px Source Sans Pro;
            letter-spacing: 0px;
            margin: 0 auto;
            padding-bottom: 30px;
        }

        .thank-you-modal-button {
            width: 351px;
            letter-spacing: 1px;
        }
    }
}
