.reservation-page {
    padding-top: 30px;

    .reservation-page-text {

        h2 {
            font: normal normal bold 22px/28px Playfair Display;
        }

        p {
            padding-top: 20px;
            font: normal normal normal 16px/22px Source Sans Pro;

            > span {
                font: normal normal 600 16px/22px Source Sans Pro;
            }
        }
    }

    .reservation-form {
        padding: 0 25px;

        .custom-field {
            .MuiFormControl-marginNormal {
                margin: 8px 0;
            }
        }

        .submit-btn {
            margin: 40px auto 67px auto;
            max-width: 282px;
        }
    }
}
