.header-menu {
    height: 60px;
    width: 132px !important;

    .close-menu-button {
        padding: 10px 0;
    }
}

#header-menu {
    .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
        top: 60px !important;
        bottom: auto;
        left: 0 !important;
    }
}
