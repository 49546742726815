.contract-button {
    min-height: 68px;

    p.contract-text {
        font: normal normal normal 18px/22px Source Sans Pro;

        &.contract-number {
            font: normal normal bold 18px/22px Source Sans Pro;
            letter-spacing: 0.9px;
        }
    }
}

.contract-button-active {
    min-height: 68px;

    p.contract-text {
        font: normal normal normal 18px/22px Source Sans Pro;

        &.contract-number {
            font: normal normal bold 18px/22px Source Sans Pro;
            letter-spacing: 0.9px;
        }
    }
}
