.profile-notifications {
    background: $grey;
    padding-bottom: 15px;

    .container {
        padding-top: 0px;
        padding-bottom: 20px;
        max-width: 700px;

    }

    .profile-notifier {
        border: 1px solid rgba(68, 68, 68, .5);
        border-bottom: none;
        padding-top: 35px;
        padding-bottom: 36px;
        background: $grey;
        // max-width: calc(50% - 15px);
        // max-height: 160px;

        .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle {
            background-color: $red;
        }
    }

    h1.profile-notifications-badge {
        text-align: center;
    }

    h1.profile-notifications-label {
        color: $sorrellbrown;
        text-align: center;
        text-transform: uppercase;
        margin: auto;
        font: normal normal normal 20px/31px Source Sans Pro;
    }
}
