.card-billing-info-container {
    background-color: $grey2;
    padding: 89px 72px 76px 81px;
    margin-top: 15px;

    .card-details-container {
        margin-right: 40px;
    }

    .bill-details-container {
        margin-left: 40px;
    }

    .card-billing-title {
        text-align: center;
        font: normal normal 600 18px/25px Source Sans Pro;
        letter-spacing: 0.9px;
        color: $black;
        text-transform: uppercase;
        opacity: 1;
        margin-bottom: 30px;
    }

    .card-logo-img {
        width: 35px;
        height: 23px;
    }

    //INPUT TYPE TEXT
    .custom-field {
        .card-bill {
            width: 100%;

            margin-top: 6px !important;
            margin-bottom: 6px !important;

            &.MuiFormControl-marginNormal {
                margin-top: 6px;
                margin-bottom: 6px;
            }

            .MuiFormLabel-root {
                color: $grey1;
                font: normal normal normal 18px/20px Source Sans Pro;
            }

            .MuiFormLabel-root.Mui-error {
                color: $error !important;
            }
        }
    }

    //END INPUT TYPE TEXT

    // Select field for card and bill
    .custom-select-field-wrapper {
        &.card-bill {
            margin-top: 6px !important;
            margin-bottom: 6px !important;

            .MuiFormLabel-root {
                font: normal normal normal 18px/20px Source Sans Pro;
            }

            .MuiInputBase-input {
                font: normal normal normal 18px/20px Source Sans Pro;
            }

            .MuiSelect-selectMenu {
                height: 0;
            }

            .MuiSvgIcon-root {
                color: $grey1;
            }

            .MuiSelect-select.MuiSelect-select {
                padding-right: 0;
            }
        }
    }

    .save-credit-card {
        margin-top: 22px;
    }
}
