.filters-container {
    color: $black;
    height: 100%;
    padding-bottom: 10px;

    .start-vacation-text {
        text-align: center;
        font: normal normal 600 15px/19px Source Sans Pro;
        letter-spacing: 0px;
        color: $white;
        text-shadow: 1px 1px 1px #00000029;
    }

    .custom-select-field-wrapper {
        &.header-filter {
            background-color: $white;
            border-radius: 2px;
            padding-left: 12px;
            margin: 0 !important;

            &.error-border {
                border: 1px solid $error;
            }

            .MuiFormLabel-root {
                padding-left: 12px;
            }

            .MuiInputBase-input {
                text-align: left;
                font: normal normal normal 20px/16px Source Sans Pro;
                letter-spacing: 0px;
                opacity: 1;
                color: $black;
                border-radius: 2px;
            }

            .MuiInput-underline::before, .MuiInput-underline::after {
                border: none;
            }

            label + .MuiInput-formControl {
                margin-top: 24px;
            }

            .MuiInputLabel-shrink {
                transform: translate(2px, 10px) scale(0.75);
                transform-origin: top left;
            }

            .MuiSvgIcon-root {
                margin-bottom: 10px;
                margin-right: 12px;
            }
        }
    }

    // Date picker
    .date-picker-container {
        &.header-filter {
            width: 100%;

            .MuiFilledInput-root {
                cursor: pointer;
                background-color: $white;
                border-radius: 2px;
            }

            .MuiFilledInput-underline::before, .MuiFilledInput-underline::after {
                border: none;
            }

            &.MuiFormControl-marginNormal {
                margin: 8px 0 !important;
            }

            .MuiInputBase-input {
                text-align: left;
                font: normal normal normal 20px/16px Source Sans Pro;
                letter-spacing: 0px;
                color: $black;
                opacity: 1;
            }

            .MuiIconButton-root {
                padding: 0;
                color: $grey1;
            }


            .MuiFormLabel-root {
                color: $grey1;
                font-family: Source Sans Pro;
                letter-spacing: 0px;
            }

            .MuiFilledInput-root.Mui-error {
                border: 1px solid $error;
            }

            .MuiFormLabel-root.Mui-focused {
                color: $grey1;
            }

            .MuiInputLabel-filled {
                transform: translate(12px, 24px) scale(1);
            }

            .MuiInputLabel-filled.MuiInputLabel-shrink {
                transform: translate(12px, 10px) scale(0.75);
            }

            .MuiFormLabel-root.Mui-error {
                color: $error;
            }

            .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
                border: 1px solid $error;
            }

            .icon-error {
                color: $error;
            }
        }
    }

    // END Date picker

    .filter-item-container:not(:last-child) {
        padding-right: 15px;
    }

    // Date Picker
    .date-picker-container {
        .MuiSvgIcon-root {
            color: $blue;
        }
    }

    .reserve-btn {
        background-color: $blue;
        color: $white;
        font: normal normal bold 16px Source Sans Pro;
        height: 60.75px;
        width: 100%;
        outline: none;
        border: none;
        border-radius: 2px;
        text-transform: uppercase;
    }
}

// This class is used for Filters on home page, image section, and remove container padding
.remove-padding-for-transparent-filters {
    margin: 0 -15px;
}
