form {
    input[type="submit"], button[type="submit"] {
        width: 282px;
    }

    .login-link {
        margin-top: 10px;
    }

    .field-error {
        &.center {
            text-align: left;
        }
    }
}
