.destination-highlights  {
    height: auto;
    padding: 30px 0px;

    h2 {
        font: normal normal bold 22px/28px Playfair Display;
        margin-bottom: 20px;
    }

    .slick-slide {
        padding: 0 5px 0 0;
        max-width: 150px;
        width: 150px;
        min-width: 150px;

        .card {
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 100%;

            .card-container {
                display: block;
                position: relative;
                height: 244px;

                .card-img-container {
                    width: 150px;
                    height: 116px;
                }

                .caption {
                    .subtitle {
                        font: normal normal normal 11px/13px Source Sans Pro;
                        margin: 10px 0 5px;
                        padding: 0;
                    }

                    .title {
                        font: normal normal bold 16px/18px Playfair Display;
                        padding: 0;
                        max-width: 127px;
                        margin: 0 auto 10px;
                    }

                    .learn-more {
                        //font: normal normal bold 13px/36px Source Sans Pro;
                        letter-spacing: 0.65px;
                        height: 39px;
                    }
                }
            }
        }

    }
}
