.update-user-modal {

    .update-user-modal-container {
        width: 350px;

        .modal-title {
            padding: 16px 20px 0px 20px;
        }

        .modal-body {
            padding: 47px 61px 0px 61px;
        }

        .submit-save-btn {
            margin: 45px auto 44px auto;
            width: 229px;
        }
    }
}
