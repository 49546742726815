.header-exchanges {
    h1 {
        font: normal normal bold 22px/28px Playfair Display;
        padding-top: 40px;
    }

    p {
        font: normal normal normal 16px/22px Source Sans Pro;
        padding-top: 20px;
        max-width: 296px;
    }
}
