.contract-carousel {
    min-height: 85px;
    padding: 5px 0;

    .contracts-slider {
        min-height: 65px;
        margin: 5px auto;

        p.contract-carousel-text {
            text-align: center;
            font: normal normal normal 18px/20px Source Sans Pro;
            letter-spacing: 0.9px;
            color: $black;
            text-transform: uppercase;
            opacity: 1;

            &.contract-number {
                font: normal normal bold 18px/22px Source Sans Pro;
                letter-spacing: 0.9px;
            }
        }
    }
}
