.payment-type {
    .add-new-container {
        button {
            outline: none;
            background-color: transparent;
            display: flex;
            align-items: center;
            border: none;
            padding: 0;

            .add-new-img {
                margin-right: 10px;
                cursor: pointer;
            }

            .add-new-text {
                text-align: left;
                font: normal normal bold 18px/16px Source Sans Pro;
                letter-spacing: 0px;
                color: $blue;
                opacity: 1;
                cursor: pointer;
            }
        }
    }

    .card-container {

        .card-content {
            display: flex;
            align-items: center;
            font: normal normal 600 13px/36px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey1;
            opacity: 1;

            .card-index-text {
                text-align: left;
                flex: 1;
            }

            .card-text {
                text-align: right;              
            }
        }

        .card-index {
            text-align: left;
            font: normal normal 600 13px/36px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey1;
            opacity: 1;
        }

        .card-details {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $grey1;
            margin-bottom: 30px;

            .card-img {
                width: 34.5px;
                height: 23px;
                margin-right: 10px;
            }

            .card-number-hidden {
                text-align: left;
                font: normal normal normal 18px/36px Source Sans Pro;
                letter-spacing: 0px;
                color: $black;
                opacity: 1;
                flex: 1;
                cursor: pointer;
            }

            .default-text {
                text-align: left;
                font: normal normal 600 13px/13px Source Sans Pro;
                letter-spacing: 0px;
                color: $blue;
                text-transform: uppercase;
                opacity: 1;
                cursor: pointer;
                max-width: 49px;

                &.is-default {
                    text-align: left;
                    font: normal normal 600 13px/36px Source Sans Pro;
                    letter-spacing: 0px;
                    color: $grey6;
                    text-transform: uppercase;
                    opacity: 1;
                    cursor: auto;
                }
            }

            button{
                outline: none;
                background-color: transparent;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                border: none;
                padding: 15px;
                color: #446DB4;
            }
        }
    }
}
