.section {
    color: $white;
    //BACKGROUND COLORS FOR SECTIONS

    &.bg-black {
        background-color: $black;

        &.sticky {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1301;
        }
    }

    &.bg-white {
        background-color: $white;
    }

    &.bg-dashboard-image {
        position: relative;
        overflow: hidden;
        background-color: $black;
        --opacity: 0.9;
        z-index: 4;
        margin-bottom: -188px;
        z-index: 999;
    }

    &.bg-dashboard-image:before {
        content: ' ';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        z-index: 4;
        opacity: var(--opacity);
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('/img/home/desktop/homepage.jpg');
    }

    &.bg-blue {
        background-color: $blue;

        &.sticky {
            position: -webkit-sticky;
            position: sticky;
            top: 70px;
            z-index: 999;

            &.edit-profile {
                top: 160px;
            }
            &.about {
                top: 164px;
            }
        }

        &.sticky-add-reservation {
            position: -webkit-sticky;
            position: sticky;
            top: 164px;
            z-index: 3;
        }
    }

    &.bg-grey {
        background-color: $grey2;
    }

    &.bg-grey12 {
        background-color: $grey12;
    }

    &.bg-greyWhite {
        background-color: $greyWhite;
    }

    &.bg-dark-blue {
        background-color: $blue2;
    }

    &.addColor {
        &.bg-filters{
            background-color: $dark-blue;
            border-top: 2px solid $blue;

            .start-vacation-text{
                opacity: 1;
            }
        }
    }

    &.removeColor {
        &.bg-filters{
            background-color:transparent;
            border-top: none;
            .start-vacation-text{
                opacity: 0;
            }
        }
    }

    &.bg-filters {
        background-color: $dark-blue;
        border-top: 2px solid $blue;
        position: sticky;
        top: 70px;
        z-index: 3;
        min-height: 86px;
        display: flex;
        align-items: center;
        padding-top: 5px;
        z-index: 1300;

        &.margin-top-100 {
            margin-top: 15px;
        }
    }

    &.bg-transparent-filters {
        background-color: transparent;
        margin-top: 20px;
    }

    &.bg-transparent {
        background-color: transparent;
        margin-top: 30px;

        &.profile-details-z-index {
            z-index: 1200;
        }
    }

    &.m-filters {
        border-bottom: 1px solid $grey4;
        margin-bottom: 0;
    }

    &.b-bottom-pre-registration {
        border-bottom: 1px solid $grey4;
    }

    &.bg-dark-grey {
        background-color: $grey;
    }

    .container-no-padding {
        padding: 0;
    }

    //END BACKGROUND COLOR FOR SECTIONS

    // .container {
    //     max-width: 1365px;
    // }

    &.border {
        border-bottom: 1px solid gray;
    }

    p {
        text-align: left;
    }
}
