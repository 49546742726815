.upcoming-reservations {
    margin-top: 0px;

    .guests-unit-container {
        background-color: $greyWhite;
    }
    
        .upcoming-reservations-title {
            text-align: center;
            letter-spacing: 2px;
            color: #000000;
            text-transform: uppercase;
            opacity: 1;
            padding-top: 40px;
            padding-bottom: 40px;
            font: normal normal bold 18px Source Sans Pro;
            margin-bottom: 0px;
        }
}
