.reservation-found-header-container {
    h1 {
        padding-top: 40px;
        padding-bottom: 30px;
        text-align: center;
        font: normal normal bold 22px/25px Source Sans Pro;
        letter-spacing: 1.1px;
        color: #000000;
        text-transform: uppercase;
        opacity: 1;
    }

    p {
        margin: 0 auto;
        padding-bottom: 60px;
        width: 350px;
        text-align: center;
        font: normal normal normal 18px/24px Source Sans Pro;
        letter-spacing: 0px;
        color: $grey;
        opacity: 1;
    }
}
