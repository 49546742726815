.filters-container {
    padding-bottom: 10px;

    .start-vacation-text {
        display: none;
    }

    .filter-item-container:not(:last-child) {
        padding-right: 10px;
    }

    .reserve-btn {
        font-size: 16px;
        height: 60.75px;
        width: 100%;
    }
}
