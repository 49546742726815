.reservation-item {
    width: 445px;
    max-height: 484px;

    .emty-reservation-image-container {
        background-color: #eee;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;

        .empty-reservation-image {
            width: 70px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .emty-reservation-image-container-past {
        background-color: #eee;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;

        .empty-reservation-image {
            width: 70px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .image-and-name-container {
        width: 100%;
        max-height: 180px;

        .reservation-image-container {
            width: 140px;
            height: 180px;

            .reservation-image {
                max-height: 180px;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .details-container {
            max-height: 180px;
            width: 100%;
            height: 100%;
            background-color: white;

            .reservation-status {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;

                &.status-background1 {
                    background-color: $orange;
                }

                &.status-background2 {
                    background-color: $confirmed;
                }

                &.status-background3 {
                    background-color: $blue;
                }

                &.status-background4 {
                    background-color: $orange1;
                }

                &.status-background5 {
                    background-color: $red;
                }

                &.status-background6 {
                    background-color: $green;
                }

                &.status-background7 {
                    background-color: $green;
                }

                &.status-background8 {
                    background-color: $green;
                }
            }

            .status-text {
                text-align: center;
                font: normal normal 600 16px/20px Source Sans Pro;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                opacity: 1;
            }

            .name-and-number {
                margin: 0 auto;
                height: 150px;
                padding: 10px;

                .title {
                    text-align: center;
                    font: normal normal bold 25px Playfair Display;
                    letter-spacing: 0;
                    color: #1e1e1e;
                    text-transform: capitalize;
                    opacity: 1;
                    margin: 0;
                }

                .confirmation-nr-title {
                    text-align: center;
                    font: normal normal normal 13px/36px Source Sans Pro;
                    letter-spacing: 0.65px;
                    color: $grey;
                    text-transform: uppercase;
                    opacity: 1;
                    margin: 0;
                    padding-top: 0px;
                }

                .confirmation-nr {
                    text-align: center;
                    font: normal normal normal 22px Source Sans Pro;
                    letter-spacing: 0;
                    color: $grey;
                    text-transform: uppercase;
                    opacity: 1;
                    margin: 0;
                    line-height: 0.3px;
                }
            }
        }
    }

    .guests-unit-container {
        background: #eee;

        .guests {
            max-height: 65px;
            padding-left: 20px;
            padding-top: 15px;

            .label-text {
                text-align: left;
                font: normal normal 600 13px/36px Source Sans Pro;
                letter-spacing: 0.65px;
                color: #7F7D7D;
                text-transform: uppercase;
                opacity: 1;
                line-height: 1;
                padding-top: 10px;
            }

            .label-desc {
                text-align: left;
                font: normal normal normal 18px/20px Source Sans Pro;
                letter-spacing: 0;
                color: $grey;
                opacity: 1;
            }
        }

        .unit-type {
            max-height: 65px;
            padding-left: 20px;

            .label-text {
                text-align: left;
                font: normal normal 600 13px/36px Source Sans Pro;
                letter-spacing: 0.65px;
                color: #7F7D7D;
                text-transform: uppercase;
                opacity: 1;
                line-height: 10px;
                padding-top: 10px;
            }

            .label-desc {
                text-align: left;
                font: normal normal normal 18px/22px Source Sans Pro;
                letter-spacing: 0;
                color: $grey;
                opacity: 1;
            }
        }

        .date {
            max-height: 65px;
            padding-left: 20px;
            padding-bottom: 15px;

            .label-text {
                text-align: left;
                font: normal normal 600 13px/36px Source Sans Pro;
                letter-spacing: 0.65px;
                color: #7F7D7D;
                text-transform: uppercase;
                opacity: 1;
                line-height: 1;
                padding-top: 10px;
            }

            .label-desc {
                text-align: left;
                font: normal normal normal 18px/20px Source Sans Pro;
                letter-spacing: 0;
                color: $grey;
                opacity: 1;
            }
        }
    }

    .reservation-action-container {
        max-height: 114px;
        //max-width: 445px;

        .new-reservation-action-item {
            background-color: $black;
            cursor: pointer;
            height: 56px;
            align-items: center;
            justify-content: center;
            text-align: center;

            &:first-child {
                margin-bottom: 2px;
            }

            .action-item-container {
                display: flex;
                height: 56px;
                justify-content: center;

                .action-icon {
                    width: 18px;
                    margin-right: 10px;
                }

                .action-text {
                    display: flex;
                    align-items: center;
                    text-align: left;
                    font: normal normal 600 16px/31px Source Sans Pro;
                    letter-spacing: 0;
                    color: #F7F7F7;
                    opacity: 1;
                }
            }
        }
    }
}
