.payment-method-header {
    width: 341px;

    .title {
        font: normal normal bold 18px/22px Source Sans Pro;
        letter-spacing: 0.9px;
        margin: 30px 0 25px 0;
    }

    .payment-method-header-item {
        padding: 20px 0;
        .label {
            font: normal normal normal 16px/20px Source Sans Pro;
        }

        .values-container {
            .value {
                font: normal normal normal 18px/20px Source Sans Pro;

                &.total {
                    font: normal normal normal 28px/20px Source Sans Pro;
                }
            }

        }

        .payment-amount {
            width: 100%;
            height: 40px;

            .placeholder {
                font: normal normal normal 13px/20px Source Sans Pro;
            }

            .value {
                input {
                    font: normal normal normal 18px/20px Source Sans Pro;
                }
            }
        }
    }
}
