.header {
    align-items: center;

    img.logo {
        margin-top: 100px;
        width: 214px;
        margin-bottom: 20px;
        cursor: pointer;
    }


    .title {
        justify-content: center;
        color: $white;
        margin-bottom: 45px;
        font-size: 32px;
        font-style: italic;
        font-family: PlayFair Display, serif;
    }
}

