.success-payment-content {
    padding-top: 100px;

    .title-right {
        margin-left: 20px;
        border-left: 1px solid $grey3;
        padding-left: 20px;

        .my-vida-vacations-payments-title {
            text-align: left;
            font: normal normal bold 25px/32px Source Sans Pro;
            letter-spacing: 2.5px;
            color: $black;
            text-transform: uppercase;
            opacity: 1;
        }
    }

    .payment-received {
        padding-top: 120px;

        .payment-received-title {
            text-align: center;
            font: normal normal bold 25px/36px Playfair Display;
            letter-spacing: 0px;
            color: $black;
            opacity: 1;
        }
    }

    .thank-container {
        padding-top: 20px;
        width: 350px;
        text-align: center;

        .thank-text {
            text-align: center;
            font: normal normal normal 18px/24px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey;
            opacity: 1;
        }
    }

    .download-icon {
        margin-right: 10px;
    }

    .download-invoice {
        margin-top: 40px;
        padding: 0 50px;
        height: 56px;
        text-align: center;
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 2px;
        color: $white;
        text-transform: uppercase;
        opacity: 1;
        margin-bottom: 315px;
    }
}
