@media screen and (max-width: $tablet-width) {
    .auth {
        @import "form";
        @import "header";
        @import "beta";
        
        .content {
            width: 480px;
        }

        &.register-container {
            min-height: 1090px;
        }

        &.login-container {
            min-height: 1024px;
        }

        .login-container {
            padding: 0px;
        }

        &.register-success-container {
            min-height: 1024px;
        }

        &.verify-email-success-container {
            min-height: 1024px;
        }

        &.register-resend-email-container {
            min-height: 1024px;
        }

        &.forgot-password-container {
            min-height: 1024px;
        }

        &.reset-password-wrapper {
            min-height: 1024px;
        }

        &.reset-password-success-wrapper {
            min-height: 1024px;
        }
    }
}
