.contracts-list {
    padding-top: 30px;
    padding-bottom: 160px;

    .list-contract {
        .list-my-contracts {
            p.resort-name {
                font: normal normal 600 18px/22px Source Sans Pro;
            }

            p.contract-number {
                font: normal normal bold 18px/22px Source Sans Pro;
            }

        }

        .details-container-header{
            display: none;
        }

        .details-container-item{
            padding: 5px;

            .desktop{
                display: none;
            }

            .device{
                display: inline;
            }

            .label{
                text-align: left;
            }

            .label-value{
                text-align: left;
            }

            .download-icon {
                margin-left: 0;
                margin-right: 0;
                width: inherit;
            }
        }
    }

    .MuiAccordionDetails-root {
        padding: 0;
    }
}
