.contact-header {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    div {
        margin: 0;
    }

    h1 {
        margin: 0 auto;
        text-align: center;
        text-transform: capitalize;
        font: normal normal bold 25px/32px Playfair Display;
        letter-spacing: 0;
        color: $black;
        opacity: 1;
        padding-top: 40px;
        max-width: 202px;
    }

    p {
        text-align: center;
        font: normal normal normal 18px/24px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        padding-top: 30px;
        padding-bottom: 30px;
        max-width: 446px;
        margin: 0 auto;
    }
}
