.card-billing-info-container {
    padding: 48px 49px 59px 61px;

    .card-details-container {
        margin-right: 0px;
    }

    .bill-details-container {
        margin-left: 0px;
    }

    .card-billing-title {
        margin-bottom: 0px;
    }

    .save-credit-card {
        margin-top: 40px;
        margin-bottom: 25px;
    }
}
