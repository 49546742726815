.container-white {
    max-width: 905px;
    margin: auto;
    width: 100%;
}

.custom-field .MuiInput-underline:before {
    border-bottom: 0;
}

.accordion .MuiAccordionSummary-content {
    text-transform: none;
    padding-bottom: 0;
    height: 74px;
}
