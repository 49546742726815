.contact-form {
    padding-bottom: 60px;
    max-width: 351px;
    margin: 0 auto;

    .custom-field {
        .MuiInputBase-input {
            color: $grey;
            font: normal normal normal 18px/36px Source Sans Pro;
        }
    }

    .contact-form-select {
        width: 351px;
    }
    .contact-form-phone {
        width: 351px;
    }
    .contact-form-email {
        width: 351px;
    }
    .contact-form-message {
        width: 351px;
    }

    .contact-form-toggle-container {
        width: 351px;
        justify-content: left;
        color: #000000;
        font: normal normal normal 18px/36px Source Sans Pro;
        letter-spacing: 0px;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .contact-submit {
        width: 351px;
        height: 56px;
        background: #446DB4;
        color: white;
        text-transform: uppercase;
        font: normal normal 600 20px/36px Source Sans Pro;
        border: none;
        letter-spacing: 1px;
    }
}
