.message-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .message-modal-content {
        flex-direction: column;
        width: 848px;
        height: 250px;
        outline: none;
        background-color: rgba($color: $white, $alpha: 1.0);

        svg {
            margin-left: 96%;
            color: $black;
            margin-bottom: 10px;
            cursor: pointer;
            margin-top: 1%;
        }

        .message-modal-text {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            outline: none;
            margin-top: -3rem;
            padding: 0 10%;

            span .note {
                font-weight: bold;
            }
        }
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: $tablet-width) {
    .message-modal-container {
        .message-modal-content {
            width: 70%;
            height: 55%;
        }
    }
}

@media screen and (max-width: $mobile-width) {
    .message-modal-container {
        .message-modal-content {
            width: 90%;
            height: 50%;
        }
    }
}
