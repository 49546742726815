.one-more-step-form {
    width: 281px;

    .h1 {
        font: normal normal bold 22px/28px Playfair Display;
    }

    .travelers-container {
        .reservation-summary-form {
            .MuiFormLabel-root.Mui-disabled {
                margin-top: 5px;
            }
        }
    }
}

.submit-container {
    .submit {
        width: 283px;
        margin-top: 50px;
        margin-bottom: 55px;
    }
}
