.additional-traveler-item {
    background: $grey2;
    margin-top: 10px;
    width: 49%;
    height: 54px;

    p.additional-traveler-title {
        font: normal normal 600 11px/13px Source Sans Pro;
        letter-spacing: 0px;
        color: $grey1;
        padding-left: 10px;
        margin-bottom: 0;
        margin-top: 11px;
    }

    p.additional-traveler-name {
        color: $black;
        font: normal normal normal 16px/16px Source Sans Pro;
        padding-left: 10px;
        letter-spacing: 0px;
    }

    .action-buttons-container {
        margin-top: 7px;
        padding-right: 10px;

        .edit-traveler-button {
            width: 40px;
            height: 40px;
            border: none;
            background: none;
            font: normal normal 600 11px/13px Source Sans Pro;
            letter-spacing: 0px;
            color: $blue;
            text-transform: uppercase;
        }

        .delete-traveler-button {
            width: 40px;
            height: 40px;
            border: none;
            background: none;
            font: normal normal 600 11px/13px Source Sans Pro;
            letter-spacing: 0px;
            color: $blue;
            text-transform: uppercase;

            .delete-icon {
                margin-left: 5px;
            }
        }
    }
}
