.add-reservation {
    cursor: pointer;
    background-color: $blue;
    height: 64px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        margin-right: 10px;
    }

    .text {
        text-align: center;
        font: normal normal 600 20px/31px Source Sans Pro;
        letter-spacing: 1px;
        color: $white;
        text-transform: uppercase;
        opacity: 1;
        margin: 0;
    }
}
