.vida-member-id-container {
    top: -48px;

    &.my-account {
        width: 229px;
        height: 65px;
    }

    width: 142px;
    height: 61px;
    padding: 8px 10px;

    .member {
        font-size: 13px;
    }

    .id {
        font-size: 22px;
    }
}
