.stay-with-us {
    padding: 40px 0px 60px 0;

    .stay-with-us-title {
        font: normal normal bold 22px/28px Playfair Display;
    }

    .img-container {
        min-width: 150px;
        width: 150px;
        max-width: 150px;
        height: auto;
        padding-right: 2px;
        padding-top: 2px;

        &:last-child {
            padding-right: 0;
        }

        .img-background {
            width: 100%;
            height: 100%;
        }
    }

    .stay-with-us-subtitle {
        font: normal normal 600 15px/20px Source Sans Pro;
        margin-bottom: 40px;
        margin-top: 30px;

    }

    .stay-with-us-btn {
        width: 282px;
        height: 56px;
        font-size: 20px;
    }
}
