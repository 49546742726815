@media screen and (max-width: $tablet-width) {
    @import "AddNewCreditCardModal";
    @import "CustomSelect";
    @import "CustomTextField";
    @import "SwitchLanguage";
    @import "Toggle";
    @import "Accordion";
    @import "videoModal";
    @import "ContractModal";
    @import "UpdateUserModal";
    @import "ThankYouModal";
    @import "messageModal";
}
