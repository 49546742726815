.header-menu {
    height: 56px;
    width: 101px !important;

    &.closed {
        background-color: $black;
        width: 0px !important;
    }

    .menu-text {
        font: normal normal normal 16px/16px Source Sans Pro;
        letter-spacing: 0.8px;
    }
}

.menu-content-container {
    .first-menu-item {
        font: normal normal bold 22px/28px Playfair Display;
        padding: 64px 0px 20px 46px;
        max-height: 150px;

        .logout-btn {
            font-size: 16px;
        }

        .header-accordion{
            display: block;
            font: normal normal normal 18px/31px Source Sans Pro;
            margin-left: 0px !important;
        }

    }

    .menu-item {
        padding-left: 46px;
        font: normal normal normal 18px/31px Source Sans Pro;
        height: 49px;
    }

    .sub-menu-item {
        padding-left: 46px;
    }


    .last-menu-container {
        padding-left: 46px;
    }

    .menu-item-accordion-summary{
        padding-left: 46px;
    }

    .menu-item-accordion-details{
        .menu-item {
            padding-left: 46px;
        }
    
        .sub-menu-item {
            padding-left: 46px;
        }
    }
}

#header-menu {
    .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
        top: 0 !important;
        bottom: auto;
        left: 0 !important;
    }
}
