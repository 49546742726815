.card-video-container {
    .card-with-video-details-container {
        padding: 20px;

        .card-with-video-title {
            font-size: 20px;
            margin-bottom: 10px;
        }

        .card-with-video-subtitle {
            font-size: 18px;
            margin-bottom: 20px;
        }

        .video-link {
            .play-video-btn {
                font-size: 18px;
            }
        }

        a {
            font-size: 18px;
        }
    }
}
