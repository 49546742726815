.header-image {
    width: 100vw;
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('/img/desktop/generalslider.jpg');
    background-position: 0% 25%;

    .payments-header-container {
        margin-top: 30px;

        .logo-and-title-container {
            max-width: 649px;
            margin: 0 auto;
            justify-content: center;
            text-align: center;
            display: block;

            .logo-container {
                margin-right: 0px;
            }

            .title-container {
                h1 {
                    margin-top: 10px;
                    text-align: center;
                    font: normal normal bold 23px Playfair Display;
                    letter-spacing: 0;
                    color: #FFFFFF;
                    opacity: 1;
                }

                hr.border {
                    margin-top: 30px;
                    border: 2px solid #7B9AD1 !important;
                    width: 48px;
                }
            }
        }

        .center-text {
            padding-top: 18px;
            margin: 0 auto;
            text-align: center;
            font: normal normal normal 18px Source Sans Pro;
            letter-spacing: 0;
            max-width: 556px;
            color: #FFFFFF;
            opacity: 1;
        }
    }

    .select-contract {
        padding-top: 0px;
        text-align: center;
        font: normal normal bold 20px Source Sans Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
}
