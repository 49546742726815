
.custom-field {

    &.captcha {
        padding: 0 !important;
    }

    .MuiTextField-root {
        background-color: transparent;
        color: $white;
        font-family: Source Sans Pro, sans-serif;
    }

    .MuiInputBase-input {
        color: $white;
        font-family: Source Sans Pro, sans-serif;
    }

    &.black {
        .MuiInputBase-input {
            color: $black;
            font: normal normal normal 18px/36px Source Sans Pro;
        }
    }

    .MuiFormLabel-root {
        color: $grey1;
        font-family: Source Sans Pro, sans-serif;
    }

    .MuiFormLabel-root.Mui-focused {
        color: $grey1;
    }

    .MuiFormLabel-root.Mui-error {
        color: $error;
    }

    .MuiInput-underline::before {
        border-bottom: 1px solid $grey1;
    }

    .MuiInput-underline:hover:not(.Mui-disabled)::before {
        border-bottom: 1px solid $grey1;
    }

    .MuiInput-underline::after {
        border-bottom: 1px solid $grey1;
    }

    .MuiInput-underline.Mui-error::after {
        border-bottom: 1px solid $error;
    }

    // PASSWORD SECTION
    .visibility {
        cursor: pointer;
    }

    .password-icon {
        border-bottom: 1px solid $grey1;
        padding-top: 23px;
        padding-bottom: 5px;

        &.error {
            color: $error;
            border-bottom: 1px solid $error !important;
        }
    }
}


