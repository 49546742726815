.destination-image {
    height: 270px;
    &.jungala {
        background-image: url('/img/destinations/mobile/jungala.jpg');
        background-position: 0% 25%;
    }

    &.palacio {
        background-image: url('/img/destinations/mobile/palacio_maya.jpg');
        background-position: 0% 63%;
    }

    &.beachland {
        background-image: url('/img/destinations/mobile/beachland.jpg');
        background-position: 0% 65%;
    }

    &.joya {
        background-image: url('/img/destinations/mobile/joya.jpg');
    }

    &.reserve {
        background-image: url('/img/destinations/mobile/the_reserve.jpg');
    }
    
}
