form {
    &.register-form {
        margin-top: 20px;

        .toggle-container {
            justify-content: left;
            padding: 0;
        }
    }

    &.login-form {
        margin-top: 20px;
    }

    .register-links {
        color: #fff;
        text-decoration: underline;
        font-family: Source Sans Pro, sans-serif;
    }

    .login-link {
        display: block;
        color: $blue1;
        font-family: Source Sans Pro, sans-serif;
    }

    .field-error {
        color: $error;
        font-size: 13px;
        font-family: Source Sans Pro, sans-serif;
        text-align: left;
    }

    input[type="submit"], button[type="submit"] {
        text-transform: uppercase;
        font-size: 20px;
        font-family: Source Sans Pro, sans-serif;
        width: 191px;
        margin: 40px auto 0;
        background: $blue;
        color: #fff;
        border-radius: 2px;
        line-height: 56px;
        border: none;
        letter-spacing: 2px;
        padding: 0;

        &[disabled] {
            opacity: 0.2;
        }
    }

    button.icon-left {
        background: $blue;
        text-transform: uppercase;
        font-size: 20px;
        color: #fff;
        opacity: 0.2;
        text-align: center;
        width: 191px;
        margin: 40px auto 0 0;
        border: none;
        border-radius: 2px;
        padding: 0;
        outline: none;

        span {
            margin-left: -5px;
            font-size: 24px;
        }
    }
}
