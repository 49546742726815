.contract-details {
    //margin-bottom: -32px;
    .margin {
        margin-top: 0;
    }

    > div.container {
        padding: 0;
    }

    .days {
        border-top: 1px solid $grey3;
        border-right: 1px solid $grey3;
        max-width: 33.333%;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:last-child {
            border-right: 0;
        }

        .number {
            text-align: center;
            font: normal normal normal 39px/31px Source Sans Pro;
            letter-spacing: 0;
            color: $white;
            opacity: 1;
            margin-bottom: 15px;
        }

        p.label {
            font: normal normal normal 11px/13px Source Sans Pro;
            padding-bottom: 0px;
        }
    }
}
