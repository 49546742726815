.reservation-item {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    .image-and-name-container {
        width: 100%;
        max-height: 160px;

        .reservation-image-container {
            width: 108px;
            height: 160px;

            .reservation-image {
                max-height: 160px;
            }
        }

        .details-container {
            max-height: 160px;

            .name-and-number {
                .title {
                    font: normal normal bold 22px/28px Playfair Display;
                    padding-top: 0px;
                }

                .confirmation-nr-title {
                    padding-top: 0;
                }

                .confirmation-nr {
                    font: normal normal normal 18px/31px Source Sans Pro;
                    line-height: 0.3px;
                }
            }
        }
    }

    .guests-unit-container {
        .guests {
            padding-left: 16px;
        }

        .unit-type {
            padding-left: 16px;
        }
    }

    .reservation-action-container {
        .new-reservation-action-item {
            //height: 45px;

            .action-item-container {
                //height: 44px;

                .action-text {
                    font: normal normal 600 16px/22px Source Sans Pro;
                }
            }
        }
    }
}
