.past-reservations {
    margin-bottom: 40px;

    .custom-accordion {
        display: flex;
        justify-content: space-between;
        width: 300px;
    }

    .past-reservations-title {
        text-align: center;
        letter-spacing: 2px;
        color: #000000;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 40px;
        padding-bottom: 40px;
        font: normal normal bold 18px Source Sans Pro;
        margin-bottom: 0px;
    }

    .cancelled-reservations-title {
        text-align: center;
        letter-spacing: 2px;
        color: #000000;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 40px;
        padding-bottom: 40px;
        font: normal normal bold 18px Source Sans Pro;
        margin-bottom: 0px;
    }
}

