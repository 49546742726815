.message-area-reply-button {

    > button {
        border: 0;
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 1px;
        text-transform: uppercase;
        opacity: 1;
        outline: none;

        > img {
            margin-right: 5px;
        }
    }
}
