.entry-section {
    background-color: $white;
    display: flex;
    justify-content: center;

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .entry-section-content {

        p {
            padding-top: 0;
            text-align: center;
            font: normal normal 600 18px/16px Source Sans Pro;
            letter-spacing: 0;
            color: #7F7D7D;
            opacity: 1;
        }
    }
}
