
.MuiGrid-spacing-xs-2 > .MuiGrid-item.custom-field {
    padding: 0 8px;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item.custom-field {
    padding: 0 8px;
}

.custom-field {
    &.captcha {
        margin-top: 40px;
    }
    .MuiFormControl-marginNormal {
        margin: 0;
    }

    .password-icon {
        padding-top: 16px;
        padding-bottom: 5px;
    }
}
