.about-video-section {
  

    .text-container {
        width: 320px;

        h2 {
            font: normal normal bold 25px/30px Playfair Display;
            padding-top: 0;
        }
    
        p {
            font: normal normal 600 18px/24px Source Sans Pro;
            max-width: 290px;
        }
    }

    .video-container {
        padding-bottom: 0;
        margin-bottom: 20px;
    }
}
