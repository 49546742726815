.payments-type {
    margin: auto;
    text-align: center;

    .payments-boxes {
        margin-bottom: 50px;

        .payment-type-options:last-child {
            border-bottom: 1px solid #C7C7CC;
        }
    }

    .payments-container {
        .payments-title-container {
            .payment-type-text {
                margin-top: 50px;
                margin-bottom: 50px;
                h3 {
                    text-align: center;
                    font: normal normal bold 25px/32px Playfair Display;
                    letter-spacing: 0px;
                    color: $black;
                    text-transform: capitalize;
                    opacity: 1;
                }
            }
        }
    }

    .payment-type-options {
        width: 675px;
        border-top: 1px solid #C7C7CC;
        color: $black;
        align-items: center;
        display: flex;
        text-align: left;
        padding-left: 20px;

        .MuiBadge-anchorOriginTopRightRectangle {
            background-color: #AEAEB2;
            color: #fff;
        }

        .MuiBadge-root {
            margin-bottom: 30px;

            > {
                background: red;
            }
        }
    }

    .custom-tool-tip {
        background: grey;
        width: 16px;
        display: inline-block;
        color: white;
        border-radius: 100%;
        text-align: center;
        margin: 0 auto;
        position: relative;
        top: -15px;
        height: 16px;
        font-size: 10px;
        right: 15px;
    }

    .options-container {
        display: flex;
        margin: 0 auto;
    }

    .payment-type-balance {
        background: #EFEFEF;
        text-align: right;

        .payment-type-box {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-right: 20px;

            > h4 {
                font: normal normal 600 13px/14px Source Sans Pro;
                letter-spacing: 0;
                color: $grey1;
                text-transform: uppercase;
                opacity: 1;
                text-align: right;
            }

            > p {
                font: normal normal 600 22px/14px Source Sans Pro;
                letter-spacing: 0;
                color: $black;
                text-transform: uppercase;
                opacity: 1;
                text-align: right;
            }

            > a {
                text-align: right;
                font: normal normal 600 13px/14px Source Sans Pro;
                letter-spacing: 0px;
                color: #446DB4;
                text-transform: uppercase;
                opacity: 1;
            }
        }
    }
}
