.welcome-component {
    height: 430px;
    .title {
        margin-top: 50px;
        font: normal normal normal 32px/28px Playfair Display;
        letter-spacing: -0.32px;
    }

    .sub-title {
        margin-top: 5px;
        font: normal normal 600 16px/18px Source Sans Pro;
        text-align: center;
        margin: 6px 80px 0;
    }

    .welcome-filters {
        margin-top: 50px;

        span {
            font: normal normal 600 16px/19px Source Sans Pro;
        }
        
        width: 100%;
    }
}
