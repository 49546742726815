.edit-profile-form {
    padding: 40px 35px 0 35px;
    margin: 0 auto;

    .member-no-edit {
        margin-top: -110px;
    }

    @media screen and (max-width: 371px) {
        .member-no-edit {
            margin-top: -145px;
        }
    }

    .title {
        font: normal normal bold 18px/24px Source Sans Pro;
        letter-spacing: 0.9px;
        margin-top: 30px;
        margin-bottom: 0;
    }

    .sub-title {
        margin-bottom: 0;
        font: normal normal normal 16px/36px Source Sans Pro;
        letter-spacing: 0;
    }

    .change-field-btn-container {
        padding-top: 6px;
    }

    .form-title {
        font: normal normal bold 16px/22px Source Sans Pro;
        letter-spacing: 0;
        margin-top: 40px;

        &:first-child {
            margin-top: 24px;
        }
    }

    .form-title-underline {
        width: 10%;
        margin-bottom: 20px;
    }

    .toggle-container {
        display: block;
        padding: 0;
    }

    .select-language-container, .communication-preferences {
        span {
            font: normal normal normal 16px/22px Source Sans Pro;
        }
    }

    .form-title-underline {
        margin-bottom: 20px;
    }

    .submit-btn {
        margin-top: 40px;
        margin-bottom: 48px;
        padding: 0;
        width: 282px;
        height: 56px;
        border: none;
        border-radius: 2px;
        text-align: center;
        font: normal normal 600 20px/36px source sans pro;
        letter-spacing: 2px;
    }
}
