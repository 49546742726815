.header-faqs {
    width: 100%;
    max-width: 472px;

    > h2 {
        padding-top: 60px;
        font: normal normal bold 22px/25px Source Sans Pro;
        letter-spacing: 1.1px;
    }

    > p {
        padding-top: 30px;
        padding-bottom: 60px;
        font: normal normal normal 18px/24px Source Sans Pro;
        letter-spacing: 0;
    }
}
