.profile-details {
    max-width: 595px;
    /* height: 102px; */
    /* padding: 20px 61px 20px 61px; */
    margin: -50px auto 0 auto;

    .name {
        font-size: 25px;
        text-transform: capitalize;
    }

    .logo {
        margin-left: 20px;

        img {
            width: 200px;
            height: auto;
        }
    }
}
