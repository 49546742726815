.other-fees-payment-form-header {
    .other-fees-payment-form-header-item {
        width: 145px;
        height: 59px;
        padding: 0 20px;

        .label {
            font: normal normal normal 16px/18px Source Sans Pro;
        }
    }
}
