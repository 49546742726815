.message-area-reply-form {
    background-color: $white;
    height: 150px;
    border-top: 1px solid $grey4;
    position: relative;

    form {
        padding: 28px 0;
        height: 100%;
        display: flex;
        justify-content: center;

        textarea {
            border: none;
            width: 100%;
            height: 80px !important;
            font: normal normal 18px/20px Source Sans Pro;
            padding: 0;
            outline: none;
            resize: none;
        }

        textarea::-webkit-input-placeholder {
            color: $grey7;
        }

        textarea:-moz-placeholder { /* Firefox 18- */
            color: $grey7;
        }

        textarea::-moz-placeholder {  /* Firefox 19+ */
            color: $grey7;
        }

        textarea:-ms-input-placeholder {
            color: $grey7;
        }

        .field-error {
            position: absolute;
            bottom: 15px;
        }
    }

    .send-message-btn-center {
        height: 100%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .send-message-btn {
            margin-right: 10px;
            border: 0;
            outline: none;
            cursor: pointer;
            width: 50px;
            background-repeat: no-repeat;
            background-color: $switch-bg-color;
            height: 50px;
            border-radius: 100%;

            img {
                width: 33px;
                height: 30px;
            }
        }
    }
}
