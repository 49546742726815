.write-message {
    margin-top: 0;

    .accordion-item {
        padding: 15px 25px;

        .message-body {
            p {
                margin: 0;
            }
        }
    }

    .accordion-container {
        padding-top: 0;
        padding-bottom: 256px;
    }

    .message-content {

        .show-message-body {
            padding-top: 13px;
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}
