.additional-traveler-item {
    width: 100%;
    height: 50px;

    p.additional-traveler-title {
        padding-left: 8px;
        margin-top: 8px;
    }

    p.additional-traveler-name {
        padding-left: 8px;
        letter-spacing: 0px;
    }

    .action-buttons-container {
        margin-top: 7px;
    }
}
