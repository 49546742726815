.your-reservation {

    .reservation-summary-information-container {
        min-height: 378px;
        height: auto;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    h1 {
        font: normal normal bold 22px/28px Playfair Display;
        letter-spacing: 0;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    h2 {
        margin-left: 15px;
        text-align: left;
        font: normal normal bold 22px/20px Playfair Display;
        padding-top: 15px;
    }

    h3 {
        margin-left: 15px;
        text-align: left;
        font: normal normal 600 18px/18px Source Sans Pro;
        letter-spacing: 0px;
        margin-top: 8px;
    }

    h4 {
        margin-left: 15px;
        text-align: left;
        font: normal normal normal 18px/18px Source Sans Pro;
        letter-spacing: 0px;
        margin-bottom: 15px;
    }

    .reservation-summary-image {
        // height: 204px;
        // width: 340px;
    }

    .reservation-info {
        .reservation-info-text {
            height: 114px;

            h2 {
                margin-left: 15px;
            }
        }

        .reservation-summary-dates {

            .reservation-date-container {
                height: 56px;
            }

            p.reservation-date {
                font: normal normal 600 18px/18px Source Sans Pro;
            }
        }
    }
}
