.messages-list {
    margin-bottom: 61px;

    .messages-item {
        padding: 20px 15px 10px 8px;

        .message-left-section {
            .message-icon-container {
                width: 15px;
            }
        }

        .subject {
            font: normal normal normal 14px/16px Source Sans Pro;
            margin-left: 15px;
        }
    }
}
