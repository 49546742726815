.custom-autocomplete {
    .MuiSvgIcon-root {
        color: $grey1;
    }
}

.custom-autocomplete.has-error {
    .MuiSvgIcon-root {
        color: $error
    }

    .MuiInput-underline::before {
        border-bottom: 1px solid $error;
    }

    .MuiInput-underline::after {
        border-bottom: 1px solid $error;
    }

    .MuiInput-underline:hover:not(.Mui-disabled)::before {
        border-bottom: 1px solid $error;
    }

    .MuiFormLabel-root {
        color: $error !important;
    }
}
