.transportation-title-container {
    margin-bottom: 30px;

    .transportation-title {
        font: normal normal bold 25px/30px Playfair Display;
    }
}

// .transportation-container {
//     max-width: 717px;
//     height: 115px;
//     margin-bottom: 60px;

//     .transportation-image-container {
//         width: 244px;
//         height: 115px;

//         .transportation-image {
//             width: 244px;
//             height: 115px;
//         }
//     }

//     .transportation-info-container {
//         width: 473px;

//         .transportation-info {
//             margin-top: 20px;

//             p.transportation-subtitle {
//                 font: normal normal bold 18px/25px Playfair Display;
//             }

//             p.transportation-text {
//                 font: normal normal normal 16px/20px Source Sans Pro;
//             }
//         }
//     }
// }
