@media screen and (max-width: $tablet-width) {
    &.my-vida-vacations-paymemts-history{
        .wrapper {
            width: 100%;
            max-width: 512px;
            margin: 0 auto;
            padding-top: 60px;
        }
        @import "EntrySection";
        @import "ContractsList";
        @import "ContractsHeader";
    }
}
