.profile-details {
    /* min-height: 90px; */
    width: 100%;
    /* padding: 0 15px; */
    margin: 0px auto 0 auto;
    padding: 20px 30px 20px 30px;
    
    .name {
        font-size: 22px;
    }

    .link {
        font-size: 16px;
    }

    .logo {
        margin-top: 10px;
        text-align: right;
        margin-left: 0px;

        img {
            width: 190px;
            height: auto;
        }
    }
}
