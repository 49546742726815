// Colors
$white: #fff;
$light-grey: #868686;
$body-bg: #000;
$blue: #446DB4;
$dark-blue: #0A162B;
$blue1: #7B9AD1;
$blue2: #1E3B6C;
$blue3: #446DB4;
$grey: #151414;
$grey1: #8E8E93;
$grey2: #f7f7f7;
$grey3: #444444;
$grey4: #C7C7C7;
$grey5: #D1D1D6;
$grey6: #AEAEB2;
$grey7: #C7C7CC;
$grey8: #333333;
$grey9: #f1f1f1;
$grey10: #888;
$grey11: #555;
$grey12: #e9e9e9;
$black1: #111111;
$orange: #E5692D;
$orange1: #E6A82E;
$error: #f10f0f;
$warning: #E5692D;
$confirmed: #25903E;
$lavender: #E5E5EA;
$light-blue: #6EC7D9;
$pink: #E0327B;
$red: #D62121;
$greyWhite: #EBF0F4;
$black: #000;
$green: #25903E;
$charcoal: #444444;
$nero: $grey;
$mustard: #C5A57B;
$red-with-opacity: rgb(239, 83, 83);
$notification-success-background: $green;

$switch-bg-color: #446DB4;
$sorrellbrown: #C5A57B;

// sizes
$desktop-width: 1500px;
$laptop-width: 1200px;
$tablet-width: 768px;
$mobile-width: 500px;

$font-family-base: Source Sans Pro, sans-serif;
$font-family-sans-serif: "Nunito", sans-serif;
$font-family-regular: Regular, "Nunito";
$font-family-bold-condensed: 'Univers Condensed BQ', sans-serif;


@import '~bootstrap/scss/bootstrap';
@import "~react-datepicker/dist/react-datepicker.css";

@import "auth";
@import "account";
@import "components";
@import "fonts";
@import "layout";
@import "loader";
@import "defaultAutoComplete";

html {
    scroll-behavior: smooth;
    font-family: Source Sans Pro, sans-serif;
}

body {
    font-family: Source Sans Pro, sans-serif;
}

.cursor-pointer {
    cursor: pointer;
}
