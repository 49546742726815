.about-section-background {
    background: $grey2;
    
    .about-list-section {
        margin: 0 auto;
        width: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding-top: 57px;
    
        h2 {
            margin: 0;
            text-align: center;
            font: normal normal bold 16px Source Sans Pro;
            letter-spacing: 0;
            color: #444444;
            opacity: 1;
        }
    
        p {
            text-align: center;
            font: normal normal normal 18px Source Sans Pro;
            letter-spacing: 0;
            color: $grey;
            opacity: 1;
            padding-top: 30px;
            padding-bottom: 80px;
            max-width: 446px;
            margin: 0 auto;
        }
    
        .about-list-container {
            padding-top: 50px;
            padding-bottom: 80px;
            justify-content: center;
            max-width: 951px;
            margin: 0 auto;
        }
    
        ul {
            width: 100%;
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: inline-block;
            text-align: left;
            color: $grey;
        }
    
        li {
            padding-bottom: 35px;
            text-align: left;
            font: normal normal bold 18px Source Sans Pro;
            letter-spacing: 0;
            color: $grey;
            opacity: 1;
            align-items: center;
            display: flex;
    
            .dot {
                aspect-ratio: 1;
                height: 8px;
                width: 8px;
                background-color: $blue;
                border-radius: 50%;
                display: inline-block;
                margin-right: 15px;
            }
        }
    }
    

}
