.accordion-faqs {
    padding-top: 60px;
    padding-bottom: 66px;
    max-width: 675px;
    width: 100%;

    .MuiAccordionSummary-root {
        height: 63px;
        padding: 0;
    }

    .MuiIconButton-edgeEnd {
        margin-right: 20px;
    }

    .MuiIconButton-root {
        padding: 0;
    }

    .MuiAccordionDetails-root {
        padding: 0 20px 15px;
    }

    .questionExpanded, .summary {
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .summary > p {
        text-align: left;
        font: normal normal normal 18px/20px Source Sans Pro;
        letter-spacing: 0;
        color: $black;
        opacity: 1;
        padding: 20px 0 0 20px;
    }

    .questionExpanded > p {
        text-align: left;
        flex-basis: 100%;
        flex-shrink: 0;
        letter-spacing: 0;
        color: $black;
        opacity: 1;
        font: normal normal 600 18px/20px Source Sans Pro;
        padding: 20px 0 0 20px;
    }

    .addBorder {
        border: 1px solid $blue;
        margin-bottom: 15px;
    }

    .answer > p {
        margin: 0;
        text-align: left;
        font: normal normal normal 18px/24px Source Sans Pro;
        letter-spacing: 0;
        color: $grey3;
        opacity: 1;
    }
}
