.contracts-list {
    padding-top: 30px;
    padding-bottom: 160px;

    .list-contract {
        .list-my-contracts {
            p.resort-name {
                font: normal normal 600 18px/22px Source Sans Pro;
            }

            p.contract-number {
                font: normal normal bold 18px/22px Source Sans Pro;
            }
        }
    }
}
