.field-error {
    color: $error;
    font-size: 13px;
    font-family: Source Sans Pro, sans-serif;
    text-align: left;

    &.center {
        text-align: center;
    }
}
