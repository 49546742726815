.write-message {
    margin-top: 0;

    .accordion-container {
        padding-top: 0;
    }

    .accordion-item {
        &.short-message {
            padding: 10px 17px;
        }

        .message-body {

            p {
                text-align: left;
                font: normal normal normal 13px/16px Source Sans Pro;
                letter-spacing: 0;
                color: #444444;
                opacity: 1;
            }
        }
    }

    .message-content {
        margin-bottom: 5px;

        .message-header-date {

            p {
                text-align: right;
                font: normal normal normal 13px/20px Source Sans Pro;
                letter-spacing: 0;
                color: $grey1;
                opacity: 1;
            }
        }

        .message-subject-read {

            h2 {
                text-align: left;
                font: normal normal bold 18px/20px Source Sans Pro;
                letter-spacing: 0;
                color: $black1;
                text-transform: capitalize;
                opacity: 1;
            }
        }

        .show-message-body {
            padding-top: 15px;

            .message-body {

                p {
                    text-align: left;
                    font: normal normal normal 16px/22px Source Sans Pro;
                    letter-spacing: 0;
                    color: $grey;
                    opacity: 1;
                }
            }
        }
    }

    .message-content:last-child {
        margin-bottom: 0;
    }
}
