.contract-carousel {
    min-height: 78px;
    padding: 0;

    .slick-slide {
        > div {
            display: grid;
            place-items: center;
        }
    }

    .contracts-slider {
        min-height: 58px;
        margin: 0px auto;

        p.contract-carousel-text {
            font: normal normal normal 16px/18px Source Sans Pro;
            letter-spacing: 0.8px;

            &.contract-number {
                font: normal normal bold 16px/18px Source Sans Pro;
                letter-spacing: 0.8px;
            }
        }
    }
}
