.about-video-section {
    max-width: 951px;
    margin: 50px auto;

    .text-container {
        width: 100%;
        margin: 0 auto;
        
        h2 {
            text-align: center;
            font: normal normal bold 22px Playfair Display;
            letter-spacing: 0;
            color: #000000;
            //text-transform: capitalize;
            opacity: 1;
            padding-top: 52px;
            margin-bottom: 0;
        }
    
        p {
            text-align: center;
            font: normal normal normal 18px Source Sans Pro;
            letter-spacing: 0;
            color: #444;
            opacity: 1;
            padding-top: 30px;
            max-width: 343px;
            margin: 0 auto;
        }
    }

    .video-container {
        margin: 0 auto;
    }

    .video-frame {
        width: 100%;
        height: auto;
    }
}
