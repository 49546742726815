.toggle-container {
    .MuiTypography-root {
        font-family: Source Sans Pro, sans-serif;
    }

    .track {
        background-color: $grey1 !important;
    }

    .switch-color-checked {
        color: $white !important;
    }

    //Toggle from edit profile section
    &.edit-profile,&.request-reservation,&.save-credit-card {
        .MuiSwitch-root {
            padding: 8px;
        }

        .MuiSwitch-track {
            border-radius: 12px;
        }

        .MuiFormControlLabel-root {
            align-items: flex-start;
        }

        .MuiFormControlLabel-label {
            color: $black !important;
            text-align: left;
            font: normal normal normal 18px/24px Source Sans Pro;
            letter-spacing: 0px;
            margin-top: 7px;
        }
    }

    //Toggle from register section
    &.register {
        .MuiSwitch-root {
            padding: 8px;
        }

        .MuiSwitch-track {
            border-radius: 12px;
        }

        .MuiFormControlLabel-root {
            align-items: flex-start;
        }

        .MuiFormControlLabel-label {
            color: $white !important;
            text-align: left;
            font: normal normal normal 18px/24px Source Sans Pro;
            letter-spacing: 0px;
            margin-top: 5px;
        }
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: $blue !important;
        opacity: 1 !important;
    }
}
