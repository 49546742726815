.message-area-reply-button {
    background-color: $blue;
    height: 56px;
    display: flex;
    justify-content: center;

    > button {
        color: $white;
        border: 0;
        background-color: $blue;
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 1px;
        text-transform: uppercase;
        opacity: 1;
        outline: none;

        > img {
            margin-right: 5px;
            margin-bottom: 4px;
        }
    }
}
