.profile-details {
    padding-bottom: 40px;

    .details-container {
        margin: 0;

        .section-profile {
            padding: 0;

            .box-container-tablet {
                margin-bottom: 20px;
            }
        }

        .section-profile .section-profile-info {
            margin-top: 0;
        }

        .label-info, .info {
            margin: auto;
            text-align: center;
        }
    }

    .edit-profile-button {
        margin-top: 0;
        
        .edit-button {
            letter-spacing: 1px;
            font: normal normal bold 18px Source Sans Pro;
            text-transform: uppercase;
            opacity: 1;
            padding: 18px 60px;
            width: 282px;
            margin-top: 0;
        }
    }
}
