.card-video-container {
    &.card-desktop {
        height: 240px;
        width: 270px;
    }

    img {
        width: 100%;
        height: 100%;

        &.card-desktop {
            object-fit: cover;
        }
    }

    .card-with-video-details-container {
        background-color: $grey2;
        padding: 30px;

        &.card-desktop {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .card-with-video-title {
            font: normal normal bold 20px Playfair Display;
            letter-spacing: 0;
            font-weight: bold;
            color: $black;
            margin-bottom: 15px;
            height: 41px;

            &.card-desktop {
                height: auto;
            }
        }

        .card-with-video-subtitle {
            color: $grey;
            font: normal normal normal 18px Source Sans Pro;
            margin-bottom: 15px;

            &.card-desktop {
                margin-bottom: 30px;
            }
        }

        .video-link {
            .play-video-btn {
                border: none;
                padding: 0;
                color: $blue;
                font: normal normal bold 16px Source Sans Pro;
                text-decoration: underline;
                cursor: pointer;
                font-weight: bold;
                background: $grey2;
                text-underline-offset: 5px;
                .card-video-icon {
                    margin-right: 10px;
                    margin-bottom: 2px;
                }
            }
        }

        a {
            color: $grey2;
            font: normal normal bold 16px Source Sans Pro;
            letter-spacing: 0;
            color: $blue;
            text-decoration: underline;
            font-weight: bold;
            text-underline-offset: 5px;
        }
    }

}
