.payment-method-header {
    width: 473px;

    .title {
        font: normal normal bold 22px/25px Source Sans Pro;
        letter-spacing: 1.1px;
        margin: 40px 0 20px 0;
    }

    .payment-method-header-item {
        padding: 20px 0;

        &:last-child{
            margin-bottom: 40px;
        }

        .payment-amount {
            width: 90%;
        }
    }


}
