.destination-highlights  {
    height: auto;
    padding: 60px 0px;
    display: block;

    h2 {
        font: normal normal bold 25px/30px Playfair Display;
        margin-bottom: 30px;
    }

    .slick-slide {
        padding: 0 15px 0 0;
        max-width: 350px;

        .card {
            cursor: pointer;
            overflow: hidden;
            width: 100%;
            display: block;

            .card-container {
                display: block;
                position: relative;
                height: 330px;

                .card-img-container {
                    width: 100%;
                    height: 162px;
                }

                .caption {
                    .subtitle {
                        margin: 0;
                        font: normal normal normal 16px/36px Source Sans Pro;
                    }

                    .title {
                        font: normal normal bold 25px/30px Playfair Display;
                    }

                    .learn-more {
                        //font-size: 20px;
                        height: 44px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
}
