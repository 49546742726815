.about-video-section {
    width: 100%;
    text-align: center;
    justify-content: center;

    .text-container {
        width: 320px;

        h2 {
            font: normal normal bold 22px/28px Playfair Display;
            display: flex;
            justify-content: center;
        }

        p {
            text-align: center;
            font: normal normal 600 15px/20px Source Sans Pro;
            padding-top: 20px;
            max-width: 320px;
            display: flex;
            justify-content: center;
        }
    }

    .video-container {
        padding-bottom: 0;
        margin-bottom: 20px;
    }

   
}

