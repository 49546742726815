.header-menu {
    align-items: center;
    height: 70px;
    background-color: $grey;
    cursor: pointer;
    padding-left: 8px;
    width: 105px !important;

    .menu-text {
        font: normal normal normal 16px/14px Source Sans Pro;
        letter-spacing: 0.9px;
    }

    .icon {
        margin-bottom: 4px;
        margin-right: 10px;
    }

    .close-menu-button {
        z-index: 9999;
        cursor: pointer;
        padding: 20px 0;
        font: normal normal normal 16px/14px Source Sans Pro;
        letter-spacing: 0px;
    }
}

.menu-content-container {
    .first-menu-item {
        padding: 20px 0px 20px 25px;
        display: flex;
        max-height: 120px;

        .user-details {
            font: normal normal bold 22px/22px Playfair Display;
        }

        .logout-btn {
            cursor: pointer;
            text-decoration: underline;
            color: $blue1;
            font: normal normal normal 18px/36px Source Sans Pro;
        }

        .header-accordion{
            display: none;
        }

    }

    .menu-item {
        padding-right: 20px;
        padding-left: 25px;
        border-top: 1px solid $grey1;
        font: normal normal normal 20px/31px Source Sans Pro;
        height: 57px;
        cursor: pointer;
    }

    .sub-menu-item {
        background-color: $grey;
        padding-right: 20px;
        padding-left: 35px;
        font: normal normal normal 20px/31px Source Sans Pro;
        height: 57px;
        cursor: pointer;
    }

    .last-menu-container {
        padding-left: 25px;
        height: 130px;
        background-color: $grey;

        .last-menu-item {
            cursor: pointer;
            color: $grey1;
            text-align: left;
            font: normal normal normal 16px/31px Source Sans Pro;
            letter-spacing: 0px;
        }
    }

    .menu-item-accordion-summary{
        padding-right: 20px;
        padding-left: 25px;
        border-top: 1px solid $grey1;
        font: normal normal normal 20px/31px Source Sans Pro;
        height: 57px !important;
        cursor: pointer;
        color: $white !important; 
    }

    .menu-item-accordion-details{

        background-color: #262626 !important;
        padding: 0;

        .menu-item {
            padding-right: 20px;
            padding-left: 25px;
            border-top: 1px solid $grey1 !important;
            font: normal normal normal 20px/31px Source Sans Pro;
            height: 57px;
            cursor: pointer;
            color: $white;
        }
    
        .sub-menu-item {
            padding-right: 20px;
            padding-left: 25px;
            background-color: #262626 !important;
            border-top: 1px solid $grey1 !important;
            font: normal normal normal 20px/31px Source Sans Pro;
            height: 57px;
            cursor: pointer;
            color: $white;
        }
    }

}

#header-menu {
    .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
        top: 70px !important;
        width: 330px;
        bottom: auto;
        height: auto;
        background-color: $black;
        color: $white;

        .MuiList-padding {
            padding-bottom: 0px;
        }
    }

    .MuiPaper-rounded {
        border-radius: 0;
    }

    .MuiAccordion-root.Mui-expanded {
        margin: 0 0 !important;
    }
}


