.contract-details {
    .days {
        max-height: 100px;

        .number {
            font: normal normal normal 40px/31px Source Sans Pro;
            margin-bottom: 5px;
        }

        .label {
            font: normal normal normal 13px/13px Source Sans Pro;
            text-transform: capitalize;
        }
    }
}
