.single-card-container {
    width: 282px;
    height: 402px;
    margin-top: 40px;
    margin-bottom: 94px;

    &.one-item {
        max-width: 282px !important;
    }

    .card-content {
        margin: 30px 20px 20px 20px;

        .card-text {
            padding: 20px 0;
            max-width: 242px;
            font: normal normal normal 16px/22px Source Sans Pro;
            letter-spacing: 0;
        }

        .card-button-container {
            .card-button {
                width: 242px;

                .link-btn {
                    .visit-website {
                        font: normal normal 600 20px/36px Source Sans Pro;
                    }
                }
            }
        }
    }
}
