.messages-centre-header {
    height: 147px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .title {
        text-align: center;
        font: normal normal bold 25px/32px Playfair Display;
        letter-spacing: 0;
        color: $white;
        text-transform: capitalize;
        opacity: 1;
        padding-bottom: 20px;
    }

    .messages-info-container {
        border-top: 1px solid $grey1;
        padding-top: 20px;

        .nr-messages {
            text-align: left;
            font: normal normal 600 18px/20px Source Sans Pro;
            letter-spacing: 0px;
            color: $white;
            opacity: 1;
        }

        .mark-as-read {
            cursor: pointer;
            text-align: left;
            font: normal normal 600 16px/31px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey5;
            text-transform: uppercase;
            opacity: 1;
        }
    }
}
