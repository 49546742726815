.destination-image {
    width: 100vw;
    height: 374px;
    background-size: cover;
    background-repeat: no-repeat;

    &.jungala {
        background-image: url('/img/destinations/desktop/jungala.jpg');
        background-position: 0% 25%;
    }

    &.palacio {
        background-image: url('/img/destinations/desktop/palacio_maya.jpg');
        background-position: 0% 63%;
    }

    &.beachland {
        background-image: url('/img/destinations/desktop/beachland.jpg');
        background-position: 0% 65%;
    }

    &.joya {
        background-image: url('/img/destinations/desktop/joya.jpg');
    }

    &.reserve {
        background-image: url('/img/destinations/desktop/the_reserve.jpg');
    }
}

