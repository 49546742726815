.past-reservations {
    margin-top: 30px;
    // margin-bottom: 99px;

    // .past-reservations-title {
    //     font: normal normal bold 25px/30px Playfair Display;
    // }

    // .cancelled-reservations-title {
    //     font: normal normal bold 25px/30px Playfair Display;
    // }
}

