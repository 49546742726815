.about-list-section {
    h2 {
        font: normal normal bold 16px/22px Source Sans Pro;
        padding-top: 30px;
        color: $black;
    }

    p {
        font: normal normal normal 18px/24px Source Sans Pro;
        max-width: 446px;
        margin: 0 auto;
    }

    .about-list-container {
        padding-top: 30px;
        padding-bottom: 60px;
        max-width: 375px;
    }

    li {
        font: normal normal normal 14px/16px Source Sans Pro;

        .dot {
            height: 20px;
            width: 20px;
            margin-right: 7px;
        }
    }
}

