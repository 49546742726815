.pre-registration-section {
    h1 {
        font: normal normal bold 22px/28px Playfair Display;
        padding-top: 40px;
    }

    p {
        font: normal normal normal 16px/22px Source Sans Pro;
        padding-top: 20px;
        padding-bottom: 40px;
        max-width: 284px;
    }

    .pre-registration-button {
        width: 340px;
        margin-bottom: 80px;
    }
}
