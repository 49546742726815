&.read-message {

    background-color: $greyWhite;
    width: 100%;

    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $grey9;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $grey10;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $grey11;
    }

    @import "ReadMessage";
    @import "WriteMessage";
    @import "MessageAreaReplyForm";
    @import "MessageAreaReplyButton";
    @import "SwitchContainer";
}
