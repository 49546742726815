.reservation-page {
    margin: auto;
    padding-top: 80px;
    max-width: 445px;

    .reservation-page-text {
        padding: 0 5px;

        h2 {
            margin: 0;
            padding: 0;
            text-align: center;
            font: normal normal bold 25px/32px Playfair Display;
            letter-spacing: 0;
            color: $black;
            text-transform: capitalize;
            opacity: 1;
        }

        p {
            text-align: center;
            letter-spacing: 0;
            color: $grey;
            opacity: 1;
            padding-top: 30px;
            font: normal normal normal 18px/24px Source Sans Pro;

            > span {
                font: normal normal bold 18px/24px Source Sans Pro;
            }
        }
    }

    .reservation-form {
        width: 100%;
        margin: auto;
        padding-top: 60px;

        // form field select
        .custom-select-field {

            .MuiSelect-root {
                text-align: left;
                color: $grey;
                font: normal normal normal 18px/24px Source Sans Pro;
            }
        }

        // form field check-date
        .check-in-date {
            width: 100%;

            .MuiFilledInput-root {
                background-color: $white;
            }

            .MuiFilledInput-root.Mui-focused {
                border-bottom: none !important;
            }

            .MuiInputLabel-filled {
                transform: translate(0px, 30px) scale(1);
            }

            .MuiInputLabel-filled.MuiInputLabel-shrink {
                transform: translate(0px, 10px) scale(0.75);
            }

            .MuiFilledInput-input {
                cursor: pointer;
                padding-left: 0;
                font: normal normal normal 18px/24px Source Sans Pro;
            }

            .MuiFilledInput-underline:hover:before {
                border-bottom: 1px solid $grey1;
            }

            .MuiFilledInput-underline:after {
                border-bottom: 1px solid $grey1;
            }

            .MuiFilledInput-underline.Mui-error:after {
                border-bottom: 1px solid $error;
            }

            .MuiIconButton-root {
                padding: 10px;
                margin-top: 10px;
                margin-right: -10px !important;
                outline: none;
            }

            .MuiSvgIcon-root {
                color: $grey4;
            }

            .icon-error {
                color: $error;
            }

            .MuiFilledInput-adornedEnd {
                padding-right: 0px;
            }
        }

        .submit-btn {
            margin: 60px auto 213px auto;

            .submit-button {
                background: $blue;
                border: $blue;
                color: $white;
                width: 191px;
                //text-transform: uppercase;
                height: 56px;
                outline: none;
                border-radius: 2px;
                text-align: center;
                font: normal normal 600 20px/36px Source Sans Pro;
                letter-spacing: 2px;
                opacity: 1;
            }
        }
    }
}

