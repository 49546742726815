.vidanta-submit-button {
    height: 56px;
    background-color: $blue;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    border-radius: 2px;
    padding: 15px 50px;
    font: normal normal 600 20px/36px Source Sans Pro;
    letter-spacing: 2px;
    //text-transform: uppercase;
    margin: 60px auto 10px;
}
