.exchanges-carousel {
    height: auto;
    padding: 40px 0 159px 0;
    margin: 0 auto;
    width: 100%;

    h2 {
        text-transform: uppercase;
        margin: 0 auto 40px;
        text-align: center;
        font: normal normal bold 25px/32px Source Sans Pro;
        color: $black;
        letter-spacing: 2.5px;
    }

    .slick-arrow {
        width: 24px;
        height: 24px;
        background-color: $blue;
    }

    .slick-arrow::before {
        border: solid $white;
        border-width:0 4px 4px 0;
        padding: 1px;
    }

    .slick-dots li.slick-active button:before {
        color: #446DB4;
        font-size: 10px;
    }

    .slick-dots {
        bottom: -35px;
    }

    .slick-dots li {
        height: 20px;
        width: 20px;
        margin: 0 5px 0 5px;
        cursor: pointer;
    }

    .slick-track {
        display: flex;
        justify-content: center;
    }

    .slick-slider {
        //max-width: 1257px;

        .slick-list {
            margin: 32px 0;
            width: 100%;
        }
    }

    .slick-slide {
        height: 454px;
        max-width: 368px;
        margin: 0 15px 0 15px;

        .card {
            max-width: 368px;
            height: 454px;
            cursor: pointer;
            position: relative;
            overflow: hidden;


            .card-container {
                display: block;
                position: relative;
                height: 454px;
                max-width: 368px;
                width: 100%;

                img.logo {
                    width: 100%;
                }

                .card-content {
                    width: 368px;
                    height: 56px;
                    background: #e5e5ea 0 0 no-repeat padding-box;
                    border-radius: 2px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;

                    .caption {
                        .subtitle {
                            color: $grey3;
                            padding: 30px 30px 0 30px;
                            font: normal normal normal 18px/25px Source Sans Pro;
                            text-align: left;
                            min-height: 227px;
                        }
                    }

                    .card-button {
                        width: 308px;
                        height: 56px;
                        margin: 40px 30px 30px 30px;
                        background: #446DB4 0% 0% no-repeat padding-box;

                        .link-btn {

                            color: white;
                            height: 56px;
                            display: flex;
                            text-decoration: none;
                            justify-content: center;
                            align-items: center;
                            bottom: 0;
                            padding-top: 15px;

                            .visit-website-img {
                                margin-bottom: 15px;
                                margin-right: 8px;
                            }

                            .visit-website {
                                font: normal normal 600 20px/36px Source Sans Pro;
                                letter-spacing: 1px;
                                color: #FFFFFF;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}

