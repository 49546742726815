.thank-you-modal {
    z-index: 1299 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .thank-you-modal-container {
        background-color: $white;
        width: 475px;
        height: 315px;
        outline: none;

        .thank-you-modal-title {
            margin-top: 40px;
            margin-bottom: 22px;
            text-align: center;
            font: normal normal bold 25px/36px Playfair Display;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
        }

        .thank-you-modal-text {
            max-width: 350px;
            text-align: center;
            font: normal normal normal 18px/24px Source Sans Pro;
            letter-spacing: 0px;
            color: $grey;
            margin: 0 auto;
            padding-bottom: 30px;
        }

        .thank-you-modal-button {
            width: 330px;
            height: 56px;
            background: #446DB4;
            border: none;
            opacity: 1;
            text-align: center;
            font: normal normal 600 20px/36px Source Sans Pro;
            letter-spacing: 2px;
            color: #FFFFFF;
            text-transform: uppercase;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }
}
