.vida-member-id-container {
    color: $blue2;
    width: 172px;
    height: 67px;
    background-color: $white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    position: absolute;
    top: -48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    //TODO Add here style for my-account and edit profile page
    &.my-account {
        position: static;
        box-shadow: none;
    }

    .member {
        color: $blue2;
        font-size: 15px;
    }

    .id {
        color: $blue2;
        font: normal normal bold 24px/30px Source Sans Pro;
    }

    .hr-line {
        color: $white;
        width: 75%;
    }
}

//@media screen and (max-width: $tablet-width) {
//    .vida-member-id-container {
//        width: 142px;
//        height: 61px;
//        padding: 8px 10px;
//        .member {
//            font-size: 13px;
//        }
//
//        .id {
//            font-size: 22px;
//        }
//    }
//}
//
//
//@media screen and (max-width: $mobile-width) {
//    .vida-member-id-container {
//        position: absolute;
//        z-index: 2;
//        width: 146px;
//        height: 59px;
//        padding: 8px 10px;
//
//        &.vida-dashboard {
//            top: -50px;
//            right: 0;
//        }
//    }
//}
