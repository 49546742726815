.more-about {
    margin: 60px 0 80px 0;

    .title {
        font: normal normal bold 25px/30px Playfair Display;
        margin-bottom: 20px;
    }

    .sub-title {
        font: normal normal 600 18px/23px Source Sans Pro;
        width: 70%;
        margin: 0 auto 70px auto;
    }
}
