.accordion-faqs {
    padding-top: 40px;
    padding-bottom: 24px;

    .summary > p {
        font: normal normal normal 18px/20px Source Sans Pro;
    }

    .questionExpanded > p {
        flex-basis: 100%;
        flex-shrink: 0;
        font: normal normal 600 18px/20px Source Sans Pro;
    }

    .addBorder {
        border: 1px solid $blue;
        margin-bottom: 15px;
    }

    .answer > p {
        text-align: left;
        font: normal normal normal 18px/24px Source Sans Pro;
        letter-spacing: 0;
        opacity: 1;
    }
}
