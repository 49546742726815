.account-profile-member {
    padding-top: 30px;
    height: 213px;

    .account-profile-member-container {
        .hr-line {
            width: 282px;
        }

        .title {
            font: normal normal normal 16px/22px Source Sans Pro;
        }

        .user-name {
            font: normal normal 300 39px/22px Source Sans Pro;
        }
    }
}
