.reservation-found-header-container {
    h1 {
        padding-top: 30px;
        padding-bottom: 20px;
        text-align: center;
        font: normal normal bold 18px/24px Source Sans Pro;
        letter-spacing: 0.9px;
        color: #000000;
        text-transform: uppercase;
        opacity: 1;
    }

    p {
        margin: 0 auto;
        padding-bottom: 40px;
        width: 298px;
        text-align: center;
        font: normal normal normal 16px/22px Source Sans Pro;
        letter-spacing: 0px;
        color: $grey;
        opacity: 1;
    }
}
