.about-list-section {
    .about-list-container {
        padding-bottom: 120px;
        max-width: 595px;
        margin: 0 auto;
    }

    li {
        max-width: 290px;
        font: normal normal normal 16px/16px Source Sans Pro;

        .dot {
            height: 32px;
            width: 32px;
            margin-right: 7px;
        }
    }
}
