.add-new-credit-card-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .add-new-credit-card-modal-container {
        background-color: $white;
        width: 935px;
        outline: none;

        .modal-title {
            padding: 16px 20px 0px 20px;

            span {
                text-align: left;
                font: normal normal bold 18px/25px Source Sans Pro;
                letter-spacing: 0.9px;
                color: $black;
                //text-transform: uppercase;
                opacity: 1;
            }

            svg {
                cursor: pointer;
                margin-bottom: 3px;
                color: $grey6;
            }
        }

        .modal-body {
            padding: 80px 88px 0px 88px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .card-billing-info-container {
                &.is-modal {
                    padding-bottom: 103px;

                    .card-details-container {
                        margin-right: 50px;
                    }

                    .bill-details-container {
                        margin-left: 50px;
                    }

                    .card-billing-title {
                        text-align: center;
                        font: normal normal 600 18px/25px Source Sans Pro;
                        letter-spacing: 0.9px;
                        color: $black;
                        text-transform: uppercase;
                        opacity: 1;
                        margin-bottom: 40px;
                    }

                    // Card form. Rewrite card number classes to be used in modal
                    .custom-field {
                        .card-bill {
                            width: 100%;

                            margin-top: 6px !important;
                            margin-bottom: 6px !important;

                            &.MuiFormControl-marginNormal {
                                margin-top: 6px;
                                margin-bottom: 6px;
                            }

                            .MuiFormLabel-root {
                                text-align: left;
                                font: normal normal normal 18px/20px Source Sans Pro;
                                letter-spacing: 0px;
                                color: $grey6;
                                opacity: 1;
                            }

                            .MuiFormLabel-root.Mui-error {
                                color: $error !important;
                            }
                        }
                    }

                    // Select field for card and bill
                    .custom-select-field-wrapper .MuiFormLabel-root.Mui-error {
                        color: $error !important;
                    }

                    .custom-select-field-wrapper {
                        &.card-bill {
                            z-index: 99999 !important;
                            margin-top: 6px !important;
                            margin-bottom: 6px !important;

                            .MuiFormLabel-root {
                                text-align: left;
                                font: normal normal normal 18px/20px Source Sans Pro;
                                letter-spacing: 0px;
                                color: $grey6;
                                opacity: 1;
                            }

                            .MuiInputBase-input {
                                text-align: left;
                                font: normal normal normal 18px/20px Source Sans Pro;
                                letter-spacing: 0px;
                                color: $black;
                                opacity: 1;
                            }

                            .MuiSelect-selectMenu {
                                height: 0;
                            }

                            .MuiSvgIcon-root {
                                color: $grey6;
                            }

                            .MuiSelect-select.MuiSelect-select {
                                padding-right: 0;
                            }
                        }
                    }

                    // TOGGLE
                    .save-credit-card {
                        margin-top: 22px;
                    }

                    // END TOGGLE

                    // End card Form
                }
            }

            .submit-save-card-btn {
                margin: 0px auto 55px auto;
                width: 313px;
                height: 56px;
                background-color: $blue;
                border: none;
                border-radius: 2px;
                align-items: center;
                justify-content: center;
                display: flex;
                color: $white;
                text-align: center;
                font: normal normal 600 20px/36px Source Sans Pro;
                letter-spacing: 2px;
                text-transform: uppercase;
                opacity: 1;
                outline: none;

                svg {
                    margin-bottom: 3px;
                }
            }

            .modal-spinner{
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }

            &.with-out-padding{
                padding: 1rem;
            }

            .saved-card-container-modal {
                cursor: pointer;
                padding: 15px;
                height: 135px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border: 1px solid $grey2;
                position: relative;
            
                .saved-card-header-modal {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 25px;
                    margin-bottom: 15px;
            
                    .expiration-date-modal {
                        text-align: right;
                        font: normal normal normal 18px/36px Source Sans Pro;
                        letter-spacing: 0px;
                        color: $grey1;
                        opacity: 1;
                    }
                    .img-modal img{
                        width: 40px;
                        height: 27px;
                    }
                }
            
                .number-modal {
                    height: 25px;
                    margin-bottom: 5px;
                    text-align: left;
                    font: normal normal 600 20px/36px Source Sans Pro;
                    letter-spacing: 0px;
                    color: $black;
                    opacity: 1;
                }
            
                .owner-name-modal {
                    text-align: left;
                    font: normal normal normal 18px/36px Source Sans Pro;
                    letter-spacing: 0px;
                    color: $grey;
                    opacity: 1;
                    height: 30px;
                    overflow: hidden;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
