.header {
    img.logo {
        margin-top: 60px;
        width: 177px;
        margin-bottom: 10px;
    }

    .title {
        margin-bottom: 20px;
        font-size: 24px;
    }
}

