.container-white {

    .container {
        margin: 0;
        padding-right: 0;
        padding-left: 0;

        .MuiContainer-root {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.section-messages {
    margin: 0;
}

.container-message {
    max-height: 390px;
    margin: 0;

    .accordion-container {
        padding-top: 15px;

        .message-content {

            .accordion-item {
                padding: 10px 17px;

                .accordion-item-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 10px;
                    padding-left: 17px;
                    padding-right: 17px;
                }

                .message-subject > h3 {
                    font: normal normal bold 14px/20px Source Sans Pro;
                }

                .message-header-date > p {
                    text-align: right;
                    font: normal normal normal 11px/20px Source Sans Pro;
                }

                .message-body > p {
                    text-align: left;
                    font: normal normal normal 13px/16px Source Sans Pro;
                    letter-spacing: 0;
                    color: $grey3;
                    opacity: 1;
                }
            }

            :last-child {
                margin-bottom: 0;
            }

            .MuiAccordionDetails-root {
                padding: 0;
            }

            .message-header-date > p {
                font: normal normal normal 13px/20px Source Sans Pro;
            }

            .show-message-body {
                padding: 15px 17px;

                .message-body {
                    height: auto;

                    > p {
                        text-align: left;
                        font: normal normal normal 13px Source Sans Pro;
                        letter-spacing: 0;
                        color: $grey;
                        opacity: 1;
                        margin-bottom: 0;

                    }
                }

                .message-header-info > p {
                    text-align: left;
                    font: normal normal normal 15px/20px Source Sans Pro;
                    letter-spacing: 0;
                    color: $switch-bg-color;
                    opacity: 1;
                }
            }

            .message-subject-read > h2 {
                font: normal normal bold 18px/20px Source Sans Pro;
                color: $black1;
                text-transform: capitalize;
                opacity: 1;
            }
        }
        .message-content:last-child {
            margin-bottom: 0;
        }
    }




}

.second-container {
    margin-bottom: 0;
}

.message-area-reply-form {

    form {
        padding: 15px 17px;

        textarea {
            font: normal normal 16px/20px Source Sans Pro;
        }
    }

    .send-message-btn-center {
        align-items: flex-end;

        .send-message-btn {
            border: 0;
            outline: none;
            background-color: $switch-bg-color;
            border-radius: 100%;
            margin: 0;
        }
    }
}
