.account-profile-member {
    padding-top: 30px;
    padding-bottom: 40px;
    text-align: center;
    height: 206px;

    .account-profile-member-container {
        position: relative;

        .title {
            text-align: center;
            font: normal normal normal 18px/22px Source Sans Pro;
            letter-spacing: 0;
            color: $white;
            opacity: 1;
        }

        .user-name {
            text-align: center;
            font: normal normal 300 40px/22px Source Sans Pro;
            letter-spacing: 0;
            color: $white;
            opacity: 1;
        }

        .hr-line {
            border: 1px solid $white;
            width: 675px;
        }
    }
}
