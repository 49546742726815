.monthly-payment-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .select-one {
        text-align: right;
        font: normal normal normal 18px/20px Source Sans Pro;
        letter-spacing: 0px;
        color: $grey7;
        opacity: 1;
        margin-bottom: 10px;
    }

    .monthly-payment-header-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 215px;
        height: 100px;
        border-radius: 2px;
        background-color: $grey3;
        margin-bottom: 15px;
        cursor: pointer;

        &.monthly-payment-header-item:not(:last-child) {
            margin-right: 14px;
        }

        &.selected {
            background-color: $mustard;
        }

        .label {
            text-align: center;
            font: normal normal normal 18px/18px Source Sans Pro;
            letter-spacing: -0.36px;
            color: $white;
            text-transform: uppercase;
            opacity: 1;
            margin-bottom: 5px;
        }

        .value {
            text-align: center;
            font: normal normal 600 22px/22px Source Sans Pro;
            letter-spacing: 0px;
            color: $white;
            opacity: 1;
        }
    }
}
