.reservation-item {
    width: 351px;
    margin-bottom: 30px;

    .image-and-name-container {
        max-height: 183px;

        .reservation-image-container {
            width: 107px;
            height: 183px;

            .reservation-image {
                max-height: 183px;
            }
        }

        .details-container {
            max-height: 183px;

            .reservation-status {
                height: 28px;
            }

            .name-and-number {
                .confirmation-nr-title {
                    padding-top: 0px;
                }
            }
        }
    }

    .reservation-action-container {
        .new-reservation-action-item {
            //height: 44px;

            .action-item-container {
                //height: 44px;
            }
        }
    }
}
