.one-more-step {
    h1 {
        font: normal normal bold 25px/30px Playfair Display;
        padding-bottom: 0;
    }
}

.one-more-step-form {
    max-width: 472px;
    width: 472px;
}

.submit-container {
    .submit {
        width: 472px;
        height: 56px;
        margin-top: 60px;
        margin-bottom: 105px;
    }
}
