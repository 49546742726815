.header {
    img.logo {
        margin-top: 79px;
        width: 229px;
        margin-bottom: 20px;
    }

    .title {
        margin-bottom: 45px;
        font-size: 32px;
    }
}

