.contracts-list {
    padding-top: 20px;
    padding-bottom: 68px;

    .list-contract {
        min-width: 339px;
        width: 100%;

        .list-my-contracts {
            height: 50px;
            padding: 15px;

            p.resort-name {
                font: normal normal 600 15px/20px Source Sans Pro;
            }

            p.contract-number {
                font: normal normal bold 16px/22px Source Sans Pro;
            }
        }
    }
}

