.custom-select-field-wrapper {
    width: 100%;
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    font-family: Source Sans Pro, sans-serif;

    &.white {
        .MuiInputBase-input {
            color: $white;
            font-family: Source Sans Pro, sans-serif;
        }
    }

    &.black {
        .MuiInputBase-input {
            color: $black;
            font: normal normal 600 19px/16px Source Sans Pro;
        }
    }

    .MuiFormLabel-root {
        color: $grey1;
        font-family: Source Sans Pro, sans-serif;
    }

    .MuiFormLabel-root.Mui-focused {
        color: $grey1;
    }

    .MuiSelect-select:focus {
        background-color: transparent;
    }

    .MuiSelect-icon {
        color: $grey1;
    }

    .icon-error {
        color: $error !important;
    }

    .MuiInput-underline::before {
        border-bottom: 1px solid $grey1;
    }

    .MuiInput-underline:hover:not(.Mui-disabled)::before {
        border-bottom: 1px solid $grey1;
    }

    .MuiInput-underline::after {
        border-bottom: 1px solid $grey1;
    }

    .MuiInput-underline.Mui-error::after {
        border-bottom: 1px solid $error;
    }

    .MuiFormLabel-root.Mui-error {
        color: $error;
    }

    .MuiSvgIcon-root {
        color: $grey4;
    }
}
