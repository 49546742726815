.accordion {
    .MuiAccordionSummary-content {
        font: normal normal bold 22px/25px Source Sans Pro;
        letter-spacing: 1.1px;
    }

    .MuiAccordion-rounded:last-child {
        margin-bottom: 0px;
    }
}
