.contract-carousel {
    background-color: $grey;
    min-height: 100px;
    align-items: center;
    padding: 0;

    .slick-prev {
        left: 15px;
        background-color: #000;
        top: 37px;
    }

    .slick-next {
        right: 15px;
        background-color: #000;
        top: 35px;
    }

    .slick-slide {
        border: 5px solid $grey;
        border-radius: 7px;
        opacity: 1;
        background: linear-gradient(270deg, #a3a3a3, #414141);
        padding-top: 0;
        font: normal normal normal 18px/20px Source Sans Pro;
        display: inline-block;

        &.slick-current {
            background: $white;
        }
    }

    .slick-arrow {
        width: 24px;
        height: 24px;
    }

    .slick-arrow:before {
        border: solid $white;
        border-width: 0 2px 2px 0;
        width: 6px;
        height: 6px;
        padding: 0;
    }

    .slick-arrow.slick-prev:before {
        margin-left: 2px;
        margin-top: 2px;
    }

    .slick-arrow.slick-next:before {
        margin-left: -2px;
        margin-top: 2px;
    }

    .slick-center {
        background-color: #fff;
        opacity: 1;
    }

    .contracts-slider {
        height: 70px;
        text-align: center;
        margin: 5px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p.contract-carousel-text {
            text-align: center;
            margin: 0 auto;
            text-align: center;
            font: normal normal normal 18px/23px Source Sans Pro;
            letter-spacing: 0.9px;
            color: $black;
            text-transform: uppercase;

            &.contract-number {
                text-align: center;
                font: normal normal bold 20px/23px Source Sans Pro;
                letter-spacing: 1px;
                color: $black;
                text-transform: uppercase;
            }
        }

        .contract-carousel-item {
            padding-top: 10px;
            background-color: $white;
            color: $black;

            .contract-title {
                font-family: Source Sans Pro, sans-serif;
                color: #000;
                font-size: 18px;
            }
        }
    }
}
