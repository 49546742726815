.more-about {
    margin: 40px 0;

    .title {
        font: normal normal bold 22px/28px Playfair Display;
        width: 70%;
    }

    .sub-title {
        font: normal normal 600 15px/18px Source Sans Pro;
        width: 100%;
        margin: 0 auto 40px auto;
    }
}
