.messages-list {
    margin: 0 -15px 81px -15px;

    .messages-item {
        padding: 12px 25px 15px 15px;
        margin-top: 0px;

        .subject {
            font: normal normal normal 16px/16px Source Sans Pro;
        }
    }
}
