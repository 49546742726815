.thank-you-modal {
    .thank-you-modal-container {
        width: 375px;
        height: 260px;

        .thank-you-modal-title {
            margin-top: 30px;
            margin-bottom: 20px;
            font: normal normal bold 22px/28px Playfair Display;
        }

        .thank-you-modal-text {
            max-width: 298px;
            text-align: center;
            font: normal normal normal 16px/20px Source Sans Pro;
            letter-spacing: -0.16px;
            color: #444444;
            padding-bottom: 22px;
        }

        .thank-you-modal-button {
            width: 280px;
            height: 56px;
            letter-spacing: 1px;
        }
    }
}
