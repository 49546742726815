.payment-details-container {
    width: 340px;

    .title {
        font: normal normal bold 18px/20px Source Sans Pro;
        letter-spacing: 0.9px;
        margin: 30px 0;
    }

    .saved-card-container {
        cursor: pointer;
        margin-right: 8px;
        padding: 8px;
        height: 85px;
        width: 108px;

        .saved-card-header {
            height: 20px;
            margin-bottom: 0px;

            .img {
                img {
                    width: 30px;
                    height: 20px;
                }
            }

            .expiration-date {
                font: normal normal normal 13px/36px Source Sans Pro;
            }
        }

        .number {
            height: 17px;
            font: normal normal 600 13px/36px Source Sans Pro;
        }

        .owner-name {
            //height: 17px;
            font: normal normal normal 13px/36px Source Sans Pro;
        }

        .add-new-card {
            font: normal normal normal 13px/36px Source Sans Pro;
        }
    }

    .replace-card {
        margin: 30px 5% 40px 5%;
        font: normal normal normal 16px/22px Source Sans Pro;

        .phone {
            font-weight: bold;
        }
    }

    button[type=submit] {
        letter-spacing: 1px;
        width: 100%;
        margin-bottom: 48px;
        text-transform: none;
    }
}
