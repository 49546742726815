
.single-card-container {
    max-width: 368px;
    //min-height: 454px;
    background: $grey2;
    display: flex;
    margin: 30px 10px 40px 10px;

    .card-content {
        display: flex;
        flex-direction: column;
        margin: 0px 0px 30px 0px;
        width: 100%;
        height: 200px;

        .card-img {
            display: flex;
            justify-content: center;
            align-items: center;

            img.logo {
                //max-width: 70%;
                //max-height: 90px;
                width: 100%;

                // &.rci {
                //     max-width: 55%;
                // }
            }
        }

        .card-text {
            padding-top: 30px;
            padding-bottom: 40px;
            max-width: 95%;
            text-align: left;
            font: normal normal normal 18px/25px Source Sans Pro;
            letter-spacing: 0;
            color: $grey;
            opacity: 1;
            height: 100%;
        }

        .card-button-container {
            .card-button {
                width: 100%;
                height: 56px;
                background: $blue 0% 0% no-repeat padding-box;

                .link-btn {

                    color: $white;
                    height: 56px;
                    display: flex;
                    text-decoration: none;
                    justify-content: center;
                    align-items: center;
                    bottom: 0;
                    padding-top: 15px;

                    .visit-website-img {
                        margin-bottom: 15px;
                        margin-right: 8px;
                    }

                    .visit-website {
                        font: normal normal 600 20px/36px Source Sans Pro;
                        letter-spacing: 1px;
                        color: $white;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .single-card-container {
        max-width: 340px;

        &.two-items {
            max-width: 368px !important;
        }

        &.one-item {
            max-width: 368px !important;
        }
    }
}

@media screen and (max-width: 1110px) {
    .single-card-container {
        max-width: 320px;
    }
}

@media screen and (max-width: 1050px) {
    .single-card-container {
        max-width: 300px;
    }
}

@media screen and (max-width: 805px) {
    .single-card-container {
        &.two-items {
            max-width: 350px !important;
        }

        &.one-item {
            max-width: 368px !important;
        }
    }
}

