.update-user-modal {
    z-index: 1299 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .update-user-modal-container {
        background-color: $white;
        width: 445px;
        outline: none;

        .modal-title {
            padding: 16px 20px 0px 20px;

            span {
                text-align: left;
                font: normal normal bold 18px/25px Source Sans Pro;
                letter-spacing: 0.9px;
                color: $black;
                text-transform: uppercase;
                opacity: 1;
            }

            svg {
                cursor: pointer;
                margin-bottom: 3px;
                color: $grey6;
            }
        }

        .modal-body {
            padding: 56px 99px 0px 99px;
            display: flex;
            flex-direction: column;
            align-items: center;


        }

        .submit-save-btn {
            margin: 45px auto 55px auto;
            width: 245px;
            height: 56px;
            background-color: $blue;
            border: none;
            border-radius: 2px;
            align-items: center;
            justify-content: center;
            display: flex;
            color: $white;
            text-align: center;
            font: normal normal 600 20px/22px Source Sans Pro;
            letter-spacing: 2px;
            text-transform: uppercase;
            opacity: 1;
            outline: none;

            svg {
                margin-bottom: 3px;
            }
        }
    }
}
