.vida-vacations {
    padding-top: 70px;

    .my-vida-vacations-payments {
        text-align: left;
        font: normal normal bold 25px/32px Source Sans Pro;
        letter-spacing: 2.5px;
        color: #000000;
        text-transform: uppercase;
        opacity: 1;
    }

    .my-vida-vacations-payments-right {
        opacity: 1;

        h1 {
            border-left: 1px solid #444444;
            padding-left: 20px;
        }
    }

    .center-text {
        max-width: 472px;
        height: 47px;
        margin: 40px auto 80px auto;
        text-align: center;
    }

    .center-text > p {
        text-align: center;
        font: normal normal normal 18px/24px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
    }

    .logo-center {
        text-align: right;
        padding-right: 20px;
    }
}
