.footer {
    height: 115px;

    .footer-black-section {
        .footer-about-links {
            flex-direction: column;

            .link {
                justify-content: center;
                padding: 7px;
                font: normal normal normal 16px/16px Source Sans Pro;
                letter-spacing: 0.8px;

                &:first-child {
                    margin-right: 0;
                }
            }
        }

        .footer-social {
            margin-top: 0;

            .icon {
                margin-left: 17px;
            }
        }
    }
}

.footer-grey-section {
    height: auto;
    flex-direction: row;

    .privacy-terms-container {
        flex-direction: column;

        .link-grey-section {
            padding: 10px;
            font: normal normal normal 13px/13px Source Sans Pro;

            a.link-grey-text {
                font: normal normal normal 16px/13px Source Sans Pro;
            }
        }
    }

    .copyright {
        width: 163px;
        height: 33px;
        text-align: right;
        font: normal normal normal 13px/16px Source Sans Pro;
    }
}
