.auth {
    @import "header";
    @import "nav-tabs";
    @import "form";
    @import "footer";
    @import "beta";

    color: #fff;
    max-width: 686.5px !important;
    min-height: 1080px;
    margin: 0 auto;

    .content {
        margin: 0 auto;
        width: 100%;
        flex: 2;
        &.middle {
            flex: 0;
        }
    }

    .center {
        width: 100%;
        text-align: center;
    }

    .auth-title {
        text-align: center;
        font: normal normal bold 25px/36px Playfair Display;
        letter-spacing: 0px;
        padding-bottom: 20px;
    }

    .auth-subtitle {
        text-align: center;
        font: normal normal normal 18px/25px Source Sans Pro;
        letter-spacing: 0px;
    }

    .login-container {
        margin: 0 auto;
        padding: 0 115px;
    }

    .auth-smaller-container {
        margin: 0px auto;
        width: 446px;
        border-top: 1px solid $grey1;
        padding: 100px 15px;
        text-align: center;
    }

    .reset-password-container {
        width: 446px;
        margin: 0px auto;
        border-top: 1px solid $grey1;
        text-align: center;
        padding-top: 30px;

        .title {
            font-size: 25px;
            font-family: PlayFair Display, serif;
            font-weight: bold;
        }

        .subtitle {
            padding-top: 20px;
            font-size: 18px;
            font-family: Source Sans Pro, sans-serif;

            .email {
                color: $blue1 !important;
            }
        }
    }
}
