.club-section {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    .logo{
        width: 247px;
        height: auto;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    h1 {
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        font: normal normal bold 25px/32px Source Sans Pro;
        letter-spacing: 2.5px;
        color: $black;
        opacity: 1;
        padding-top: 93px;
    }

    p {
        text-align: center;
        font: normal normal normal 18px Source Sans Pro;
        letter-spacing: 0;
        color: $grey;
        opacity: 1;
        padding-top: 30px;
        max-width: 674px;
        margin: 0 auto;
    }

    .club-read-more {
        width: 233px;
        height: 56px;
        border: none;
        background: $blue;
        margin-bottom: 50px;
        margin-top: 50px;

        a {
            text-decoration: none;
            color: $white;
            font: normal normal 600 20px/36px Source Sans Pro;
            letter-spacing: 2px;
            text-transform: uppercase;
            text-align: center;
        }
    }
}
