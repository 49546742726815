.reservation-found-info-container {
    background: black;
    display: flex;
    flex-direction: column;

    .image-and-info-container {
        min-height: 250px;
        height: auto;
        display: flex;
        flex-direction: row;

        .image-container {
            img {
                width: 100%;
                height: 100%;
            }
        }

        .info-container {
            display: flex;
            flex-direction: column;

            .reservation-status-container {
                height: 46px;
                background: $green;
                display: flex;
                justify-content: center;
                align-items: center;

                &.status-background1 {
                    background-color: $orange;
                }

                &.status-background2 {
                    background-color: $green;
                }

                &.status-background3 {
                    background-color: $blue;
                }

                &.status-background4 {
                    background-color: $orange1;
                }

                &.status-background5 {
                    background-color: $red;
                }

                &.status-background6 {
                    background-color: $green;
                }

                &.status-background7 {
                    background-color: $green;
                }

                &.status-background8 {
                    background-color: $green;
                }

                h2.reservation-status {
                    margin: 0;
                    text-align: center;
                    font: normal normal 600 16px/20px Source Sans Pro;
                    letter-spacing: 0px;
                    color: $white;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }

            .reservation-details-container {
                height: 204px;
                background: $white;

                h3.destination {
                    text-align: center;
                    font: normal normal bold 32px/28px Playfair Display;
                    letter-spacing: 0px;
                    color: $black;
                    text-transform: capitalize;
                    opacity: 1;
                    margin-top: 40px;
                }

                h4.confirmation-label {
                    text-align: center;
                    font: normal normal normal 16px/36px Source Sans Pro;
                    letter-spacing: 0.8px;
                    color: $charcoal;
                    text-transform: uppercase;
                    opacity: 1;
                    margin-top: 30px;
                    margin-bottom: 0;
                }

                p.confirmation-number {
                    text-align: center;
                    font: normal normal normal 22px/31px Source Sans Pro;
                    letter-spacing: 0px;
                    color: $grey;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }
        }
    }

    .travelers-and-unit-container {
        min-height: 100px;
        height: auto;
        background: $grey2;

        .travelers-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 30px;

            p.travelers-label {
                margin-bottom: 0;
                text-align: left;
                font: normal normal 600 13px/36px Source Sans Pro;
                letter-spacing: 0.65px;
                color: $grey1;
                text-transform: uppercase;
                opacity: 1;
                height: 25px;
            }

            p.travelers-names {
                text-align: left;
                font: normal normal normal 18px/20px Source Sans Pro;
                letter-spacing: 0px;
                color: $grey;
                opacity: 1;
                margin-bottom: 0;
            }
        }

        .unit-type-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 30px;

            p.unit-type-label {
                margin-bottom: 0;
                text-align: left;
                font: normal normal 600 13px/36px Source Sans Pro;
                letter-spacing: 0.65px;
                color: $grey1;
                text-transform: uppercase;
                opacity: 1;
                height: 25px;
            }

            p.unit-type {
                text-align: left;
                font: normal normal normal 18px/20px Source Sans Pro;
                letter-spacing: 0px;
                color: $grey;
                opacity: 1;
                margin-bottom: 0;
            }
        }
    }
}

.verify-button-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 140px;

    .verify-button {
        width: 184px;
        height: 56px;
        border: none;
        background: $blue 0% 0% no-repeat padding-box;
        opacity: 1;
        text-align: center;
        font: normal normal 600 20px/36px Source Sans Pro;
        letter-spacing: 2px;
        color: $white;
        text-transform: uppercase;
    }
}
