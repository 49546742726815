.account {
    background-color: $white;
    @import "dashboard";
    @import "general";
    @import "myReservations";
    @import "myAccount";
    @import "editProfile";
    @import "messagesCenter";
    @import "myVidaVacationsPayments";
    @import "ReadMessage";
    @import "requestReservation";
    @import "addReservationToProfile";
    @import "paymentMethod";
    @import "FAQs";
    @import "myVidaVacationsContracts";
    @import "myVidaVacationsPaymentsHistory";
    @import "exchanges";
    @import "about";
    @import "contact";
    @import "clubFreedomBenefits";
    @import "preRegistration";
    @import "destinationHighlight";
    @import "reservationSummary";
    @import "reservationFound";

    
    @media screen and (min-width: 1554px) {
        .container {
            max-width: $desktop-width;
        }
    }

    @media screen and (max-width: 1553px) {
        .container {
            max-width:  $laptop-width;
        }
    }

    @media screen and (max-width: 1200px) {
        .container {
            max-width: $laptop-width;
        }
    }

    @media screen and (max-width: 768px) {
        .container {
            max-width: $tablet-width;
        }
    }

    @media screen and (max-width: 568px) {
        .container {
            max-width: $mobile-width;
        }
    }
}
