.payment-type {
    .add-new-container {
        margin-top: 25px;

        button {
            .add-new-text {
                font: normal normal bold 16px/16px Source Sans Pro;
            }
        }
    }

    .card-container {

        .card-details {
            margin-bottom: 0px;

            .card-number-hidden {
                font: normal normal normal 18px/18px Source Sans Pro;
            }

            .default-text {
                font: normal normal 600 13px/18px Source Sans Pro;

                &.is-default {
                    font: normal normal 600 13px/18px Source Sans Pro;
                }
            }
        }
    }
}
