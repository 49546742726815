.contact-header {
    h1 {
        font: normal normal bold 22px/28px Playfair Display;
        padding-top: 40px;
        max-width: 178px;
    }

    p {
        text-align: center;
        font: normal normal normal 16px/22px Source Sans Pro;
        padding-top: 20px;
        padding-bottom: 40px;
        max-width: 282px;
    }
}
