.messages-centre-header {
    height: 100px;

    .title {
        font: normal normal bold 18px/24px Playfair Display;
        letter-spacing: 0.9px;
    }

    .messages-info-container {
        .nr-messages {
            font: normal normal 600 16px/20px Source Sans Pro;
        }

        .mark-as-read {
            font: normal normal 600 13px/31px Source Sans Pro;
        }
    }
}
