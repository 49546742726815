.payments-type {
    .payments-container {
        .payments-title-container {
            .payment-type-text {
                margin-top: 40px;
                margin-bottom: 30px;
                h3 {
                    font: normal normal bold 22px/28px Playfair Display;
                }
            }
        }
    }

    .payment-type-options {
        max-width: 375px;
    }

    .payment-type-make-payment {
        margin-bottom: 159px;
        padding-top: 80px;

        .MuiButton-label {
            background-color: $switch-bg-color;
            text-align: center;
            font: normal normal 600 20px/36px Source Sans Pro;
            letter-spacing: 2px;
            color: $white;
            text-transform: uppercase;
            opacity: 1;
            padding: 15px 50px;
        }

    }
}
