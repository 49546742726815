.messages-centre-header {
    height: 113px;
    padding: 0px 10px;

    .title {
        font: normal normal bold 25px/25px Playfair Display;
        padding-bottom: 10px;
    }

    .messages-info-container {
        padding-top: 10px;
    }
}
