.destination-image {
    height: 400px;

    &.jungala {
        background-image: url('/img/destinations/tablet/jungala.jpg');
        background-position: 0% 25%;
    }

    &.palacio {
        background-image: url('/img/destinations/tablet/palacio_maya.jpg');
        background-position: 0% 63%;
    }

    &.beachland {
        background-image: url('/img/destinations/tablet/beachland.jpg');
        background-position: 0% 65%;
    }

    &.joya {
        background-image: url('/img/destinations/tablet/joya.jpg');
    }

    &.reserve {
        background-image: url('/img/destinations/tablet/the_reserve.jpg');
    }
}
