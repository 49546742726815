.choose-months {
    &.expanded {
        max-height: 340px;
    }

    .custom-payment-field > div:nth-child(3n + 2) {
        padding-left: 0px;
    }

    .custom-payment-field > div:nth-child(3n) {
        padding-left: 0px;
    }
}
