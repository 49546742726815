.header-exchanges {
    h1 {
        font: normal normal bold 25px/30px Playfair Display;
        padding-top: 60px;
    }

    p {
        max-width: 472px;
    }
}
