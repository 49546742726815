.header-faqs {
    width: 100%;
    max-width: 340px;
    padding: 0 20px;

    > h2 {
        font: normal normal bold 18px/24px Source Sans Pro;
        letter-spacing: 0.9px;
        padding-top: 40px;
    }

    > p {
        padding-top: 20px;
        padding-bottom: 41px;
        font: normal normal normal 16px/22px Source Sans Pro;
        letter-spacing: 0;
    }
}
